import { useContext, useState } from "react";
import { DELETE_ALL_EXTERNAL_VOICES_ENDPOINT } from "../../constants/Constants";
import { SuccessButtonStyle } from "../../constants/CSSConstants";
import { Tool } from "../../generated/graphql";
import useMakeBackendRequest from "../../hooks/useMakeBackendRequest";
import { AppContext } from "../../UserApp";

type LongConversationResponse = {
  toolName: string;
  conversation: string[];
  minimunConversationLength: number;
  content: any;
};

type ShortConversationResponse = {
  toolName: string;
  conversations: string[][];
  minimunConversationsLength: number;
  content: any;
};
const Backofffice = () => {
  const { post } = useMakeBackendRequest();
  const app = useContext(AppContext);
  const [showContentForTool, setShowContentForTool] =
    useState<Map<string, boolean>>();
  const generativeAiTools: Tool[] | undefined = app.generativeAiTools;
  const [longAudioPromptsResponse, setLongAudioPromptsResponse] = useState<
    LongConversationResponse[]
  >([]);
  const [shortAudioPromptsResponse, setShortAudioPromptsResponse] = useState<
    ShortConversationResponse[]
  >([]);

  const callAudioTool = async (tool: Tool) => {
    const generateParams = {
      productDescription: "Minions show",
      productName: "The banana conversation",
    };

    const createContentResponse = await post(
      `${process.env.REACT_APP_ML_ENGINE}/generate`,
      {
        toolId: tool.id,
        tags: [],
        // tags: [],
        userId: app?.user?.id,
        moreLikeResponse: null,
        //validate me
        params: [],
        generateParams,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return createContentResponse;
  };
  const [Loading, setLoading] = useState<boolean>(false);

  const testAudioPrompts = () => {
    setLongAudioPromptsResponse([]);
    setShortAudioPromptsResponse([]);
    setLoading(true);

    const audioToolsWithMultipleConversations = ["Voice Over", "Audio Ad"];

    // find audioToolsWithMultipleConversations and call them
    const audioToolsMultipleConversations = generativeAiTools?.filter(
      (tool) => {
        return (
          tool.toolTypeByToolTypeId?.type === "AUDIO_GENERATIVE" &&
          audioToolsWithMultipleConversations.includes(tool.name)
        );
      }
    );
    audioToolsMultipleConversations?.forEach(async (tool) => {
      const createContentResponse = await callAudioTool(tool);
      const shortConversationResponse: ShortConversationResponse = {
        toolName: tool.name,
        conversations: createContentResponse?.data,
        minimunConversationsLength: 3,
        content: createContentResponse?.data,
      };

      setShortAudioPromptsResponse((audioPromptsResponse) => [
        ...audioPromptsResponse,
        shortConversationResponse,
      ]);
    });

    const audioToolsLongConversation = generativeAiTools?.filter((tool) => {
      return (
        tool.toolTypeByToolTypeId?.type === "AUDIO_GENERATIVE" &&
        !audioToolsWithMultipleConversations.includes(tool.name)
      );
    });
    audioToolsLongConversation?.forEach(async (tool) => {
      const createContentResponse = await callAudioTool(tool);

      const longConversation = createContentResponse?.data?.[0]
        ?.conversation as string[];
      const longConversationResponse: LongConversationResponse = {
        toolName: tool.name,
        conversation: longConversation,
        minimunConversationLength: 3,
        content: createContentResponse?.data,
      };

      setLongAudioPromptsResponse((audioPromptsResponse) => [
        ...audioPromptsResponse,
        longConversationResponse,
      ]);
    });

    setLoading(false);
  };

  return (
    <div>
      <div>
        <button
          onClick={() => {
            post(DELETE_ALL_EXTERNAL_VOICES_ENDPOINT, {});
          }}
        >
          clear all external voices
        </button>
      </div>
      <div>
        test prompts
        <button
          onClick={() => {
            testAudioPrompts();
          }}
          className={SuccessButtonStyle}
        >
          test audio prompts
        </button>
      </div>
      <div>{Loading ? "Loading" : ""}</div>
      <div>
        <div className="my-10 flex justify-center">Audio tools Status</div>
        {longAudioPromptsResponse.map((response) => {
          return (
            <div
              className={`my-5 grid grid-cols-2 ${
                response.conversation.length >
                response.minimunConversationLength
                  ? " bg-green-200"
                  : ""
              }`}
            >
              <div className="col-span-2">Tool: {response.toolName}</div>
              <div>Conversation Lenth: {response.conversation.length}</div>
              <div>
                Minimun Conversation Lenth: {response.minimunConversationLength}
              </div>
            </div>
          );
        })}
        <div>
          {shortAudioPromptsResponse.map((response) => {
            return (
              <div
                onClick={() => {
                  setShowContentForTool((showContentForTool) => {
                    const newShowContentForTool = new Map(showContentForTool);
                    newShowContentForTool.set(
                      response.toolName,
                      !showContentForTool?.get(response.toolName)
                    );
                    return newShowContentForTool;
                  });
                }}
                className={`my-5 grid grid-cols-2 ${
                  response.conversations.length ===
                  response.minimunConversationsLength
                    ? " bg-green-200"
                    : response.conversations.length >
                      response.minimunConversationsLength
                    ? " bg-yellow-200"
                    : " bg-red-200"
                }

              `}
              >
                <div>Tool: {response.toolName}</div>
                <div>
                  Conversation Lenth: {response.conversations.length} vs min{" "}
                  {response.minimunConversationsLength}
                </div>
                <div
                  className={`col-span-2 ${
                    showContentForTool?.get(response.toolName)
                      ? "block"
                      : "hidden"
                  }`}
                >
                  {JSON.stringify(response.content)}
                </div>
              </div>
            );
          })}
          {/* {JSON.stringify(shortAudioPromptsResponse, null, 2)} */}
        </div>
      </div>
    </div>
  );
};
export default Backofffice;
