import { getQuery } from "./../graphql/fetchQuery";

import { useState, useCallback } from "react";
import { useEffect } from "react";
import { Tool, Query, User, Subscription } from "./../generated/graphql";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

import { WhitelistQueries } from "../graphql/whitelistedQueries";
import { useAuthMutation, useAuthQuery } from "./useAuthApollo";
import { ApolloQueryResult } from "@apollo/client";
import { isLocalhost } from "../utils/navigationUtils";

export type AppData = {
  user: User;
  intialLoadDone: boolean;
  generativeAiTools?: Tool[];
  refreshData: () => Promise<unknown>;
  authToken: string;
  subscriptions?: Subscription[];
};

const useLoadAppData = (firebaseUser: firebase.User, authToken: string) => {
  const query = getQuery(WhitelistQueries.AllUsers);

  const isLive =
    (process.env.REACT_APP_LIVE_MODE === "true" && !isLocalhost) || false;

  const subscritionsVariables = {
    live: isLive,
  };

  const { data: subscriptionsData } = useAuthQuery<Query>(
    { query: getQuery(WhitelistQueries.AllSubscriptions) },
    {
      variables: subscritionsVariables,
    }
  );

  const { data: toolsData } = useAuthQuery<Query>({
    query: getQuery(WhitelistQueries.AllTools),
  });

  const { loading, error, data, refetch } = useAuthQuery<Query>(
    {
      query: query,
    },
    {
      variables: { firebaseId: firebaseUser?.uid },
    }
  );
  const [appData, setAppData] = useState<AppData>();

  const refreshData: () => Promise<unknown> = useCallback(async () => {
    console.info(`refreshing user data`);
    const refreshDataResult: Promise<unknown> = new Promise(
      (resolve, reject) => {
        refetch()
          ?.then((newData: ApolloQueryResult<Query>) => {
            if (newData?.data.allUsers?.nodes.length === 1) {
              console.log(`refeteched data for user`);
              const user = newData.data?.allUsers?.nodes[0];
              console.log(
                `subscription user32 ${user?.userSubscriptionsByUserId?.nodes?.length}`
              );
              if (user) {
                setAppData((prevData) => {
                  if (!prevData) {
                    return prevData;
                  }
                  return {
                    ...prevData,
                    user: user,
                  };
                });
                resolve({
                  ...appData,
                  user: user,
                }); // resolve the Promise
              }
            } else {
              console.error(
                `no user found or more than 1 user found mnewData?.data.allUsers?.nodes ${JSON.stringify(
                  newData?.data.allUsers?.nodes
                )}`
              );
              reject({
                error: "no user found or more than 1 user found",
              });
            }
          })
          .catch((error: any) => {
            console.error(error);
            reject(error); // reject the Promise with the error
          });
      }
    );
    return refreshDataResult;
  }, [refetch, setAppData]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const createUserMutation = getQuery(WhitelistQueries.CreateUser);
  const [mutationCreateUser] = useAuthMutation({
    mutation: createUserMutation,
  });
  useEffect(() => {
    if (appData?.user) {
      return;
    }
    if (data?.allUsers?.nodes.length === 0 && !appData?.user) {
      const user = {
        name: firebaseUser?.displayName,
        email: firebaseUser?.email!,
        firebaseId: firebaseUser?.uid!,
      };

      const createUserResult = mutationCreateUser({
        variables: {
          name: user.name,
          email: user.email,
          firebaseId: user.firebaseId,
        },
        //TODO for all queries
        context: {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        },
      });
      createUserResult.then((createUserResult) => {
        const createdUser: User = createUserResult.data?.createUser.user;
        setAppData({
          user: createdUser,
          intialLoadDone: false,
          refreshData: refreshData,
          authToken: authToken,
        });
      });
    } else {
      if (data?.allUsers?.nodes.length === 1 && !appData?.user) {
        console.log(`found user`);
        const user = data?.allUsers?.nodes[0];
        if (user) {
          try {
            Sentry.setUser({ email: user.email, id: user.id });
            LogRocket.identify(user.id, {
              name: user.name,
              email: user.email,
            });
          } catch (e) {
            console.log(e);
          }

          setAppData({
            user: user,
            intialLoadDone: false,
            refreshData: refreshData,
            authToken: authToken,
          });
        }
      }
    }
  }, [
    appData?.user,
    data?.allUsers?.nodes,
    data?.allUsers?.nodes.length,
    firebaseUser,
    mutationCreateUser,
    refreshData,
    authToken,
  ]);

  const fetchGenerativeTools = async (
    tools: Tool[],
    subscriptions: Subscription[]
  ) => {
    setAppData((prevData) => {
      if (!prevData) {
        return;
      }
      return {
        ...prevData,
        generativeAiTools: tools,
        intialLoadDone: true,
        subscriptions: subscriptions,
      };
    });
    //after all data is done, we do a final refresh to
    // get subscription in cause it was just created
    if (
      !appData?.user.userSubscriptionsByUserId?.nodes ||
      appData?.user.userSubscriptionsByUserId?.nodes?.length === 0
    ) {
      console.log(`fetching first subscription`);
      // await refreshData();
      //refreshData in 2 seconds to give time for the subscription to be created
      setTimeout(() => {
        refreshData();
      }, 2000);
    }
  };
  useEffect(() => {
    const toolsLength = toolsData?.allTools?.nodes?.length || 0;
    const subscriptionsLength =
      subscriptionsData?.allSubscriptions?.nodes?.length || 0;
    if (appData?.user.id && toolsLength > 0 && subscriptionsLength > 0) {
      const tools: Tool[] = toolsData?.allTools?.nodes.map((tool) => tool!)!;
      const subscriptions: Subscription[] =
        subscriptionsData?.allSubscriptions?.nodes.map(
          (subscription) => subscription!
        )!;
      fetchGenerativeTools(tools, subscriptions);
    } else {
      console.log(
        `
        notDONE
        appData?.user.id ${appData?.user.id} toolsLength ${toolsLength} subscriptionsLength ${subscriptionsLength}`
      );
    }
  }, [
    appData?.user.id,
    subscriptionsData?.allSubscriptions?.nodes,
    toolsData?.allTools?.nodes,
    toolsData?.allTools?.nodes?.length,
  ]);
  return { appData };
};
export { useLoadAppData };
