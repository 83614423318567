import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { resetApp } from "..";
import { auth } from "../config/firebase";

export const SignInWithSocialMedia = (provider: firebase.auth.AuthProvider) =>
  new Promise<firebase.auth.UserCredential>((resolve, reject) => {
    auth
      .signInWithPopup(provider)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });

export const signOut = () => {
  // clear all cookies and local storage

  localStorage.clear();
  auth.signOut();
  resetApp();
};

export const getCurrentUser = () => {
  return auth.currentUser;
};
