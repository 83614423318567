import {
  useMutation,
  useQuery,
  DocumentNode,
  MutationHookOptions,
  QueryHookOptions,
  TypedDocumentNode,
  DefaultContext,
  MutationTuple,
  ApolloCache,
  OperationVariables,
  ApolloClient,
  ServerError,
} from "@apollo/client";
import { useEffect } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { useApolloClient } from "@apollo/client";

interface Props<TData = any, TVariables = any> {
  query?: DocumentNode | TypedDocumentNode<TData, TVariables>;
  mutation?: DocumentNode | TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
}

const clearGraphqlClientStore = async (client: ApolloClient<object>) => {
  try {
    await Promise.all([
      client.resetStore(),
      client.clearStore().catch((error) => {
        console.error("Error clearing store:", error);
      }),
    ]);
  } catch (e) {
    console.error(e);
  }
};

export function useAuthMutation<TData = any, TVariables = any>(
  props: Props<TData, TVariables>,
  options?: MutationHookOptions<
    TData,
    TVariables,
    DefaultContext,
    ApolloCache<any>
  >
): MutationTuple<TData, TVariables> {
  const client: ApolloClient<object> = useApolloClient();

  const [mutate, result] = useMutation<TData, TVariables>(props.mutation!, {
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
        try {
          await clearGraphqlClientStore(client);
        } catch (e) {
          console.log(`clearGraphqlClientStore error ${JSON.stringify(e)}`);
        }
      } else {
        localStorage.removeItem("token");
        try {
          await clearGraphqlClientStore(client);
        } catch (e) {
          console.log(`clearGraphqlClientStore error ${JSON.stringify(e)}`);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [client]);

  useEffect(() => {
    const error = result.error;
    if (error) {
      if (
        error.networkError &&
        (error.networkError as ServerError).statusCode === 401
      ) {
        const user = firebase.auth().currentUser;
        if (user) {
          user.getIdToken(true).then((token) => {
            localStorage.setItem("token", token);
            mutate();
            try {
              clearGraphqlClientStore(client);
            } catch (e) {
              console.log(`clearGraphqlClientStore error ${JSON.stringify(e)}`);
            }
          });
        }
      }
    }
  }, [result.error]);

  return [mutate, result];
}

export function useAuthQuery<
  TData = any,
  TVariables extends OperationVariables = any
>(
  props: Props<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
) {
  const client = useApolloClient();

  console.log(`useAuthQuery options ${JSON.stringify(options)}`);
  const { loading, error, data, refetch } = useQuery<TData, TVariables>(
    props.query!,
    {
      ...options,
      context: {
        ...options?.context,
        headers: {
          ...options?.context?.headers,
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      skip: !firebase.auth().currentUser,
    }
  );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
        try {
          clearGraphqlClientStore(client);
        } catch (e) {
          console.log(`clearGraphqlClientStore error ${JSON.stringify(e)}`);
        }
      } else {
        localStorage.removeItem("token");
        try {
          clearGraphqlClientStore(client);
        } catch (e) {
          console.log(`clearGraphqlClientStore error ${JSON.stringify(e)}`);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [client]);

  useEffect(() => {
    if (error) {
      // const statusCode = error;
      if (
        error.networkError &&
        (error.networkError as ServerError).statusCode === 401
      ) {
        const user = firebase.auth().currentUser;
        if (user) {
          user.getIdToken(true).then((token) => {
            localStorage.setItem("token", token);
            refetch();
            clearGraphqlClientStore(client);
          });
        }
      }

      console.log(`useAuthQuery error ${JSON.stringify(error)}`);
      // if (statusCode === 401) {
      //   // Redirect the user to the login page
      //   history.push("/login");
      // }
    }
  }, [error]);

  // check if firebase is still loading
  if (!firebase.auth().currentUser) {
    return {
      loading: true,
      error: null,
      data: null,
      refetch: () => {},
    };
  }

  return { loading, error, data, refetch };
}
