import { SuccessButtonStyle } from "../constants/CSSConstants";

type TermsPopUpProps = {
  acceptTerms: () => void;
};
const TermsPopUp = (props: TermsPopUpProps) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="bg-white rounded-lg shadow-lg p-8 m-4 max-w-xl w-full">
        <div className="mb-4 grid gap-2">
          <h1 className="text-2xl font-bold flex justify-center">
            Terms and Conditions
          </h1>
          <div className="text-gray-600 ">
            By using this website you agree to our
            <a
              className=" px-2 text-gray-500  underline"
              href="https://cloveai.com/terms"
            >
              terms and conditions
            </a>
            and
            <a
              className=" px-2 text-gray-500  underline"
              href="https://cloveai.com/privacy"
            >
              privacy policy.
            </a>
          </div>
          {/*  */}
          <div className="flex justify-center mt-3">
            <button
              onClick={() => {
                props.acceptTerms();
              }}
              className={SuccessButtonStyle}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsPopUp;
