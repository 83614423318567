import { AppContext } from "./../../UserApp";
import { WhitelistQueries } from "../../graphql/whitelistedQueries";
import { getQuery } from "./../../graphql/fetchQuery";
import { useContext, useState } from "react";
import useNotify, { Notifications } from "../../hooks/useNotify";
import useMakeBackendRequest from "../../hooks/useMakeBackendRequest";
import useDeleteClonedVoice from "../../hooks/useDeleteClonedVoice";
import { useAuthMutation } from "../../hooks/useAuthApollo";

type CloneVoiceProps = {
  userId: string;
  numberOfVoiceSubmissions: number;
  userName: string;
  userRecordingURL: string;
  voiceName: string;
  previousVoiceId?: string;
  voiceId: string;
};

export const useCloneVoice = () => {
  const app = useContext(AppContext);

  const { post } = useMakeBackendRequest();

  const [voicePrintDemoUrl, setVoicePrintDemoUrl] = useState<string>();

  const { notify } = useNotify();

  const [cloningVoice, setCloningVoice] = useState(false);
  const [voiceId, setVoiceId] = useState<string>();

  const submitCloneVoice = async (
    userId: string,
    voiceName: string,
    voiceId: string
  ) => {
    // const formData: FormData = new FormData();

    const cloneVoiceBody = {
      bucket: "clove-ai-user-voices",
      name: "santtest111",
      labels: "",
      fileName: `${voiceName}--${Date.now()}.wav`,
      userId: userId || "",
      voiceName: voiceName || "",
      voiceId: voiceId,
    };

    const cloneVoiceResponse = await post(
      `${process.env.REACT_APP_ML_ENGINE}/clonevoice`,
      cloneVoiceBody,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return cloneVoiceResponse;
  };

  const cloneVoice = async (props: CloneVoiceProps) => {
    setCloningVoice(true);
    let voiceId = props.previousVoiceId;

    if (!voiceId) {
      voiceId = props.voiceId;
    }

    const userId = props.userId;
    const voiceName = props.voiceName;
    const cloneVoiceResponse = await submitCloneVoice(
      userId,
      voiceName,
      voiceId
    );

    const { voicePrintDemoUrl } = cloneVoiceResponse.data;

    setVoiceId(voiceId);

    notify(
      Notifications.NEW_CLONED_VOICE,
      `New cloned voice created ${voiceId} for user ${props.userName} 
      userRecordingURL ${props.userRecordingURL} voicePrintDemoUrl : ${voicePrintDemoUrl} 
      `
    );

    app.refreshData();
    setCloningVoice(false);
    setVoicePrintDemoUrl(voicePrintDemoUrl);
  };

  return {
    cloneVoice,
    cloningVoice,
    voicePrintDemoUrl,
    submitCloneVoice,
    voiceId,
  };
};
