import {
  Query,
  TextToSpeechSubmission,
} from "../../../../../generated/graphql";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/solid";
import RegenerateSentenceAudio from "./RegenerateSentenceAudio";
type ResponseWorkshopRowActionsProps = {
  textToSpeeches: Query;
  refetchSubmissionResponse: () => void;
  refetchTextToSpeeches: () => Promise<any>;
  content: string;
  idx: number;
  userToolSubmissionResponseId: string;
  contentKey: string;
  submissionResponse: any;
  noAudioForText: Map<string, boolean>;
  setNoAudioForText: (prev: any) => any;
  audioRefs: Map<string, HTMLAudioElement>;
  isPlaying: Map<string, boolean>;
  setIsPlaying: (prev: any) => any;
  textToSpeechIdxHistory: Map<number, TextToSpeechSubmission>;
  setTextToSpeechIdxHistory: (prev: any) => any;
  contentKeyVoiceId: Map<string, string>;
  setContentKeyVoiceId: (prev: any) => any;
  highlightSelecteVoice: boolean;
};
const ResponseWorkshopRowActions = (props: ResponseWorkshopRowActionsProps) => {
  const textToSpeechSubmission =
    props.textToSpeeches?.allTextToSpeechSubmissions?.nodes?.find((node) => {
      return node?.text === props.content;
    });
  const audioToPlay = textToSpeechSubmission?.audioUrl;
  if (!props.textToSpeechIdxHistory.get(props.idx) && textToSpeechSubmission) {
    props.setTextToSpeechIdxHistory((prev: any) => {
      const newMap = new Map(prev);
      newMap.set(props.idx, textToSpeechSubmission);
      return newMap;
    });
  }

  if (!audioToPlay && !props.noAudioForText.get(props.content)) {
    props.setNoAudioForText((prev: any) => {
      const newMap = new Map(prev);
      newMap.set(props.content, true);
      return newMap;
    });
  }

  if (audioToPlay && props.noAudioForText.get(props.content)) {
    props.setNoAudioForText((prev: any) => {
      const newMap = new Map(prev);
      newMap.delete(props.content);
      return newMap;
    });
  }
  if (
    audioToPlay &&
    textToSpeechSubmission?.voiceId &&
    !props.contentKeyVoiceId.get(props.contentKey)
  ) {
    props.setContentKeyVoiceId((prev: any) => {
      const newMap = new Map(prev);
      newMap.set(props.contentKey, textToSpeechSubmission.voiceId);
      return newMap;
    });
  }
  console.log("props.key555", props.contentKey);

  return (
    <div>
      <div className="flex flex-row">
        <audio
          ref={(ref) => {
            if (ref) {
              props.audioRefs.set(props.content, ref);
            }
          }}
          //on finish playing set isPlaying to false
          onEnded={() => {
            props.setIsPlaying((prev: any) => {
              const newMap = new Map(prev);
              newMap.set(props.content, false);
              return newMap;
            });
          }}
          className="hidden"
          src={audioToPlay}
          controls
        ></audio>

        {!props.isPlaying.get(props.content) ? (
          props.noAudioForText.get(props.content) ? (
            <RegenerateSentenceAudio
              highlightSelecteVoice={props.highlightSelecteVoice}
              textToSpeechIdxHistory={props.textToSpeechIdxHistory}
              setTextToSpeechIdxHistory={props.setTextToSpeechIdxHistory}
              refetchTextToSpeeches={props.refetchTextToSpeeches}
              content={props.content}
              idx={props.idx}
              userToolSubmissionResponseId={props.userToolSubmissionResponseId}
              contentKey={props.contentKey}
              submissionResponse={props.submissionResponse}
              textToSpeeches={props.textToSpeeches}
              contentKeyVoiceId={props.contentKeyVoiceId}
              setContentKeyVoiceId={props.setContentKeyVoiceId}

              //             contentKeyVoiceId: Map<string, string>;
              // setContentKeyVoiceId: (prev: any) => any;
              //map
            />
          ) : (
            <PlayCircleIcon
              onClick={() => {
                props.setIsPlaying((prev: any) => {
                  const newMap = new Map(prev);
                  newMap.set(props.content, true);
                  return newMap;
                });

                props.audioRefs.get(props.content)?.play();
              }}
              className="  h-7 w-7"
            />
          )
        ) : (
          <PauseCircleIcon
            onClick={() => {
              props.setIsPlaying((prev: any) => {
                const newMap = new Map(prev);
                newMap.set(props.content, false);
                return newMap;
              });

              props.audioRefs.get(props.content)?.pause();
            }}
            className="h-7 w-7"
          ></PauseCircleIcon>
        )}
      </div>
    </div>
  );
};
export default ResponseWorkshopRowActions;
