import axios from "axios";
export enum Notifications {
  SUCCESS = "success",
  NEW_CLONED_VOICE = "new_cloned_voice",
}

const useNotify = () => {
  const notify = (type: Notifications, message: string) => {
    axios.post("https://hook.us1.make.com/ebjbky67sdpnux9aocpqsk6h4tcprma1", {
      message,
      type,
    });
  };

  return { notify };
};

export default useNotify;
