import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LogRocket from "logrocket";
import { loadQueries } from "./graphql/fetchQuery";
import { Loading } from "./common/Loading";

try {
  LogRocket.init("dok3u1/clove");

  Sentry.init({
    dsn: "https://9e125589520f45588e60b22d83139ea2@o4504759643865088.ingest.sentry.io/4504759645175808",
    integrations: [new BrowserTracing()],
    denyUrls: [/localhost/i],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
} catch (error) {}
function LoadingMessage() {
  //if I do't hide spinner it "restarts the animation" and looks funky
  return <Loading></Loading>;
}

function AppWrapper() {
  const [isLoadingQueries, setIsLoadingQueries] = useState(true);

  useEffect(() => {
    async function load() {
      await loadQueries();
      setIsLoadingQueries(false);
    }
    load();
  }, []);

  if (isLoadingQueries) {
    return <LoadingMessage />;
  }

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root");

ReactDOM.render(<AppWrapper />, rootElement as HTMLElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export function resetApp() {
  ReactDOM.unmountComponentAtNode(rootElement as HTMLElement);
  ReactDOM.render(<AppWrapper />, rootElement as HTMLElement);
}
