import { AppContext } from "./../UserApp";
import { useContext } from "react";
import { LIST_FILES_ENDPOINT } from "./../constants/Constants";
import { useState } from "react";
import useMakeBackendRequest from "./useMakeBackendRequest";

export type BucketFile = {
  name: string;
  url: string;
  rawName: string;
};

const useListBucketFiles = () => {
  const [bucketFiles, setBucketFiles] = useState<Array<BucketFile>>([]);
  const [error, setError] = useState<string>();
  const app = useContext(AppContext);
  const { post } = useMakeBackendRequest();

  const listBucketFiles = async (path: string) => {
    console.log("files");

    try {
      const userId = app.user.id;
      const files = await post(LIST_FILES_ENDPOINT, {
        userId,
        path,
      });
      console.log("files2");

      setBucketFiles(files.data);
      return files.data.files as BucketFile[];
    } catch (error) {
      // setError(String(JSON.stringify(error)));
      console.log("error", error);
    }
    // if I add post I get infinite re renders, can't figure it out yet
    return [];
  };

  return { listBucketFiles, error };
};

export default useListBucketFiles;

// Path: src/components/BucketFiles.tsx
