import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../UserApp";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { WhitelistQueries } from "../../../graphql/whitelistedQueries";
import { getQuery } from "../../../graphql/fetchQuery";
import { useAuthMutation, useAuthQuery } from "../../../hooks/useAuthApollo";
import {
  Maybe,
  Query,
  UserAddedVoice,
  Voice,
} from "../../../generated/graphql";
import { Loading } from "../../../common/Loading";
import Spinner from "../../../common/Spinner";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/solid";
import SearchPublicVoiceRow from "./SearchPublicVoiceRow";
import {
  ADD_PUBLIC_VOICE,
  CONVERSATION_TOOL_NAME,
} from "../../../constants/Constants";
import {
  getOnboardingStatus,
  setOnboardingStatus,
} from "../../onboarding/getOnboardingStatus";
import { useNavigate } from "react-router-dom";

const SearchPublicVoices = () => {
  const appData = useContext(AppContext);
  const onboarding = getOnboardingStatus();
  const navigate = useNavigate();

  const [addedVoicesCount, setAddedVoicesCount] = useState(0);

  const isOnboardingAddPublicVoice =
    onboarding?.hightLightTool === ADD_PUBLIC_VOICE;

  const tutorialAddVoicesCount = 2;

  useEffect(() => {
    if (addedVoicesCount === tutorialAddVoicesCount) {
      if (onboarding) {
        setOnboardingStatus({
          ...onboarding,
          hightLightTool: CONVERSATION_TOOL_NAME,
        });
      }
      navigate("/");
    }
  }, [addedVoicesCount, navigate]);

  const { data: allPublicVoices } = useAuthQuery<Query>({
    query: getQuery(WhitelistQueries.AllPublicVoices),
  });

  const [filteredPublicVoices, setFilteredPublicVoices] = useState<
    Maybe<Voice>[]
  >([]);
  const maybeAddedVoices: Maybe<UserAddedVoice>[] =
    appData.user?.userAddedVoicesByUserId?.nodes || [];

  //remove null from maybeAddedVoices so we end up with an array of UserAddedVoice
  const addedVoices: UserAddedVoice[] = maybeAddedVoices.filter(
    (voice) => voice !== null
  ) as UserAddedVoice[];

  console.log(`addedVoices22 ${JSON.stringify(addedVoices)}`);

  const allPublicVoicesList = allPublicVoices?.allVoices?.nodes || [];

  console.log(`allPublicVoicesLis22t ${JSON.stringify(allPublicVoicesList)}`);

  //filter out all public voices that are already added by the user
  const filteredPublicVoicesResult: Maybe<Voice>[] = allPublicVoicesList.filter(
    (voice) => {
      return !addedVoices.find((addedVoice) => {
        console.log(`addedVoice22.voiceId ${addedVoice?.voiceId}`);
        return addedVoice?.voiceId === voice?.id;
      });
    }
  );

  useEffect(() => {
    setFilteredPublicVoices(filteredPublicVoicesResult);
  }, [allPublicVoices]);

  if (!allPublicVoices) {
    return <Loading></Loading>;
  }

  return (
    <div className="grid justify-center gap-3">
      <div>
        {isOnboardingAddPublicVoice && (
          <div className="text-xl justify-center flex">
            Add {tutorialAddVoicesCount - addedVoicesCount} voices to continue
          </div>
        )}
      </div>
      {filteredPublicVoices?.map((voice) => {
        if (!voice) return <div></div>;
        return (
          <SearchPublicVoiceRow
            filteredPublicVoices={filteredPublicVoices}
            setFilteredPublicVoices={setFilteredPublicVoices}
            isOnboardingAddPublicVoice
            setAddedVoicesCount={setAddedVoicesCount}
            voice={voice}
          />
        );
      })}
    </div>
  );
};

export default SearchPublicVoices;
