import { useState } from "react";
import useMakeBackendRequest from "./useMakeBackendRequest";

interface ConversationProps {
  userToolSubmissionResponseId: string;
  conversation: {
    [speaker: string]: string; // dynamic key-value pairs for speaker name and text
  }[];
  voices: {
    [speaker: string]: {
      voiceId: string;
    }; // dynamic key-value pairs for speaker name and ID
  };
}
const useCreateConversation = () => {
  const [conversationURL, setConversationURL] = useState("");
  const { post } = useMakeBackendRequest();

  const createDemoConversation = async (props: ConversationProps) => {
    const conversationDemoResponse = await post(
      `${process.env.REACT_APP_ML_ENGINE}/audio/createconversation`,
      props
    );
    setConversationURL(conversationDemoResponse.data.conversationURL);
  };

  return { conversationURL, createDemoConversation };
};

export default useCreateConversation;
