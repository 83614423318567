import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { signOut } from "../auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import LogoSrc from "../assets/logo.png";
import routes from "../constants/routes";
import { AppContext } from "../UserApp";
import {
  CurrencyDollarIcon,
  ArrowRightOnRectangleIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/solid";
import SiteBanner from "../home/banners/SiteBanner";
import {
  getOnboardingStatus,
  setOnboardingStatus,
} from "../home/onboarding/getOnboardingStatus";
import { ADD_PUBLIC_VOICE, SEARCH_PUBLIC_VOICE } from "../constants/Constants";

type NavHeaderProps = {
  user: firebase.User;
};

const NavHeader = (props: NavHeaderProps) => {
  let location = useLocation();
  const navigate = useNavigate();
  const app = useContext(AppContext);

  const onboardingStatus = getOnboardingStatus();

  const [stateOnboardingStatus, setStateOnboardingStatus] =
    useState(onboardingStatus);
  const IsTutorialAddVoice =
    stateOnboardingStatus?.hightLightTool === SEARCH_PUBLIC_VOICE;

  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
  const [showSideMenu, setShowSideMenu] = useState<boolean>(false); // Added state for side menu

  return (
    <>
      <div className="">{!onboardingStatus && <SiteBanner></SiteBanner>}</div>

      <div>
        <nav className="flex justify-center sticky top-0 z-40 bg-white md:h-fit pb-6 md:pb-0 h-28">
          <div className="flex flex-grow md:ml-36 justify-center items-center -mt-3">
            <div
              onClick={() => setShowSideMenu(!showSideMenu)}
              className={`
              flex text-4xl absolute top-0 left-0 mt-4 ml-4 p-3
              cursor-pointer
             ${IsTutorialAddVoice ? " bg-pulse-gradient" : ""}
              `}
            >
              ☰ {``}
              {IsTutorialAddVoice && (
                <div className="p-1">Open Menu to start</div>
              )}
            </div>
            <Link to={"/"} className="flex justify-center items-center">
              <img className="w-10 mr-2" alt="logo" src={LogoSrc}></img>
              <div className="text-center mt-2  text-xl">
                Clove
                {
                  //check if localhost
                  window.location.hostname === "localhost" && (
                    <span className="text-sm"> - {app.user?.id}</span>
                  )
                }
              </div>
            </Link>
          </div>

          {/* Added side menu */}
          <div
            className={`fixed top-0 left-0 h-full w-full md:w-64 bg-white shadow-md p-4 ${
              !showSideMenu ? "hidden" : ""
            }`}
          >
            {/* Add side menu content here  */}
            <div>
              <button
                onClick={() => setShowSideMenu(!showSideMenu)}
                className="text-4xl "
              >
                ☰
              </button>
            </div>
            <div className="font-semibold text-lg mb-2">Audio</div>
            <div className="ml-4 mb-4">
              <div
                className={`cursor-pointer hover:text-blue-600 text-lg sm:text-base
               `}
              >
                <Link
                  onClick={() => setShowSideMenu(false)}
                  to={routes.addVoice.path}
                >
                  Add Voice
                </Link>
              </div>
              <div className="cursor-pointer hover:text-blue-600 text-lg sm:text-base">
                <Link
                  onClick={() => {
                    setShowSideMenu(false);
                  }}
                  to={routes.listVoices.path}
                >
                  List Voices
                </Link>
              </div>
              <div
                onClick={() => {
                  if (onboardingStatus) {
                    setOnboardingStatus({
                      ...onboardingStatus,
                      hightLightTool: ADD_PUBLIC_VOICE,
                    });
                    console.log(`setStateOnboardingStatus`);
                    setStateOnboardingStatus((prevState) => {
                      if (prevState) {
                        return {
                          ...prevState,
                          hightLightTool: ADD_PUBLIC_VOICE,
                        };
                      } else {
                        return onboardingStatus;
                      }
                    });
                  } else {
                    console.log(`onboardingStatus is null`);
                  }
                  navigate(routes.voiceSearch.path);

                  setShowSideMenu(false);
                }}
              >
                <div
                  className={`
                cursor-pointer hover:text-blue-600 text-lg sm:text-base
                ${IsTutorialAddVoice ? " bg-pulse-gradient" : ""}`}
                >
                  Search Voices
                </div>
              </div>
            </div>
            {/* Add "signout" item at the bottom of side menu */}
            <div className="absolute bottom-4">
              <div
                onClick={() => {
                  signOut();
                  setShowSideMenu(false);
                  navigate("/");
                }}
                className="cursor-pointer hover:text-blue-600 text-lg sm:text-base"
              >
                Sign out
              </div>
            </div>
          </div>

          <div className="" />
          <div
            onClick={() => {
              setShowUserMenu(!showUserMenu);
            }}
            className="hidden md:grid inset-x-0 justify-end pr-10 cursor-pointer"
          >
            <div className="">
              <img
                alt="profile"
                className=" w-12 h-12 mt-2 rounded-full"
                src={`${props.user.photoURL}`}
              ></img>
            </div>
            <div className="mt-3">
              <div></div>
              <div
                className={`
            grid gap-4 pt-4
            fixed top-16 right-14 bg-white rounded-lg shadow-md p-4 ${
              !showUserMenu ? "hidden" : ""
            }`}
              >
                <div className=" border-b-2 grid gap-2 pb-2">
                  <div> {props.user.displayName}</div>
                  <div className="text-sm"> {props.user.email}</div>
                </div>
                <div
                  className="flex p-4 font-medium hover:no-underline hover:border-b-2 hover:border-gray-200 py-2"
                  onClick={() => {
                    setShowUserMenu(false);
                    navigate(routes.checkout.path);
                  }}
                >
                  <CurrencyDollarIcon className="h-6 w-6 text-gray-600 mr-2"></CurrencyDollarIcon>
                  Subscription
                </div>

                <div
                  className="flex p-4 font-medium hover:no-underline hover:border-b-2 hover:border-gray-200 py-2"
                  onClick={() => {
                    signOut();
                    setShowUserMenu(false);
                    navigate("/");
                  }}
                >
                  <ArrowRightOnRectangleIcon className="h-6 w-6 text-gray-600 mr-2"></ArrowRightOnRectangleIcon>

                  <div>Sign out</div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export { NavHeader };
