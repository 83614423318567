import { Navigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useEffect, useState, ReactElement } from "react";
import { NavHeader } from "../navigation/NavHeader";
import Footer from "../navigation/Footer";
import { Loading } from "../common/Loading";
import SideMenu from "../navigation/SideMenu";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

interface IAuthRouteProps {
  children: ReactElement;
  waitForChildren?: boolean;
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children } = props;
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [childrenLoaded, setChildrenLoaded] = useState<boolean>(
    !props.waitForChildren
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      setFirebaseUser(firebaseUser);
      setLoading(false);
    });
  }, []);

  if (loading || !children) {
    return <Loading></Loading>;
  }

  if (!firebaseUser) {
    const redirectPath = new URL(window.location.href).pathname;
    return <Navigate to={`/login?redirect=${redirectPath}`} />;
  }

  return (
    <div className="grid gap-2 pt-1">
      <NavHeader user={firebaseUser}></NavHeader>

      <div>
        <div className="">
          {childrenLoaded && <SideMenu />}
          <div className="px-3">
            <div>
              {React.cloneElement(children, {
                loaded: () => {
                  setChildrenLoaded(true);
                },
              })}
            </div>
          </div>
        </div>
      </div>

      {childrenLoaded && <Footer />}
      {
        // don't delete this makes notifications work
        <ToastContainer />
      }
    </div>
  );
};

export default AuthRoute;
