const trimAndAddEllipsis = (s: string) => {
  const maxLength = 27;
  if (s.length > maxLength) {
    return s.substring(0, maxLength).trim() + "...";
  } else {
    return s;
  }
};

function addRandomStringToFile(filename: string): string {
  const dotIndex = filename.lastIndexOf(".");
  let extension = "";
  let filenameWithoutExtension = filename;
  if (dotIndex !== -1) {
    extension = filename.slice(dotIndex + 1);
    filenameWithoutExtension = filename.slice(0, dotIndex);
  }
  const randomString = Math.random().toString(36).substr(2, 5);
  const newFilename = `${filenameWithoutExtension}_${randomString}${
    extension ? `.${extension}` : ""
  }`;
  return newFilename;
}

export { trimAndAddEllipsis, addRandomStringToFile };
