import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../UserApp";
import Stopwatch from "./StopWatch";
import { cloneVoiceSentences } from "./CloneVoiceConstants";
import {
  GrayCancelButtonStyle,
  ItemsCenterStyle,
  SuccessButtonStyle,
} from "../../constants/CSSConstants";
import { useReactMediaRecorder } from "react-media-recorder";
import Spinner from "../../common/Spinner";
import useUploadAudioFile from "../../hooks/useUploadAudioFile";
import { useMicrophoneEnabled } from "../../hooks/useMicrophoneEnabled";
import MicrophoneCheck from "./MicrophoneCheck";
import { useCloneVoice } from "./useClonevoice";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../constants/routes";

interface propState {
  voiceName: string;
  voiceId: string;
}

const MinSecondsToRecord: number = 4;

const AudioRecorderComponent = () => {
  const app = useContext(AppContext);
  const location = useLocation();

  let { voiceName, voiceId } = location.state as propState;

  const isMicrophoneEnabled = useMicrophoneEnabled();

  const [error, setError] = useState<string | null>(null);

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });

  const [numberOfVoiceSubmissions, setNumberOfVoiceSubmissions] = useState(0);
  const [retryVoiceUpload, setRetryVoiceUpload] = useState(false);
  const MIN_AMOUNT_OF_VOICE_SUBMISSIONS = 1;
  const [reviewingVoice, setReviewingVoice] = useState(false);
  const [voiceRetries, setVoiceRetries] = useState(0);
  const [userFileUploaded, setUserFileUploaded] = useState<boolean>(false);
  const [mediaBlobUrlsUploaded, setMediaBlobUrlsUploaded] = useState<string[]>(
    []
  );

  const [allowNext, setAllowNext] = useState(false);

  useEffect(() => {
    if (status === "recording") {
      setTimeout(() => {
        setAllowNext(true);
      }, MinSecondsToRecord * 1000);
    }
  }, [status]);

  const {
    cloneVoice,
    cloningVoice,
    voicePrintDemoUrl,
    voiceId: createdVoiceId,
  } = useCloneVoice();

  const {
    uploadUserRecording,
    userRecordingURL,
    cleanUserRecordingURL,
    filesUploadedCount,
  } = useUploadAudioFile();

  useEffect(() => {
    if (retryVoiceUpload && voiceRetries < 2) {
      setRetryVoiceUpload(false);
      setVoiceRetries(voiceRetries + 1);
      stopAndProcessRecording();
    }
  }, [retryVoiceUpload]);

  useEffect(() => {
    if (error) {
      stopAndProcessRecording();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [error]);

  useEffect(() => {
    if (
      mediaBlobUrl &&
      mediaBlobUrlsUploaded.includes(mediaBlobUrl) === false
    ) {
      setMediaBlobUrlsUploaded([...mediaBlobUrlsUploaded, mediaBlobUrl]);
      setUserFileUploaded(true);

      uploadUserRecording({
        mediaBlobUrl,
        userId: app.user.id,
        uploadBucket: "clove-ai-user-voices",
        uploadPath: voiceId,
        extend: false,
        fileName: voiceName,
      });
    }
  }, [mediaBlobUrl, userFileUploaded, app.user.id, uploadUserRecording]);

  const isFirstVoiceSubmission = () => {
    return numberOfVoiceSubmissions === 0;
  };

  const stopAndProcessRecording = () => {
    console.log("stop and process recording");
    stopRecording();
    setReviewingVoice(true);
  };
  const navigate = useNavigate();

  if (!voiceName) {
    navigate(routes.addVoice.path);
  }

  const refreshDataAndViewVoice = async (createdVoiceId: string) => {
    await app.refreshData();
    navigate(routes.viewVoice.path.replaceAll(":id", createdVoiceId));
  };

  console.log(`voiceName: ${voiceName}`);
  if (voicePrintDemoUrl && createdVoiceId) {
    refreshDataAndViewVoice(createdVoiceId);
  }

  if (isMicrophoneEnabled === false) {
    return (
      <div>
        <MicrophoneCheck></MicrophoneCheck>
      </div>
    );
  }

  return (
    <div className="">
      <div className=" text-center mb-10">
        <div className="text-3xl ">Create your Voice Print</div>
      </div>
      <div
        className={`${
          status === "recording" || reviewingVoice || cloningVoice
            ? "hidden"
            : ""
        } text-center mb-10`}
      >
        <div className="hidden text-3xl"> - userId -{app.user.id}</div>

        {isFirstVoiceSubmission() && (
          <div className="grid gap-3">
            <div className="text-lg">
              Voice Print, the revolutionary feature that transforms your voice
              into a powerful tool for creating audio content.
            </div>
            <div>
              With Voice Print, you can effortlessly create audio books,
              podcasts, and more, using your own unique voice
            </div>
            <div className="my-10">
              Please ensure that you are in a quiet environment to minimize any
              background noise that may affect the quality of your recording
            </div>
          </div>
        )}
        {!isFirstVoiceSubmission() && (
          <div className="grid gap-3">
            <div className="text-xl">
              Congratulations! You have successfully submitted a voice sample.
            </div>

            <div className="text-lg">
              {numberOfVoiceSubmissions} of {MIN_AMOUNT_OF_VOICE_SUBMISSIONS}{" "}
              voice samples submitted
            </div>
            <div></div>
          </div>
        )}
      </div>

      {status !== "recording" && !cloningVoice && !reviewingVoice && (
        <div>
          <div className="flex justify-center">
            <button
              className="
           w-1/6
          bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={startRecording}
              // disabled={status !== "recording"}
            >
              {numberOfVoiceSubmissions === 0
                ? "Create your Voice Print"
                : "Read next text"}
            </button>
          </div>
        </div>
      )}

      <div className="grid justify-center">
        {status === "recording" && (
          <div>
            <div></div>

            <div
              className="flex flex-col
             justify-center"
            >
              <div className=" flex justify-center text-2xl md:my-5">
                Please read the following text
              </div>
              <div className="grid grid-cols-2 gap-2 justify-center md:px-48 px-5 my-10">
                {cloneVoiceSentences[numberOfVoiceSubmissions]
                  .slice(0, 10)
                  .map((sentence: string, index) => {
                    return <div key={index}>{sentence}</div>;
                  })}

                {/* {
                  cloneVoiceScripts[
                    getNumberOfVoiceSubmissions() + numberOfVoiceSubmissions
                  ]
                } */}
              </div>
            </div>
            <div className=" text-2xl flex justify-center">
              <div>
                <Stopwatch />
              </div>
            </div>
          </div>
        )}
      </div>

      {reviewingVoice && !userRecordingURL && (
        <div>
          <div className="text-xl flex justify-center">
            Processing audio please wait
          </div>
          <Spinner></Spinner>
        </div>
      )}

      {reviewingVoice && userRecordingURL && (
        <div className="flex justify-center my-5 w-full">
          <div className="w-1/3">
            <div className={`${ItemsCenterStyle} text-2xl`}>
              Review your recording
            </div>
            <div className="flex justify-center">
              <audio className="my-10" src={mediaBlobUrl} controls />
            </div>
            <div className="flex justify-between">
              <button
                className={`${SuccessButtonStyle}`}
                disabled={!userFileUploaded}
                onClick={async () => {
                  setNumberOfVoiceSubmissions(numberOfVoiceSubmissions + 1);
                  setReviewingVoice(false);
                  setUserFileUploaded(false);
                  // setTimeRecording(0);
                  cleanUserRecordingURL();
                  if (
                    numberOfVoiceSubmissions + 1 ===
                    MIN_AMOUNT_OF_VOICE_SUBMISSIONS
                  ) {
                    if (!app.user.id) {
                      throw new Error("User id is undefined");
                    }
                    cloneVoice({
                      userId: app.user.id,
                      numberOfVoiceSubmissions: numberOfVoiceSubmissions,
                      userName: app.user.name,
                      userRecordingURL: userRecordingURL,
                      voiceName: voiceName,
                      voiceId: voiceId,
                    });
                  }
                }}
              >
                Sounds good!
              </button>
              <button className={`${GrayCancelButtonStyle}`}>
                Discard recording
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center">
        {allowNext && !cloningVoice && !reviewingVoice && (
          <div>
            <button
              onClick={() => {
                if (!cloningVoice) {
                  stopAndProcessRecording();
                }
              }}
              className={`${SuccessButtonStyle}`}
            >
              Next
            </button>
          </div>
        )}
      </div>

      <div>
        {cloningVoice && (
          <div className="grid justify-center">
            <div className="text-xl">Creating your Voice Print</div>
            <Spinner></Spinner>
          </div>
        )}
      </div>
      <div className="hidden text-3xl text-red-600">{error}</div>
    </div>
  );
};

export default AudioRecorderComponent;
