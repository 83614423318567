import { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const useGetGraphQLClient = () => {
  const [firebaseToken, setFirebaseToken] = useState<string>();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setFirebaseToken(token);
      } else {
        setFirebaseToken(undefined);
      }
    });
    return () => unsubscribe();
  }, []);

  const getgrahphQLClient: (
    token: string
  ) => ApolloClient<NormalizedCacheObject> = (token: string) => {
    return new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_URL,
      cache: new InMemoryCache(),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return { getgrahphQLClient, firebaseToken };
};

export default useGetGraphQLClient;
