import { useState } from "react";

type ToolResponseprops = {
  content: string;
  renderRowActions?: (
    key: string,
    rowContent: string,
    idx: number
  ) => JSX.Element;
  onEditBlur?: (
    idx: number,
    key: string,
    value: string,
    initialContent: string
  ) => void;
  rowCss?: string;
  editRowOnClick?: boolean;
  idx: number;
  contentKey: string;
};
const ToolResponseRow = (props: ToolResponseprops) => {
  const [rowContent, setRowContent] = useState<string>(props.content);
  const [initialContent, setOriginalContent] = useState<string>(props.content);

  const [editing, setEditing] = useState<boolean>(false);

  console.log(
    `got key: ${props.contentKey} and content: ${props.content} and idx: ${props.idx} and editing: ${editing} and rowContent: ${rowContent} `
  );
  if (editing) {
    return (
      <div>
        <textarea
          className={`w-full bg-transparent`}
          autoFocus
          onFocus={(e) => {}}
          onKeyDown={(e) => {
            // on enter key press blur
            if (e.key === "Enter") {
              e.currentTarget.blur();
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setRowContent(e.target.value);
          }}
          onBlur={() => {
            setEditing(false);
            if (props.onEditBlur) {
              props.onEditBlur(
                props.idx,
                props.contentKey,
                rowContent,
                initialContent
              );
            }
          }}
          value={rowContent}
        ></textarea>
      </div>
    );
  }
  return (
    <span className="grid md:grid-cols-12">
      <div
        onClick={() => {
          if (props.editRowOnClick) {
            setEditing(true);
          }
        }}
        className={`${
          props.renderRowActions ? "md:col-span-10" : "md:col-span-12"
        }`}
      >
        {rowContent}
      </div>
      <div className={`${props.renderRowActions ? "md:col-span-2" : "hidden"}`}>
        {props.renderRowActions &&
          props.renderRowActions(props.contentKey, rowContent, props.idx)}
      </div>
    </span>
  );
};

export default ToolResponseRow;
