import Spinner from "./Spinner";

class LoadingProps {
  hideSpinner?: boolean = false;
}

const Loading = (props?: LoadingProps) => {
  const hideSpinner = props?.hideSpinner;
  return (
    <div className="grid justify-center mt-50 w-full">
      <div
        className=" w-14 
      mt-56"
      >
        Clove
      </div>
      <div>{!hideSpinner && <Spinner />}</div>
    </div>
  );
};

export { Loading };
