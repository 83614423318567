const firstStoryTasksAndActivities = [
  "The sun rose, and a young boy was already there to see it. He watched as a birch canoe slid on the smooth planks of a lake, seemingly gliding effortlessly through the water.",
  "A man was faced with four hours of steady work. He started by gluing a sheet to a dark blue background, carefully ensuring that the bond was strong.",
  "Another person was tasked with the simple but important job of determining the depth of a well. It was easy to tell with just a glance.",
  "One rare dish that could be found that day was a chicken leg, a delicacy in these times. Rice, on the other hand, was a more common sight. It was often served in round bowls, a staple food for many. The juice of lemons made a fine punch, adding a tangy flavor to the drink.",
  "A box was thrown beside a parked truck, its contents unknown. Meanwhile, hogs were being fed chopped corn and garbage, their squeals of delight filling the air.",
  "A young girl at a booth sold fifty bonds, her financial savvy earning her a tidy sum. But the small pup gnawing a hole in a sock was more interested in play than work. The fish, however, twisted and turned on the bent hook, trying to free itself from the fisherman’s line.",
  "Other tasks that needed to be done included pressing pants and sewing a button on a vest. A swan dive was attempted, but it fell far short of perfection. The beauty of the view, however, still stunned the young boy, leaving him in awe. Two blue fish swam in a tank, their graceful movements a sight to behold.",
  "A woman stumbled and fell, but a soft cushion broke her fall. A man came to her aid, helping her back to her feet. As the day wore on, a pot of tea helped to pass the evening, while smoky fires lacked both flame and heat. The salt breeze came across from the sea, refreshing those who were nearby.",
  "As the sun began to set, a man wiped the grease off his dirty face and mended his coat before heading out. But his wrist was badly strained and hung limp, making the task difficult. A stray cat gave birth to kittens, adding to the hustle and bustle of the day. The young girl gave no clear response when asked a question, her thoughts unknown. The meal was cooked before the bell rang, satisfying the hunger of those who were waiting.",
  "There was joy in living, a reminder of the simple pleasures in life. A king had once ruled the state in the early days, his legacy still remembered. But a ship was torn apart on a sharp reef, a warning of the dangers that lay ahead. Sickness kept a man at home for the third week, but the wide road shimmered in the hot sun for those who were well. The lazy cow lay in the cool grass, taking a well-deserved rest.",
  "A task of lifting a square stone over a fence was accomplished with ease. The rope bound the seven books at once, making the job quicker. One could hop over the fence and plunge into the refreshing water of the lake by taking the winding path. The size of the gas tank was noted closely, an important detail to remember.",
  "A cup of sugar made sweet fudge, a treat for those with a sweet tooth. A rosebush was placed near the porch steps, adding a touch of beauty to the surroundings. Tragically, both a man and woman lost their lives in the raging storm, a reminder of the fragility of life.",
];

export const firstCloneVoiceSentences: string[] = [
  "The birch canoe slid on the smooth planks.",
  "Glue the sheet to the dark blue background.",
  "It's easy to tell the depth of a well.",
  "These days a chicken leg is a rare dish.",
  "Rice is often served in round bowls.",
  "The juice of lemons makes fine punch.",
  "The box was thrown beside the parked truck.",
  "The hogs were fed chopped corn and garbage.",
  "Four hours of steady work faced us.",
  "A large size in stockings is hard to sell.",
  "The boy was there when the sun rose.",
  "A rod is used to catch pink salmon.",
  "The source of the huge river is the clear spring.",
  "Kick the ball straight and follow through.",
  "Help the woman get back to her feet.",
  "A pot of tea helps to pass the evening.",
  "Smoky fires lack flame and heat.",
  "The soft cushion broke the man's fall.",
  "The salt breeze came across from the sea.",
  "The girl at the booth sold fifty bonds.",
  "The small pup gnawed a hole in the sock.",
  "The fish twisted and turned on the bent hook.",
  "Press the pants and sew a button on the vest.",
  "The swan dive was far short of perfect.",
  "The beauty of the view stunned the young boy.",
  "Two blue fish swam in the tank.",
  "Her purse was full of useless trash.",
  "The colt reared and threw the tall rider.",
  "It snowed, rained, and hailed the same morning.",
  "Read verse out loud for pleasure.",
];

const secondVoicesentences: string[] = [
  "Hoist the load to your left shoulder.",
  "Take the winding path to reach the lake.",
  "Note closely the size of the gas tank.",
  "Wipe the grease off his dirty face.",
  "Mend the coat before you go out.",
  "The wrist was badly strained and hung limp.",
  "The stray cat gave birth to kittens.",
  "The young girl gave no clear response.",
  "The meal was cooked before the bell rang.",
  "What joy there is in living.",
  "A king ruled the state in the early days.",
  "The ship was torn apart on the sharp reef.",
  "Sickness kept him home the third week.",
  "The wide road shimmered in the hot sun.",
  "The lazy cow lay in the cool grass.",
  "Lift the square stone over the fence.",
  "The rope will bind the seven books at once.",
  "Hop over the fence and plunge in.",
  "The friendly gang left the drug store.",
  "Mesh wire keeps chicks inside.",
  "The frosty air passed through the coat.",
  "The crooked maze failed to fool the mouse.",
  "Adding fast leads to wrong sums.",
  "The show was a flop from the very start.",
  "A saw is a tool used for making boards.",
  "The wagon moved on well oiled wheels.",
  "March the soldiers past the next hill.",
  "A cup of sugar makes sweet fudge.",
  "Place a rosebush near the porch steps.",
  "Both lost their lives in the raging storm.",
];

const thirdVoiceSentences: string[] = [
  "We talked of the side show in the circus.",
  "Use a pencil to write the first draft.",
  "He ran half way to the hardware store.",
  "The clock struck to mark the third period.",
  "A small creek cut across the field.",
  "Cars and busses stalled in snow drifts.",
  "The set of china hit the floor with a crash.",
  "This is a grand season for hikes on the road.",
  "The dune rose from the edge of the water.",
  "Those words were the cue for the actor to leave.",
  "A yacht slid around the point into the bay.",
  "The two met while playing on the sand.",
  "The ink stain dried on the finished page.",
  "The walled town was seized without a fight.",
  "The lease ran out in sixteen weeks.",
  "A tame squirrel makes a nice pet.",
  "The horn of the car woke the sleeping cop.",
  "The heart beat strongly and with firm strokes.",
  "The pearl was worn in a thin silver ring.",
  "The fruit peel was cut in thick slices.",
  "The Navy attacked the big task force.",
  "See the cat glaring at the scared mouse.",
  "There are more than two factors here.",
  "The hat brim was wide and too droopy.",
  "The lawyer tried to lose his case.",
  "The grass curled around the fence post.",
  "Cut the pie into large parts.",
  "Men strive but seldom get rich.",
  "Always close the barn door tight.",
  "He lay prone and hardly moved a limb.",
];

const fourthVoiceSentences = [
  "The slush lay deep along the street.",
  "A wisp of cloud hung in the blue air.",
  "A pound of sugar costs more than eggs.",
  "The fin was sharp and cut the clear water.",
  "The play seems dull and quite stupid.",
  "Bail the boat to stop it from sinking.",
  "The term ended in late June that year.",
  "A tusk is used to make costly gifts.",
  "Ten pins were set in order.",
  "The bill was paid every third week.",
  "Oak is strong and also gives shade.",
  "Cats and dogs each hate the other.",
  "The pipe began to rust while new.",
  "Open the crate but don't break the glass.",
  "Add the sum to the product of these three.",
  "Thieves who rob friends deserve jail.",
  "The ripe taste of cheese improves with age.",
  "Act on these orders with great speed.",
  "The hog crawled under the high fence.",
  "Move the vat over the hot fire.",
  "The bark of the pine tree was shiny and dark.",
  "Leaves turn brown and yellow in the fall.",
  "The pennant waved when the wind blew.",
  "Split the log with a quick, sharp blow.",
  "Burn peat after the logs give out.",
  "He ordered peach pie with ice cream.",
  "Weave the carpet on the right hand side.",
  "Hemp is a weed found in parts of the tropics.",
  "A lame back kept his score low.",
  "We find joy in the simplest things.",
];

const storyArray: string[] = [
  "Once upon a time, in a small town, there was a man who lived a simple life. His days were filled with tasks that he carried out with determination. He knew that life was hard, and men often strived to get rich, but he also knew that wealth was not always attainable.",
  "One day, he was cutting a pie into large parts when he remembered to close the barn door tight. He lay prone and hardly moved a limb, taking a moment to rest from his daily chores. The slush lay deep along the street, but he was not discouraged by the weather. Instead, he gazed up at the sky and saw a wisp of cloud hanging in the blue air.",
  "As he went about his day, he noticed that the cost of living had gone up. A pound of sugar now cost more than eggs. He sighed, but continued on. He walked down to the river, where he saw a fish with a fin so sharp that it cut through the clear water. The man thought about how life was like that fish, always moving forward, never looking back.",
  "He then made his way to the town square, where a play was being performed. To his disappointment, the play seemed dull and quite stupid. He longed for something more exciting. He then remembered the boat that he had to bail out to prevent it from sinking. The term ended in late June that year, and he was happy to have finished another year of hard work.",
  "As he walked home, he came across a man who was carving a Tusk, which would be used to make costly gifts. The man marveled at the intricate work that went into such a piece. He then saw ten pins that had been set in order, ready for a game of bowling. He smiled, remembering how much he enjoyed that pastime.",
  "The man’s life was filled with routine. Every third week, he paid his bills, and he knew that he had to be careful with his money. The strong oak trees that lined his street provided him with both strength and shade. He knew that life was like those trees, strong and steadfast, never giving up.",
  "Cats and dogs each hated the other, and he chuckled at the thought. He then noticed that his pipe had begun to rust, even though it was brand new. He shook his head and made his way home, where he opened a crate filled with fragile glass. He was careful not to break the glass as he added the sum to the product of these three.",
  "The man knew that thieves who robbed friends deserved to be put in jail, and he was grateful that he had never encountered such a situation in his life. He also knew that the ripe taste of cheese only improved with age, just like the lessons that life taught him. He was instructed to act on these orders with great speed, and he did so with determination.",
  "The hog crawled under the high fence, and he marveled at the resilience of the animal. He then remembered the vat that needed to be moved over the hot fire, and he quickly got to work. The bark of the pine tree was shiny and dark, and he thought about how life was like that tree, always adapting to its surroundings.",
  "The leaves turn brown and yellow in the fall, and he was reminded of the changing seasons. The pennant waved in the wind, and he thought about how life was like that flag, always moving forward, never looking back. He split the log with a quick, sharp blow, and then burned the peat after the logs gave out.",
  "Finally, he sat down for dinner and ordered peach pie with ice cream.",
  "We had a delightful conversation about the side show at the circus. My companion suggested that I jot down my thoughts in a notebook, using a pencil as a first draft. Excited by the idea, I headed out to purchase a notebook, but only made it halfway to the hardware store. Suddenly, the clock tower rang out, marking the beginning of the third period.",
  "As I continued my journey, I noticed a small creek cutting through a field. The snow was so heavy that cars and buses were getting stuck in drifts. I hurried inside to avoid the inclement weather and was greeted by the sound of a set of china hitting the floor and shattering. Despite the winter weather, it was still a grand season for hikes along the roads.",
  "A dune rose up from the edge of the water as I gazed out the window. Those were the cue for an actor to take his leave. I watched as a yacht slid gracefully around a point and into the bay. I couldn’t help but smile as I remembered the time I met a friend while playing on the sand.",
  "I sat down to write and watched as the ink stain dried on the finished page. The walled town was taken without a fight and the lease would run out in sixteen weeks. A pet squirrel was running around the room, clearly well-behaved. Just then, the horn of a car blared, waking up a sleeping cop.",
  "My heart beat strongly, with firm strokes, and I couldn’t help but touch the pearl I was wearing in a thin silver ring. The fruit peel was cut into thick slices, reminding me of the Navy’s attack on the big task force. I turned to see a cat glaring at a scared mouse and knew there were more than two factors at play. The hat brim was wide and droopy, much like the lawyer trying to lose his case.",
  "The grass curled around the fence post as I walked outside.",
];

export const cloneVoiceSentences: string[][] = [
  firstStoryTasksAndActivities,
  storyArray,
  firstCloneVoiceSentences,
  secondVoicesentences,
  thirdVoiceSentences,
  fourthVoiceSentences,
];

export const cloneVoiceScripts = [
  `It was a busy day filled with a multitude of tasks and activities. The sun rose, and a young boy was already there to see it. He watched as a birch canoe slid on the smooth planks of a lake, seemingly gliding effortlessly through the water.

  In the meantime, a man was faced with four hours of steady work. He started by gluing a sheet to a dark blue background, carefully ensuring that the bond was strong. Another person was tasked with the simple but important job of determining the depth of a well. It was easy to tell with just a glance.
  
  One rare dish that could be found that day was a chicken leg, a delicacy in these times. Rice, on the other hand, was a more common sight. It was often served in round bowls, a staple food for many. The juice of lemons made a fine punch, adding a tangy flavor to the drink.
  
  A box was thrown beside a parked truck, its contents unknown. Meanwhile, hogs were being fed chopped corn and garbage, their squeals of delight filling the air.
  
  A young girl at a booth sold fifty bonds, her financial savvy earning her a tidy sum. But the small pup gnawing a hole in a sock was more interested in play than work. The fish, however, twisted and turned on the bent hook, trying to free itself from the fisherman’s line.
  
  Other tasks that needed to be done included pressing pants and sewing a button on a vest. A swan dive was attempted, but it fell far short of perfection. The beauty of the view, however, still stunned the young boy, leaving him in awe. Two blue fish swam in a tank, their graceful movements a sight to behold.
  
  A woman stumbled and fell, but a soft cushion broke her fall. A man came to her aid, helping her back to her feet. As the day wore on, a pot of tea helped to pass the evening, while smoky fires lacked both flame and heat. The salt breeze came across from the sea, refreshing those who were nearby.
  
  As the sun began to set, a man wiped the grease off his dirty face and mended his coat before heading out. But his wrist was badly strained and hung limp, making the task difficult. A stray cat gave birth to kittens, adding to the hustle and bustle of the day. The young girl gave no clear response when asked a question, her thoughts unknown. The meal was cooked before the bell rang, satisfying the hunger of those who were waiting.
  
  There was joy in living, a reminder of the simple pleasures in life. A king had once ruled the state in the early days, his legacy still remembered. But a ship was torn apart on a sharp reef, a warning of the dangers that lay ahead. Sickness kept a man at home for the third week, but the wide road shimmered in the hot sun for those who were well. The lazy cow lay in the cool grass, taking a well-deserved rest.
  
  A task of lifting a square stone over a fence was accomplished with ease. The rope bound the seven books at once, making the job quicker. One could hop over the fence and plunge into the refreshing water of the lake by taking the winding path. The size of the gas tank was noted closely, a important detail to remember.
  
  A cup of sugar made sweet fudge, a treat for those with a sweet tooth. A rosebush was placed near the porch steps, adding a touch of beauty to the surroundings. Tragically, both a man and woman lost their lives in the raging storm, a reminder of the fragility of life.
  
  `,
  `Once upon a time, in a small town, there was a man who lived a simple life. His days were filled with tasks that he carried out with determination. He knew that life was hard, and men often strived to get rich, but he also knew that wealth was not always attainable.

  One day, he was cutting a pie into large parts when he remembered to close the barn door tight. He lay prone and hardly moved a limb, taking a moment to rest from his daily chores. The slush lay deep along the street, but he was not discouraged by the weather. Instead, he gazed up at the sky and saw a wisp of cloud hanging in the blue air.
  
  As he went about his day, he noticed that the cost of living had gone up. A pound of sugar now cost more than eggs. He sighed, but continued on. He walked down to the river, where he saw a fish with a fin so sharp that it cut through the clear water. The man thought about how life was like that fish, always moving forward, never looking back.
  
  He then made his way to the town square, where a play was being performed. To his disappointment, the play seemed dull and quite stupid. He longed for something more exciting. He then remembered the boat that he had to bail out to prevent it from sinking. The term ended in late June that year, and he was happy to have finished another year of hard work.
  
  As he walked home, he came across a man who was carving a Tusk, which would be used to make costly gifts. The man marveled at the intricate work that went into such a piece. He then saw ten pins that had been set in order, ready for a game of bowling. He smiled, remembering how much he enjoyed that pastime.
  
  The man’s life was filled with routine. Every third week, he paid his bills, and he knew that he had to be careful with his money. The strong oak trees that lined his street provided him with both strength and shade. He knew that life was like those trees, strong and steadfast, never giving up.
  
  Cats and dogs each hated the other, and he chuckled at the thought. He then noticed that his pipe had begun to rust, even though it was brand new. He shook his head and made his way home, where he opened a crate filled with fragile glass. He was careful not to break the glass as he added the sum to the product of these three.
  
  The man knew that thieves who robbed friends deserved to be put in jail, and he was grateful that he had never encountered such a situation in his life. He also knew that the ripe taste of cheese only improved with age, just like the lessons that life taught him. He was instructed to act on these orders with great speed, and he did so with determination.
  
  The hog crawled under the high fence, and he marveled at the resilience of the animal. He then remembered the vat that needed to be moved over the hot fire, and he quickly got to work. The bark of the pine tree was shiny and dark, and he thought about how life was like that tree, always adapting to its surroundings.
  
  The leaves turn brown and yellow in the fall, and he was reminded of the changing seasons. The pennant waved in the wind, and he thought about how life was like that flag, always moving forward, never looking back. He split the log with a quick, sharp blow, and then burned the peat after the logs gave out.
  
  Finally, he sat down for dinner and ordered peach pie with ice cream.
  
  We had a delightful conversation about the side show at the circus. My companion suggested that I jot down my thoughts in a notebook, using a pencil as a first draft. Excited by the idea, I headed out to purchase a notebook, but only made it halfway to the hardware store. Suddenly, the clock tower rang out, marking the beginning of the third period.
  
  As I continued my journey, I noticed a small creek cutting through a field. The snow was so heavy that cars and buses were getting stuck in drifts. I hurried inside to avoid the inclement weather and was greeted by the sound of a set of china hitting the floor and shattering. Despite the winter weather, it was still a grand season for hikes along the roads.
  
  A dune rose up from the edge of the water as I gazed out the window. Those were the cue for an actor to take his leave. I watched as a yacht slid gracefully around a point and into the bay. I couldn’t help but smile as I remembered the time I met a friend while playing on the sand.
  
  I sat down to write and watched as the ink stain dried on the finished page. The walled town was taken without a fight and the lease would run out in sixteen weeks. A pet squirrel was running around the room, clearly well-behaved. Just then, the horn of a car blared, waking up a sleeping cop.
  
  My heart beat strongly, with firm strokes, and I couldn’t help but touch the pearl I was wearing in a thin silver ring. The fruit peel was cut into thick slices, reminding me of the Navy’s attack on the big task force. I turned to see a cat glaring at a scared mouse and knew there were more than two factors at play. The hat brim was wide and droopy, much like the lawyer trying to lose his case.
  
  The grass curled around the fence post as I walked outside.
  
  `,
  `There was a cat who loved to chase birds. One day, he saw a beautiful bird in his garden and immediately began to chase it. The bird flew high into the sky, but the cat was determined to catch it. He ran and ran, but the bird was too fast. Eventually, the bird flew away and the cat was left feeling sad and disappointed. The bird, on the other hand, felt happy and relieved that he had escaped from the cat. He knew that he had to be careful and stay away from the cat in the future. After the cat failed to catch the bird, he decided to watch the birds from far away to learn about their behavior. He noticed how they flew and where they liked to rest. He waited quietly for the right time to catch them. The cat was patient and didn't rush. He learned that by being calm and careful, he could catch the birds without hurting them.The cat realized that it wasn't just about catching birds, but also about respecting them and their freedom. He became friends with the birds and enjoyed their company. The garden became a peaceful place where birds and the cat lived in harmony.As time passed, the cat grew fond of the birds and enjoyed watching them from afar. He no longer had the urge to chase them, but instead appreciated their beauty and elegance. The birds, in turn, became comfortable around the cat and would sometimes perch on his back while he lay in the sun.   `,
  `Emily woke up on the morning of her birthday feeling very excited. She knew that her parents were planning a special birthday party for her that day. She could hardly wait to see her friends and eat cake.
  When the party began, Emily's friends arrived one by one with presents in their hands. They played games, danced, and had a great time together. Emily felt so happy and loved. As the party was coming to an end, Emily's parents brought out a big, beautifully decorated cake with sparklers on top. Everyone sang "Happy Birthday" as Emily made a wish and blew out the candles. She couldn't wait to see what her parents had gotten her for her birthday.As she began to open her presents, she was surprised to find that her parents had given her a small box wrapped in shiny gold paper. She opened it eagerly and found a shiny silver key inside.`,
  `Sarah loved her toy more than anything in the world. She took it with her everywhere she went. One day, when Sarah was playing at the park, she accidentally dropped her toy and didn't realize it. She searched everywhere for it, but it was nowhere to be found. Sarah felt very sad and started to cry. A kind man saw Sarah crying and asked her what was wrong. Sarah explained that she had lost her toy and didn't know where to find it. The man promised to help her and started searching for the toy.After a few minutes of searching, the man found the toy hiding under a nearby bench. He picked it up and gave it to Sarah, who was overjoyed to be reunited with her beloved toy. She hugged the man tightly, thanking him for his help.  `,
  `Once upon a time, a lion was sleeping in the forest. A mouse saw the lion and started playing around him. The lion woke up and was about to eat the mouse when the mouse begged for mercy and promised to help the lion one day.
  The lion laughed at the mouse and let him go. However, one day, the lion was caught in a hunter's trap, and he couldn't escape. The mouse heard the lion's roar and went to see what was happening.When the mouse saw that the lion was trapped, he remembered the promise he made and knew that it was his chance to repay the lion's kindness. The mouse scurried around the trap and saw that the rope was tied tightly around the lion's leg. The mouse gnawed at the rope with all his might until the lion was finally free.  `,
  `One day, a hare met a tortoise on the road. The hare made fun of the tortoise for being so slow, and the tortoise challenged the hare to a race. The hare laughed at the tortoise and agreed to race him.
  During the race, the hare ran as fast as he could and soon became tired. He decided to take a nap, thinking that he had plenty of time to catch up to the slow-moving tortoise.As the hare slept, the tortoise continued to move slowly but steadily towards the finish line. When the hare woke up, he realized that he had overslept and that the tortoise was nowhere in sight. He ran as fast as he could towards the finish line, but it was too late. The tortoise had already crossed the line and won the race.After the race was over, the hare was shocked and embarrassed that he had lost to the slow-moving tortoise.  `,
  `Once upon a time, there was a little girl named Rose who loved to play in the forest. One day, she got lost and couldn't find her way back home. As night fell, Rose began to feel scared and lonely. Suddenly, she saw a light in the distance and started running towards it. When she arrived, she found a kind old woman who welcomed her into her cottage and gave her food and shelter for the night. The next day, the old woman helped Rose find her way back home.Rose was overjoyed to be back home with her family, but she couldn't forget the kind old woman who had helped her when she was lost. She often thought about her and wondered how she was doing.One day, Rose decided to visit the old woman to thank her for her kindness. She asked her parents for permission and set off towards the forest. It was a long and tiring journey, but Rose was determined to show her gratitude.  `,
  `Tom was a young boy who loved to play with his toy car. He dreamt of driving a real car when he grew up. One day, he saw a car parked in the driveway of his neighbor's house. He went over to take a closer look, and the owner of the car, an old man, saw him and asked if he wanted to take a ride. Tom couldn't believe his luck and eagerly accepted. The old man took Tom for a drive around town and showed him all the sights. Tom realized that driving was even better than he had imagined.From that day on, Tom couldn't stop thinking about driving. He begged his parents to take him for driving lessons, but they told him he had to wait until he was older. Tom was disappointed but he didn't give up on his dream. He spent hours reading books and watching videos about cars and driving.  `,
  `Amanda was a talented musician who dreamed of playing at the grand concert hall in the city. She practiced every day, hoping to get noticed by the concert organizers. One day, while playing her violin in the park, a famous conductor heard her and was so impressed that he invited her to perform at the concert hall. Amanda was overjoyed and practiced even harder for the big day. When the concert arrived, she played flawlessly and received a standing ovation from the audience.  `,
  `Alex was a successful businessman who was always busy with work. He had little time for anything else, including his family. One day, he realized that he had missed his daughter's ballet recital because of work. He felt terrible and decided to make it up to her by taking her on a weekend trip to the beach. They spent two days playing in the sand, swimming in the ocean, and having fun together. Alex realized that there was more to life than just work.`,
  `James was a brave firefighter who loved his job. One day, he received a call about a fire at a local pet store. When he arrived, he saw that the building was engulfed in flames, and the animals inside were in danger. James knew that he had to act fast. He put on his gear and ran into the burning building, rescuing several cats and dogs. The owners of the pet store were so grateful that they named a section of the store after him. James felt proud and honored to have helped save the animals' lives.  `,
];

export const ReviewVoiceScripts = [
  `Once upon a time, there was a little village in the countryside. One day, a young traveler arrived and explored the hills. He found a glowing crystal in a hidden cave behind a waterfall. The villagers were amazed and believed it was magic. The village prospered for many years, and the traveler stayed on as a part of the community. The villagers were happy and lived in peace. `,
];
