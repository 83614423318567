import { DELETE_FILES_ENDPOINT } from "./../constants/Constants";
import useMakeBackendRequest from "./useMakeBackendRequest";

type DeleteBucketFileProps = {
  fileName: string;
};
const useDeleteBucketFile = () => {
  const { post } = useMakeBackendRequest();

  const deleteBucketFile = async (props: DeleteBucketFileProps) => {
    const result = await post(DELETE_FILES_ENDPOINT, {
      fileName: props.fileName,
    });
    return result;
  };

  return { deleteBucketFile };
};
export default useDeleteBucketFile;
