import {
  SpeakerWaveIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
import { SuccessButtonStyle } from "../../../../../constants/CSSConstants";
import { KeyAndIdx, UpdateConversationResponse } from "./ResponseWorkshop";
import { AxiosResponse } from "axios";
import useWorkshopTextToSpeech from "./useWorkshopTextToSpeech";
import { UPDATE_CONVERSATION_ENDPOINT } from "../../../../../constants/Constants";
import useMakeBackendRequest from "../../../../../hooks/useMakeBackendRequest";
import { TextToSpeechSubmission } from "../../../../../generated/graphql";
import { useState } from "react";
import Spinner from "../../../../../common/Spinner";

type WorkshopAudioActionsProps = {
  audioURL?: string | null;
  contentKeyAndIdxMap: Map<string, KeyAndIdx>;
  textToSpeeches: any;
  refetchTextToSpeeches: () => Promise<any>;
  noAudioForText: Map<string, boolean>;
  contentKeys: Set<string>;
  contentKeyVoiceId: Map<string, string>;
  userToolSubmissionResponseId: string;
  refetch: () => void;
  setContentKeyVoiceId: (prev: any) => any;
  textToSpeechIdxHistory: Map<number, TextToSpeechSubmission>;
  setTextToSpeechIdxHistory: (prev: any) => any;
  setAudioURL: (prev: any) => any;
  submissionResponse: any;
  charactersTextMissingAudio: number;
  updateContentUserToolSubmissionResponseById: any;
  highLightAction: boolean;
};

const WorkshopAudioActions = (props: WorkshopAudioActionsProps) => {
  const { post } = useMakeBackendRequest();

  const [isUpdatingAudio, setIsUpdatingAudio] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const updateResponseAudio = async () => {
    const updatedConv: AxiosResponse<UpdateConversationResponse> = await post(
      UPDATE_CONVERSATION_ENDPOINT,
      {
        userToolSubmissionResponseId: props.userToolSubmissionResponseId,
      }
    );
    props.setAudioURL(updatedConv.data.audioURL);

    await props.updateContentUserToolSubmissionResponseById({
      variables: {
        id: props.userToolSubmissionResponseId,
        response: JSON.stringify(props.submissionResponse.response),
        audioUrl: updatedConv.data.audioURL,
      },
    });
    props.refetch();

    setIsUpdatingAudio(false);
  };

  const { generateTextToSpeech } = useWorkshopTextToSpeech(
    props.textToSpeeches || null,
    props.contentKeyVoiceId
  );

  const recreateAudio = async () => {
    if (props.contentKeys.size > props.contentKeyVoiceId.size) {
      alert("please assign missing voices");
      return;
    }
    setIsUpdatingAudio(true);

    const speechPromise = Array.from(props.noAudioForText.entries()).map(
      ([key, value]) => {
        const keyAndIndex = props.contentKeyAndIdxMap.get(key)!;
        const generateSpeechProps = {
          highlightSelecteVoice: false,
          refetchTextToSpeeches: props.refetchTextToSpeeches,
          textToSpeechIdxHistory: props.textToSpeechIdxHistory,
          setTextToSpeechIdxHistory: props.setTextToSpeechIdxHistory,
          refetch: props.refetch,
          content: key,
          idx: keyAndIndex.idx,
          userToolSubmissionResponseId: props.userToolSubmissionResponseId,
          contentKey: keyAndIndex.contentKey,
          submissionResponse: props.submissionResponse,
          textToSpeeches: props.textToSpeeches,
          contentKeyVoiceId: props.contentKeyVoiceId,
          setContentKeyVoiceId: props.setContentKeyVoiceId,
        };
        console.log(`key: ${key} value: ${value}`);

        return generateTextToSpeech(generateSpeechProps);
      }
    );
    // wait fora ll promises to resolve
    await Promise.all(speechPromise);
    // update conversation

    await updateResponseAudio();

    // console.log(`noAudioForText ${JSON.stringify(noAudioForText, null, 2)}`);
  };
  if (isUpdatingAudio) return <Spinner></Spinner>;

  return (
    <div className="">
      <div
        onClick={() => setShowActions(!showActions)}
        className={`flex bg-slate-200 cursor-pointer mb-5 ${
          props.highLightAction && !showActions ? "bg-pulse-gradient" : ""
        }`}
      >
        {showActions ? (
          <ChevronDoubleDownIcon className="w-7 h-7 mx-10 my-2"></ChevronDoubleDownIcon>
        ) : (
          <ChevronDoubleUpIcon className="w-7 h-7 mx-10 my-2"></ChevronDoubleUpIcon>
        )}
        <SpeakerWaveIcon className="w-7 h-7 mx-10 my-2"></SpeakerWaveIcon>

        <div className="py-3"> Audio Creation</div>
      </div>

      {showActions && (
        <div>
          <div className="flex border-b-2 mb-2">
            <div className="p-4">Audio:</div>
            <div className={` p-4 mx-10 justify-center flex gap-5 `}>
              <button
                className={`${SuccessButtonStyle}  ${
                  props.highLightAction && !props.audioURL
                    ? "bg-pulse-gradient"
                    : ""
                }`}
                onClick={async () => {
                  recreateAudio();
                }}
              >
                Generate Audio
              </button>
              <div>{props.charactersTextMissingAudio} Characters</div>
            </div>
          </div>
          <div>
            {props.audioURL && <audio src={props.audioURL} controls></audio>}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkshopAudioActions;
