import { useState } from "react";
import { MicrophoneIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import TrainVoiceWithFiles from "./TrainVoiceWithFiles";
import { v4 as uuidv4 } from "uuid";

enum CreateVoicePrintErrors {
  NO_VOICE_NAME = "Please enter a voice name",
  NO_VOICE_SAMPLES = "Please upload at least one voice sample",
}
const CreateVoicePrint = () => {
  const [voiceName, setVoiceName] = useState<string>("");
  const [error, setError] = useState<CreateVoicePrintErrors | null>(null);
  const navigate = useNavigate();
  const voiceId = uuidv4();
  const [isTrainingVoice, setIsTrainingVoice] = useState<boolean>(false);

  return (
    <div>
      <div className="grid ">
        <div className="mt-2 flex justify-center text-3xl">Sound Lab</div>
        <div className="my-10 flex justify-center text-2xl">
          Create a new voice
        </div>
        <div className="flex justify-center gap-3 md:mb-20">
          <div className="flex justify-center gap-3">
            <div className="-ml-24 mr-4">Voice Name</div>

            <div className=" ">
              <input
                onChange={(e) => {
                  setVoiceName(e.target.value);
                  setError(null);
                }}
                className={`border-b-2 outline-none  ${
                  error === CreateVoicePrintErrors.NO_VOICE_NAME
                    ? "border-red-500"
                    : "border-gray-500"
                }`}
                type={"text"}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div>
          <TrainVoiceWithFiles
            voiceId={voiceId}
            voiceName={voiceName}
            onTrainingVoice={() => {
              setIsTrainingVoice(true);
            }}
            voicedTrainedCallBack={(voiceId: string) => {
              navigate(`/voices/view/${voiceId}`);
            }}
            onError={(error: string) => {
              setError(error as CreateVoicePrintErrors);
            }}
          ></TrainVoiceWithFiles>
        </div>

        <div
          className="grid justify-center cursor-pointer"
          onClick={() => {
            if (isTrainingVoice) {
              return;
            }
            if (!voiceName) {
              setError(CreateVoicePrintErrors.NO_VOICE_NAME);
              return;
            }
            navigate("/voices/add/record", { state: { voiceName, voiceId } });
          }}
        >
          <div>
            <MicrophoneIcon className="h-10 w-10"></MicrophoneIcon>
          </div>
        </div>
      </div>
      <div className="flex justify-center text-red-600 text-2xl">
        {error && <div>{error}</div>}
      </div>
    </div>
  );
};

export default CreateVoicePrint;
