import { useParams } from "react-router-dom";
import { Loading } from "../../../../../common/Loading";
import {
  Maybe,
  Query,
  TextToSpeechSubmission,
} from "../../../../../generated/graphql";
import { getQuery } from "../../../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../../../graphql/whitelistedQueries";
import {
  useAuthMutation,
  useAuthQuery,
} from "../../../../../hooks/useAuthApollo";
import ToolSubmissionResponse from "../ToolSubmissionResponse";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ResponseWorkshopRowActions from "./ResponseWorkshopRowActions";
import useMakeBackendRequest from "../../../../../hooks/useMakeBackendRequest";
import {
  CONTINUE_RESPONSE_ENDPOINT,
  ONBOARDING_CREATE_AUDIO__SUCCESS_TRIGGER,
  UPDATE_CONVERSATION_ENDPOINT,
} from "../../../../../constants/Constants";
import UploadImage from "./UploadImage";
import {
  GrayCancelButtonStyle,
  SuccessButtonStyle,
} from "../../../../../constants/CSSConstants";
import {
  findTextSubmissionByText,
  replaceStringInObject,
} from "./workshopUtils";
import WorkshopVideoCreator from "./WorkshopVideoCreator";
import useWorkshopTextToSpeech from "./useWorkshopTextToSpeech";
import { AppContext } from "../../../../../UserApp";
import { AppData } from "../../../../../hooks/useLoadAppData";
import { getUserAvailableVoices } from "../../../../../utils/responseAudioUtils";
import { AxiosResponse } from "axios";
import Spinner from "../../../../../common/Spinner";
import {
  ChevronRightIcon,
  PaperAirplaneIcon,
  SpeakerWaveIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import WorkshopAudioActions from "./WorkshopAudioActions";
import {
  WORKSHOP_ONBOARDING_STATUS,
  deleteOnboardingStatus,
  getOnboardingStatus,
  setOnboardingStatus,
} from "../../../../onboarding/getOnboardingStatus";

export type KeyAndIdx = {
  contentKey: string;
  idx: number;
};

export type UpdateConversationResponse = {
  audioURL: string;
};
const ResponseWorksop = () => {
  const { id } = useParams<{ id: string }>();

  const audioRef = useRef<HTMLDivElement>(null);

  const scrollToAudioActions = () => {
    audioRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onboardingStatus = getOnboardingStatus();

  const highlightSelecteVoice =
    onboardingStatus?.hightLightTool === WORKSHOP_ONBOARDING_STATUS;

  const { post } = useMakeBackendRequest();

  // hashset of content keys that have been updated
  const [contentKeys, setContentKeys] = useState<Set<string>>(new Set());

  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [contentKeyVoiceId, setContentKeyVoiceId] = useState<
    Map<string, string>
  >(new Map());

  const [workshopTask, setWorkshopTask] = useState<string | null>(null);
  const [isReviewingContent, setIsReviewingContent] = useState(false);

  const hasAllVoicesAssigned = contentKeys.size === contentKeyVoiceId.size;

  const updateOnboardingQuery = getQuery(WhitelistQueries.UpdateUserOnboarding);
  const [updateOnboardingMutation] = useAuthMutation({
    mutation: updateOnboardingQuery,
  });

  const appData = useContext<AppData>(AppContext);

  useEffect(() => {
    if (
      audioURL &&
      onboardingStatus?.successTrigger ===
        ONBOARDING_CREATE_AUDIO__SUCCESS_TRIGGER
    ) {
      deleteOnboardingStatus();

      updateOnboardingMutation({
        variables: {
          id: appData.user?.id,
          onboarded: true,
        },
      });
    }
  }, [
    appData.user?.id,
    audioURL,
    onboardingStatus?.successTrigger,
    updateOnboardingMutation,
  ]);

  useEffect(() => {
    if (hasAllVoicesAssigned && highlightSelecteVoice) {
      scrollToAudioActions();
    }
  }, [hasAllVoicesAssigned, highlightSelecteVoice]);

  const getResponseByIDQuery = getQuery(
    WhitelistQueries.UserToolSubmissionResponseById
  );
  const textToSpeechesByResponseQuery = getQuery(
    WhitelistQueries.FindTextToSpeechSubmissionsByUserToolSubmissionResponseId
  );

  const {
    data: userSubmissionResponseResult,
    refetch: refetchSubmissionResponse,
  } = useAuthQuery<Query>(
    {
      query: getResponseByIDQuery,
    },
    {
      variables: {
        id,
      },
    }
  );

  const UpdateContentUserToolSubmissionResponseById = getQuery(
    WhitelistQueries.UpdateContentUserToolSubmissionResponseById
  );
  const [updateContentUserToolSubmissionResponseById] = useAuthMutation({
    mutation: UpdateContentUserToolSubmissionResponseById,
  });

  const [contentKeyAndIdxMap, setContentKeyAndIdxMap] = useState<
    Map<string, KeyAndIdx>
  >(new Map());

  //
  const { data: textToSpeeches, refetch: refetchTextToSpeeches } =
    useAuthQuery<Query>(
      {
        query: textToSpeechesByResponseQuery,
      },
      {
        fetchPolicy: "no-cache",
        variables: {
          userToolSubmissionResponseId: id,
        },
      }
    );

  const [textToSpeechIdxHistory, setTextToSpeechIdxHistory] = useState<
    Map<number, TextToSpeechSubmission>
  >(new Map());
  const [isPlaying, setIsPlaying] = useState<Map<string, boolean>>(new Map());

  const [noAudioForText, setNoAudioForText] = useState<Map<string, boolean>>(
    new Map()
  );
  //map ref of audios to play on click of play button
  const audioRefs = new Map<string, HTMLAudioElement>();

  const [updatedContent, setUpdatedContent] = useState<any>();
  const [isRetrievingNewContent, setIsRetrievingNewContent] = useState(false);

  const userToolSubmissionResponse =
    userSubmissionResponseResult?.userToolSubmissionResponseById;

  useEffect(() => {
    if (!updatedContent && userToolSubmissionResponse?.response) {
      setUpdatedContent(JSON.parse(userToolSubmissionResponse?.response));
    }
  }, [userToolSubmissionResponse?.response, updatedContent]);

  useEffect(() => {
    if (userToolSubmissionResponse?.audioUrl && !audioURL) {
      setAudioURL(userToolSubmissionResponse?.audioUrl);
    }
  }, [audioURL, userToolSubmissionResponse?.audioUrl]);

  if (!userSubmissionResponseResult || !textToSpeeches || !updatedContent) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }
  if (!userToolSubmissionResponse?.response) {
    //error to sentry
  }
  const submissionResponse = JSON.parse(userToolSubmissionResponse?.response);
  // const items = submissionResponse["conversation"] ? submissionResponse["conversation"] : submissionResponse;

  async function updateContentByIndex<T>(
    key: string,
    index: number,
    newContent: any,
    initialContent: string
  ) {
    let adjustedIndex = index;
    let isConversation = false;
    // const idxSplit = idx.split("-");
    //get item by index composite for example 0-0-0 gets the first item in the first item in the first item
    let item = updatedContent;
    // for (let i = 0; i < idxSplit.length; i++) {
    // const index = parseInt(idxSplit[i]);
    if (item["conversation"]) {
      item = item["conversation"];
      isConversation = true;
    } else {
      //we need to skip id which is first item

      adjustedIndex = index + 1;
    }
    if (Array.isArray(item)) {
      item[adjustedIndex] = { [key]: newContent };
    } else {
      console.log(`itemitem 
      
      initialContent ${initialContent}
      adjustedIndex ${adjustedIndex} ${JSON.stringify(item, null, 2)}}}`);
      const firstKey = Object.keys(item)[adjustedIndex];
      //check if Object has any atttribute that are also objects check all keys
      // for each key
      // get all keys for object

      const hasNestedObject = Object.keys(item).some((key) => {
        return typeof item[key] === "object";
      });
      if (hasNestedObject) {
        replaceStringInObject(item, initialContent, newContent);

        // console.log(`hasNestedObjecthasNestedObject ${JSON.stringify(item, null, 2)}}`)
        // //search for keys that have content like initialContent and replace it with newContent
        // //keep in mind that the key could be nested
        // const keys = Object.keys(item);
        // for (let i = 0; i < keys.length; i++) {
        //   const key = keys[i];
        //   console.log(`hasNestedObjecthasNestedObject key ${key}`)
        //   if (typeof item[key] === "object") {
        //     const nestedKeys = Object.keys(item[key]);
        //     for (let j = 0; j < nestedKeys.length; j++) {
        //       const nestedKey = nestedKeys[j];
        //       if (item[key][nestedKey] === initialContent) {
        //         item[key][nestedKey] = newContent;
        //       }
        //     }
        //   } else {
        //     if (item[key] === initialContent) {
        //       item[key] = newContent;
        //     }
        //   }
        // }
      } else {
        item[firstKey] = newContent;
      }
    }
    console.log(
      `setUpdatedContentsetUpdatedContent ${JSON.stringify(item, null, 2)}}`
    );
    if (isConversation) {
      updatedContent["conversation"] = item;
    }
    setUpdatedContent(updatedContent);
    console.log(`updatedContent ${JSON.stringify(updatedContent, null, 2)}}`);

    await updateContentUserToolSubmissionResponseById({
      variables: {
        id,
        response: JSON.stringify(updatedContent),
      },
    });

    refetchSubmissionResponse();
  }

  const continueResponse = async () => {
    setIsReviewingContent(false);
    setIsRetrievingNewContent(true);

    const updatedResponse = await post(CONTINUE_RESPONSE_ENDPOINT, {
      userToolSubmissionResponseId: id,
      task: workshopTask,
    });

    setUpdatedContent(updatedResponse.data.updatedResponse);
    setIsReviewingContent(true);
  };
  const renderConversationActions = () => {
    return (
      <div>
        <div className="flex flex-row justify-center  w-full my-10">
          <input
            placeholder="Enter task - result will be added to the content, you can try typing 'continue' "
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                continueResponse();
              }
            }}
            onChange={(e) => {
              setWorkshopTask(e.target.value);
            }}
            value={workshopTask || ""}
            className="w-full rounded-md
            h-10 px-5 pr-10 text-base placeholder-gray-600
            border-2
            border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            type="text"
          />
          <div className={` mx-10 pt-2 justify-center`}>
            {isRetrievingNewContent ? (
              <Spinner></Spinner>
            ) : (
              <button
                disabled={!workshopTask}
                onClick={async () => {
                  if (workshopTask) {
                    await continueResponse();
                  }
                  setIsRetrievingNewContent(false);
                  setIsReviewingContent(true);
                }}
              >
                <PaperAirplaneIcon className="w-6 h-6"></PaperAirplaneIcon>
              </button>
            )}
          </div>
        </div>

        <div className="grid gap-2">
          {/*    
          
  const scrollToDiv = () => {
    if (divRef && divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

if (props.contentKeys.size > props.contentKeyVoiceId.size) {  */}
          <div ref={audioRef}>
            <WorkshopAudioActions
              highLightAction={hasAllVoicesAssigned && highlightSelecteVoice}
              audioURL={audioURL}
              refetchTextToSpeeches={refetchTextToSpeeches}
              contentKeyAndIdxMap={contentKeyAndIdxMap}
              textToSpeeches={textToSpeeches}
              noAudioForText={noAudioForText}
              contentKeys={contentKeys}
              contentKeyVoiceId={contentKeyVoiceId}
              userToolSubmissionResponseId={id!}
              refetch={refetchSubmissionResponse}
              setContentKeyVoiceId={setContentKeyVoiceId}
              textToSpeechIdxHistory={textToSpeechIdxHistory}
              setTextToSpeechIdxHistory={setTextToSpeechIdxHistory}
              setAudioURL={setAudioURL}
              submissionResponse={submissionResponse}
              updateContentUserToolSubmissionResponseById={
                updateContentUserToolSubmissionResponseById
              }
              charactersTextMissingAudio={
                //get sum of all strings characters in noAudioForText keus
                Array.from(noAudioForText.keys()).reduce(
                  (acc, curr) => acc + curr.length,
                  0
                )
              }
            />
          </div>

          <WorkshopVideoCreator
            highLightAction={
              (audioURL?.length || 0) > 0 && highlightSelecteVoice
            }
            userId={appData.user.id}
            textToSpeeches={textToSpeeches}
            submissionResponse={submissionResponse}
            conversationURL={userToolSubmissionResponse?.audioUrl}
            userToolSubmissionResponseId={id!}
            refectTextToSpeeches={refetchTextToSpeeches}
            userToolSubmissionResponseVideoUrl={
              userToolSubmissionResponse?.videoUrl
            }
          />
        </div>
      </div>
    );
  };
  // console.log(`items: ${JSON.stringify(items, null, 2)}`);

  return (
    <div className="w-full">
      <div className="flex justify-center">Workshop</div>

      <div className="flex justify-center text-4xl my-5">
        {highlightSelecteVoice ? "Select voices for the conversation" : ""}
      </div>
      <div className="  ">
        <ToolSubmissionResponse
          onEditBlur={(
            idx: number,
            key: string,
            content: string,
            initialContent: string
          ) => {
            updateContentByIndex(key, idx, content, initialContent);
          }}
          renderRowActions={(key: string, content: string, idx: number) => {
            // setContentKeys
            if (!contentKeys.has(key)) {
              setContentKeys((prev: Set<string>) => {
                const newSet = new Set(prev);
                newSet.add(key);
                return newSet;
              });
            }
            const textToSpeechSubmission = findTextSubmissionByText(
              textToSpeeches,
              content
            );

            const audioToPlay = textToSpeechSubmission?.audioUrl;

            if (!textToSpeechIdxHistory.get(idx) && textToSpeechSubmission) {
              setTextToSpeechIdxHistory((prev) => {
                const newMap = new Map(prev);
                newMap.set(idx, textToSpeechSubmission);
                return newMap;
              });
            }

            if (!contentKeyAndIdxMap.get(content)) {
              setContentKeyAndIdxMap((prev) => {
                const newMap = new Map(prev);
                newMap.set(content, {
                  contentKey: key,
                  idx,
                });
                return newMap;
              });
            }

            // console.log(`audioToPlay: ${audioToPlay}`);
            if (!audioToPlay && !noAudioForText.get(content)) {
              setNoAudioForText((prev) => {
                const newMap = new Map(prev);
                newMap.set(content, true);
                return newMap;
              });
            }

            console.log(
              `ResponseWorkshopRowActionskey ke555y ${key} content ${content} idx ${idx}`
            );
            const elemenet = (
              <ResponseWorkshopRowActions
                highlightSelecteVoice={highlightSelecteVoice}
                textToSpeeches={textToSpeeches}
                refetchTextToSpeeches={refetchTextToSpeeches}
                refetchSubmissionResponse={refetchSubmissionResponse}
                content={content}
                idx={idx}
                userToolSubmissionResponseId={id!}
                contentKey={key}
                submissionResponse={submissionResponse}
                noAudioForText={noAudioForText}
                setNoAudioForText={setNoAudioForText}
                audioRefs={audioRefs}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                textToSpeechIdxHistory={textToSpeechIdxHistory}
                setTextToSpeechIdxHistory={setTextToSpeechIdxHistory}
                contentKeyVoiceId={contentKeyVoiceId}
                setContentKeyVoiceId={setContentKeyVoiceId}
                //               contentKeyVoiceId: Map<string, string>;
                // setContentKeyVoiceId: (prev: any) => any;
              ></ResponseWorkshopRowActions>
            );

            return elemenet;
          }}
          editRowOnClick={true}
          toolResponse={updatedContent}
          rowCss=" cursor-pointer hover:bg-gray-100"
        />
      </div>
      {/* send div to bottom of page */}

      {isReviewingContent ? (
        <div className="flex justify-around mt-8">
          <button
            onClick={() => {
              setUpdatedContent(
                JSON.parse(userToolSubmissionResponse?.response)
              );
              continueResponse();
            }}
          >
            <ArrowPathIcon className=" cursor-pointer w-6 h-6"></ArrowPathIcon>
          </button>
          <button
            onClick={async () => {
              await updateContentUserToolSubmissionResponseById({
                variables: {
                  id,
                  response: JSON.stringify(updatedContent),
                },
              });
              setTimeout(() => {
                refetchSubmissionResponse();
              }, 2000);

              setIsReviewingContent(false);
            }}
          >
            Looks Good
          </button>
          <button
            onClick={() => {
              setUpdatedContent(
                JSON.parse(userToolSubmissionResponse?.response)
              );
              setIsReviewingContent(false);
              setIsRetrievingNewContent(false);
            }}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div>{renderConversationActions()}</div>
      )}
    </div>
  );
};

export default ResponseWorksop;
