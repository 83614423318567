import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from "./firebaseConfig";

const Firebase = firebase.initializeApp(firebaseConfig.firebase);

// Add or Remove authentification methods here.
export const Providers = {
  google: new firebase.auth.GoogleAuthProvider(),
  facebook: new firebase.auth.FacebookAuthProvider(),
};

export const auth = firebase.auth();

export default Firebase;
