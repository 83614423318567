import { useState } from "react";
import { getQuery } from "../../graphql/fetchQuery";
import { WhitelistQueries } from "../../graphql/whitelistedQueries";
import { useAuthMutation } from "../../hooks/useAuthApollo";
import {
  CONVERSATION_TOOL_NAME,
  SEARCH_PUBLIC_VOICE,
  OnboardingStatusLocalStorageKey,
  ONBOARDING_CREATE_AUDIO__SUCCESS_TRIGGER,
  ONBOARDING_CREATE_VIDEO_SUCCESS_TRIGGER,
  AttentionInterestDesireAction_TOOL_NAME,
  ONBOARDING_CREATE_TEXT_SUCCESS_TRIGGER,
} from "../../constants/Constants";
import LogoSrc from "../../assets/logo.png";
import { SuccessButtonStyle } from "../../constants/CSSConstants";
import { deleteOnboardingStatus } from "./getOnboardingStatus";

enum OnboardingType {
  NOT_CHOSEN = "NOT_CHOSEN",
  VIDEO_AUDIO = "VIDEO_AUDIO",
  VIDEO_AUDIO_CUSTOM_VOICES = "VIDEO_AUDIO_CUSTOM_VOICES",
  AUDIO = "AUDIO",
  AUDIO_CUSTOM_VOICES = "AUDIO_CUSTOM_VOICES",
  TEXT = "TEXT",
}
export type OnboardingStatus = {
  onboarded: boolean;
  onboardedType: OnboardingType;
  hightLightTool: string;
  successTrigger: string;
  step: number;
};
type OnboardingProps = {
  userId: string;
  setOnboardingSet: (onboardingSet: boolean) => void;
};

const Onboarding = (props: OnboardingProps) => {
  const onboardingSelectionCss = `flex justify-center cursor-pointer bg-sky-100 rounded-xl p-5 
  hover:bg-sky-200`;
  const defaultOnboardingStatus = {
    onboarded: false,
    onboardedType: OnboardingType.NOT_CHOSEN,
    hightLightTool: CONVERSATION_TOOL_NAME,
    successTrigger: ONBOARDING_CREATE_AUDIO__SUCCESS_TRIGGER,
    step: 0,
  };
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>(
    defaultOnboardingStatus
  );
  const updateOnboardingQuery = getQuery(WhitelistQueries.UpdateUserOnboarding);
  const [showFinalStep, setShowFinalStep] = useState<boolean>(false);

  const [updateOnboardingMutation] = useAuthMutation({
    mutation: updateOnboardingQuery,
  });

  const persistOnboardingToLocalStorage = (
    newonboardingStatus: OnboardingStatus
  ) => {
    localStorage.setItem(
      OnboardingStatusLocalStorageKey,
      JSON.stringify(newonboardingStatus)
    );
    setShowFinalStep(true);
  };

  const renderVideoOnboardingOptions = () => {
    return (
      <div className="grid gap-8">
        <div
          className={onboardingSelectionCss}
          onClick={() => {
            const newonboardingStatus = {
              ...onboardingStatus,
              onboardedType: OnboardingType.VIDEO_AUDIO_CUSTOM_VOICES,
              hightLightTool: SEARCH_PUBLIC_VOICE,
              successTrigger: ONBOARDING_CREATE_VIDEO_SUCCESS_TRIGGER,
            };
            setOnboardingStatus(newonboardingStatus);

            persistOnboardingToLocalStorage(newonboardingStatus);
          }}
        >
          Show me how to create videos with custom voices
        </div>
        <div
          className={onboardingSelectionCss}
          onClick={() => {
            const newonboardingStatus = {
              ...onboardingStatus,
              onboardedType: OnboardingType.VIDEO_AUDIO,
              hightLightTool: CONVERSATION_TOOL_NAME,
              successTrigger: ONBOARDING_CREATE_VIDEO_SUCCESS_TRIGGER,
            };
            setOnboardingStatus(newonboardingStatus);
            persistOnboardingToLocalStorage(newonboardingStatus);
          }}
        >
          Show me how to create with default voices
        </div>
      </div>
    );
  };

  const renderAudioOnboardingOptions = () => {
    return (
      <div className="grid gap-8">
        <div
          className={onboardingSelectionCss}
          onClick={() => {
            const newonboardingStatus = {
              ...onboardingStatus,
              onboardedType: OnboardingType.AUDIO_CUSTOM_VOICES,
              hightLightTool: SEARCH_PUBLIC_VOICE,
              successTrigger: ONBOARDING_CREATE_AUDIO__SUCCESS_TRIGGER,
            };
            setOnboardingStatus(newonboardingStatus);
            persistOnboardingToLocalStorage(newonboardingStatus);
          }}
        >
          Show me how to create audio with custom voices
        </div>
        <div
          className={onboardingSelectionCss}
          onClick={() => {
            const newonboardingStatus = {
              ...onboardingStatus,
              onboardedType: OnboardingType.AUDIO_CUSTOM_VOICES,
              hightLightTool: SEARCH_PUBLIC_VOICE,
              successTrigger: ONBOARDING_CREATE_AUDIO__SUCCESS_TRIGGER,
            };
            setOnboardingStatus(newonboardingStatus);
            persistOnboardingToLocalStorage(newonboardingStatus);
          }}
        >
          Show me how to create audio with default voices
        </div>
      </div>
    );
  };

  const chooseOnboarding = () => {
    return (
      <div className="grid gap-8">
        <div>I want to create:</div>
        <div
          className={onboardingSelectionCss}
          onClick={() => {
            setOnboardingStatus({
              ...onboardingStatus,
              onboardedType: OnboardingType.VIDEO_AUDIO,
            });
          }}
          // on hover  set bg-slate-200
        >
          Still videos
        </div>

        <div
          className={onboardingSelectionCss}
          onClick={() => {
            setOnboardingStatus({
              ...onboardingStatus,
              onboardedType: OnboardingType.AUDIO,
            });
          }}
        >
          Audio
        </div>
        <div
          onClick={() => {
            const newonboardingStatus = {
              ...onboardingStatus,
              onboardedType: OnboardingType.TEXT,
              hightLightTool: AttentionInterestDesireAction_TOOL_NAME,
              successTrigger: ONBOARDING_CREATE_TEXT_SUCCESS_TRIGGER,
            };
            setOnboardingStatus(newonboardingStatus);
            persistOnboardingToLocalStorage(newonboardingStatus);
          }}
          className={onboardingSelectionCss}
        >
          Text
        </div>
        <div
          onClick={() => {
            updateOnboardingMutation({
              variables: {
                id: props.userId,
                onboarded: true,
              },
            });
            deleteOnboardingStatus();
            props.setOnboardingSet(true);
          }}
          className={onboardingSelectionCss}
        >
          Just take me to the dashboard
        </div>
      </div>
    );
  };

  const renderOnboarding = () => {
    if (onboardingStatus.onboardedType === OnboardingType.NOT_CHOSEN) {
      return chooseOnboarding();
    }
    if (onboardingStatus.onboardedType === OnboardingType.VIDEO_AUDIO) {
      return renderVideoOnboardingOptions();
    }
    if (onboardingStatus.onboardedType === OnboardingType.AUDIO) {
      return renderAudioOnboardingOptions();
    }
  };
  const logo = (
    <div className="flex gap-5 justify-center">
      <img className="w-14" alt="logo" src={LogoSrc} />
      <div className="pt-3 text-2xl">Clove</div>
    </div>
  );
  const finalOnboardingStep = () => {
    return (
      <div className="grid gap-8 p-20">
        {logo}
        <div className="text-4xl md:p-10 flex justify-center py-2">
          <div className="py-2">Follow the</div>
          <div className="bg-pulse-gradient ml-3 p-2 rounded-lg">
            glowing elements
          </div>
        </div>
        <div
          className={`  p-4 bg-pulse-gradient cursor-pointer flex justify-center text-2xl rounded-xl `}
          onClick={() => {
            props.setOnboardingSet(true);
          }}
        >
          Let's Start
        </div>
      </div>
    );
  };
  if (showFinalStep) {
    return finalOnboardingStep();
  }
  return (
    <div className="flex flex-col  h-screen">
      {/* vertical center tailwind */}
      {logo}
      <div
        className="
         first-letter mt-20
md:my-auto
        grid gap-10
        text-4xl
        rounded-lg  p-3 items-center justify-center w-full"
      >
        <div>{renderOnboarding()}</div>
      </div>
    </div>
  );
};

export default Onboarding;
