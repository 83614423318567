import { PlayCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { Maybe, Voice } from "../../../generated/graphql";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../../../UserApp";
import { useAuthMutation } from "../../../hooks/useAuthApollo";
import { getQuery } from "../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../graphql/whitelistedQueries";
import Spinner from "../../../common/Spinner";
import { SuccessButtonStyle } from "../../../constants/CSSConstants";
import { getOnboardingStatus } from "../../onboarding/getOnboardingStatus";
import { ADD_PUBLIC_VOICE } from "../../../constants/Constants";

// const [filteredPublicVoices, setFilteredPublicVoices] = useState<
// Maybe<Voice>[]
// >([]);

type SearchPublicVoiceRowProps = {
  voice: Voice;
  isOnboardingAddPublicVoice: boolean;
  setAddedVoicesCount: React.Dispatch<React.SetStateAction<number>>;
  filteredPublicVoices: Maybe<Voice>[];
  setFilteredPublicVoices: React.Dispatch<React.SetStateAction<Maybe<Voice>[]>>;
};

const SearchPublicVoiceRow = (props: SearchPublicVoiceRowProps) => {
  const appData = useContext(AppContext);

  const voice = props.voice;

  //react ref for   <audio className="hidden " src={voice?.demoUrl!} controls></audio>
  const audioRef = useRef<HTMLAudioElement>(null);

  //   props.audioRefs.get(props.content)?.play();
  const [createPublicUserVoiceMutation] = useAuthMutation({
    mutation: getQuery(WhitelistQueries.CreateUserAddedVoice),
  });
  const [isAddingVoice, setIsAddingVoice] = useState<Map<string, boolean>>(
    new Map()
  );

  return (
    <div className="border-b-2  pb-2">
      <div className="flex gap-10 align-middle	 ">
        <div className=" w-36 pt-3">
          {voice?.name}

          <div className="text-xs">
            Created By {voice?.userByUserId?.name.split(" ")[0]}
          </div>
        </div>
        <div>
          <PlayCircleIcon
            onClick={() => {
              audioRef.current?.play();
            }}
            className="h-7 w-7 text-gray-400 cursor-pointer"
          />

          <audio
            ref={audioRef}
            className="hidden "
            src={voice?.demoUrl!}
            controls
          ></audio>
        </div>
        <div
          className=" cursor-pointer w-28"
          onClick={async () => {
            if (isAddingVoice.get(voice?.id!)) return;
            setIsAddingVoice(new Map(isAddingVoice).set(voice?.id!, true));
            await createPublicUserVoiceMutation({
              variables: {
                //gets added by backend
                userId: "placeholder",
                voiceId: voice?.id,
              },
            });
            props.setAddedVoicesCount((prev: number) => prev + 1);
            appData.refreshData();
            props.setFilteredPublicVoices(
              props.filteredPublicVoices.filter((v) => v?.id !== voice?.id)
            );
          }}
        >
          {isAddingVoice.get(voice?.id!) ? (
            <div className="h-7 w-7 text-gray-400">
              <Spinner></Spinner>
            </div>
          ) : (
            <div
              className={`flex justify-center ${SuccessButtonStyle}
                ${props.isOnboardingAddPublicVoice ? "bg-pulse-gradient" : ""}
                `}
            >
              Add
            </div>
            // <PlusCircleIcon

            // className="h-7 w-7 text-gray-400 cursor-pointer"></PlusCircleIcon>
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default SearchPublicVoiceRow;
