import { useContext, useState } from "react";
import {
  CREATE_VIDEO_SUMMARY_FROM_CONTEXT_ENDPOINT,
  SUBITLE_BASE64_ENDPOINT,
} from "../../constants/Constants";
import useMakeBackendRequest from "../../hooks/useMakeBackendRequest";
import { SuccessButtonStyle } from "../../constants/CSSConstants";
import Spinner from "../../common/Spinner";
import { AppContext } from "../../UserApp";

const CreateVideoFromContext = () => {
  const app = useContext(AppContext);
  const { post } = useMakeBackendRequest();
  const [isCreating, setIsCreating] = useState(false);
  const [contextURL, setContextURL] = useState<string | null>(null);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const [base64Subtitle, setBase64Subtitle] = useState<string | null>(null);
  const { get } = useMakeBackendRequest();

  if (isCreating) {
    return (
      <div>
        <div>Creating video...</div>
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div>
          <h1>Enter URL</h1>
          <div>
            Only pdfs up to 10 pages, due to cost constraints. We are working on
            increasing this
          </div>
        </div>
        <input
          className="border-2 w-full border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          type="text"
          placeholder="Enter URL"
          onChange={(e) => {
            setContextURL(e.target.value);
          }}
        />
      </div>
      <div
        className=" hover:bg-gray-100 cursor-pointer p-2 rounded-md"
        onClick={async () => {
          if (!contextURL) {
            alert("Please enter a URL");
            return;
          }
          setIsCreating(true);

          const createVideoResult = await post(
            CREATE_VIDEO_SUMMARY_FROM_CONTEXT_ENDPOINT,
            {
              url: contextURL,
            }
          );

          const videoURL = createVideoResult.data.videoCreatedResponse.videoURL;
          const videoName =
            createVideoResult.data.videoCreatedResponse.videoName;
          setVideoURL(videoURL);
          setIsCreating(false);
          const url = SUBITLE_BASE64_ENDPOINT(app.user.id, videoName);
          const result = await get(url);
          const base64Subtitle = result.data.base64Subtitle;
          setBase64Subtitle(base64Subtitle);
        }}
      >
        <div className={SuccessButtonStyle}>Create Video From URL</div>
      </div>
      {videoURL && (
        <div>
          <video id="video" playsInline controls>
            <source src={videoURL} type="video/mp4" />
            {base64Subtitle && (
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={`data:image/png;base64, ${base64Subtitle}`}
                default
              />
            )}
          </video>
        </div>
      )}
    </div>
  );
};

export default CreateVideoFromContext;
