import {
  ClipboardDocumentIcon,
  HeartIcon,
  MagnifyingGlassIcon,
  MicrophoneIcon,
  ArrowTopRightOnSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../UserApp";
import { ToolTypes } from "../../../constants/Constants";
import ToolResponseAudio from "./ToolResponseAudio";
import { cleanResponseKey, cleanToolResponse } from "./toolResponseCleanup";
import useMakeBackendRequest from "../../../hooks/useMakeBackendRequest";
import ToolResponseActions from "./ToolResponseActions";
import { isLocalhost } from "../../../utils/navigationUtils";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../../constants/routes";
import ToolSubmissionResponse from "./responses/ToolSubmissionResponse";
import {
  WORKSHOP_ONBOARDING_STATUS,
  getOnboardingStatus,
  setOnboardingStatus,
} from "../../onboarding/getOnboardingStatus";
// import { renderResponseKeyValue } from "./responses/reponseUtils";

type ToolResponsesProps = {
  createdContent: any[] | undefined;
  productName: string | undefined;
  setMoreLikeResponse: React.Dispatch<React.SetStateAction<JSON | undefined>>;
  hide: boolean;
  createContent: (moreLike: JSON | undefined) => Promise<void>;
  setLikedResponses: React.Dispatch<React.SetStateAction<string[]>>;
  likedResponses: string[];
  toolType: string;
  highLightCustomize: boolean;
};

const ToolResponses = (props: ToolResponsesProps) => {
  const app = useContext(AppContext);

  const [responseTextToCopy, setResponseTextToCopy] = useState<string[]>([]);
  const [deletedResponseIds, setDeletedResponseIds] = useState<string[]>([]);

  const [usageData, setUsageData] = useState<number>();

  const navigate = useNavigate();

  const { get } = useMakeBackendRequest();
  if (
    props.highLightCustomize &&
    props.createdContent &&
    props.createContent.length > 0
  ) {
    // scroll down 100px
    window.scrollTo(0, 150);
  }

  const getSpeechUsageData = useCallback(() => {
    const usage = get(`${process.env.REACT_APP_ML_ENGINE}/audio/usage`);
    usage.then((data) => {
      const textToSpeechCharactersAllowed: number | undefined =
        app.user.userSubscriptionsByUserId?.nodes?.[0]
          ?.subscriptionBySubscriptionId?.textToSpeechCharactersAllowed;

      if (textToSpeechCharactersAllowed) {
        setUsageData(textToSpeechCharactersAllowed - data.data.usage);
      } else {
        setUsageData(0);
      }
    });
  }, [app.user.userSubscriptionsByUserId?.nodes, get]);

  useEffect(() => {
    if (!usageData) {
      getSpeechUsageData();
    }
  }, [app.user.userSubscriptionsByUserId?.nodes, get]);

  // 0 is falsey, so we need to check if it's undefined
  if (props.hide || usageData === undefined) return null;

  const renderAudioOptions = (
    toolType: string,
    text: string,
    responseId: string,
    charactersUsed: number
  ) => {
    if (toolType !== ToolTypes.AUDIO_GENERATIVE) return <></>;
    return (
      <ToolResponseAudio
        textToSpeechCallBack={getSpeechUsageData}
        charactersRemaining={charactersUsed}
        userToolSubmissionResponseId={responseId}
        text={text}
      ></ToolResponseAudio>
    );
  };

  const renderCustomizeDiv = (responseId: string) => {
    const workshopLink = routes.responseWorkshop.path.replace(
      ":id",
      responseId
    );
    return (
      <div
        onClick={() => {
          const onboardingStatus = getOnboardingStatus();
          if (onboardingStatus) {
            onboardingStatus.hightLightTool = WORKSHOP_ONBOARDING_STATUS;
            setOnboardingStatus(onboardingStatus);
          }
          navigate(workshopLink);
        }}
      >
        <div
          className={` rounded-xl   p-1 ${
            props.highLightCustomize
              ? "bg-pulse-gradient flex justify-center p-5 text-2xl"
              : "float-right"
          }`}
        >
          <div className="w-5 ml-7">
            <ArrowTopRightOnSquareIcon className=" w-5 h-5 "></ArrowTopRightOnSquareIcon>
          </div>
          <div className="">
            <div className="">Customize</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="">
      {props.createdContent
        ?.filter((created: any) => !deletedResponseIds.includes(created["id"]))
        .map((toolResponse: any, idx: number) => {
          const responseId = toolResponse["id"];

          return (
            <div className="grid border-b-2">
              <div className="w-full cursor-pointer mt-5">
                {renderCustomizeDiv(responseId)}
              </div>
              {/* {renderResponseKeyValue(keys, toolResponse, idx)} */}
              <ToolSubmissionResponse
                toolResponse={toolResponse}
                setTextToCopy={(tmpresponseTextToCopy) => {
                  if (!responseTextToCopy[idx]) {
                    responseTextToCopy[idx] = tmpresponseTextToCopy;
                    setResponseTextToCopy(responseTextToCopy);
                  }
                }}
              ></ToolSubmissionResponse>

              <ToolResponseActions
                setMoreLikeResponse={props.setMoreLikeResponse}
                createContent={props.createContent}
                setLikedResponses={props.setLikedResponses}
                likedResponses={props.likedResponses}
                responseId={responseId}
                setDeletedResponseIds={setDeletedResponseIds}
                toolResponse={toolResponse}
                idx={idx}
                responseTextToCopy={responseTextToCopy}
              />

              {renderCustomizeDiv(responseId)}
              {!props.highLightCustomize &&
                renderAudioOptions(
                  props.toolType,
                  toolResponse,
                  responseId,
                  usageData
                )}
            </div>
          );
        })}
    </div>
  );
};

export default ToolResponses;
