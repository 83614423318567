import React, { useState } from "react";

interface FileUploaderProps {
  onFilesSelect: (files: File[]) => void;
}

const useFileUploader = ({ onFilesSelect }: FileUploaderProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maybeFiles: FileList | null =
      event.target.files && event.target.files;
    if (!maybeFiles) return;
    //maybeFiles to array
    const files = Array.from(maybeFiles);

    let error = false;
    files.forEach((file) => {
      if (file) {
        if (file.size > 10 * 1024 * 1024) {
          alert("File size must be less than 10MB");
          error = true;
          return;
        }
        if (!file.name.endsWith(".mp3") && !file.name.endsWith(".wav")) {
          error = true;
          alert(`Only wav or MP3 files are allowed ${file.name}`);
          return;
        }
      }
    });

    if (error) return;
    setSelectedFiles(files);
    onFilesSelect(files);
  };

  return {
    selectedFiles,
    handleFileInput,
  };
};

export default useFileUploader;
