import { Query } from "../../../../../generated/graphql";

export const findTextSubmissionByText = (
  textToSpeeches: Query,
  text: string
) => {
  const textToSpeechSubmission =
    textToSpeeches?.allTextToSpeechSubmissions?.nodes?.find((node) => {
      return node?.text === text;
    });
  return textToSpeechSubmission;
};

export function replaceStringInObject(
  obj: any,
  searchString: string,
  replaceString: string
): void {
  if (typeof obj !== "object" || obj === null) {
    return;
  }

  for (const key of Object.keys(obj)) {
    const value = obj[key];

    if (typeof value === "string" && value.includes(searchString)) {
      obj[key] = value.replace(searchString, replaceString);
    } else {
      replaceStringInObject(value, searchString, replaceString);
    }
  }
}
