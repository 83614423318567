import { FolderIcon } from "@heroicons/react/24/solid";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../UserApp";
import Spinner from "../../common/Spinner";
import {
  SuccessButtonStyle,
  GrayCancelButtonStyle,
} from "../../constants/CSSConstants";
import useFileUploader from "../../hooks/useFileUploader";
import useUploadAudioFile from "../../hooks/useUploadAudioFile";
import { useCloneVoice } from "./useClonevoice";
import { toast } from "react-toastify";
import { trimAndAddEllipsis } from "../../utils/StringUtils";

type TrainVoiceWithFilesProps = {
  voiceName: string;
  onTrainingVoice: () => void;
  voicedTrainedCallBack: (voiceId: string, demoURL: string) => void;
  onError: (error: string) => void;
  previousVoiceId?: string;
  voiceId: string;
};

enum CreateVoicePrintErrors {
  NO_VOICE_NAME = "Please enter a voice name",
  NO_VOICE_SAMPLES = "Please upload at least one voice sample",
}

const TrainVoiceWithFiles = (props: TrainVoiceWithFilesProps) => {
  const UPLOAD_BUCKET = "clove-ai-user-voices";

  const app = useContext(AppContext);

  const { cloneVoice, voiceId, voicePrintDemoUrl } = useCloneVoice();

  const { uploadFile } = useUploadAudioFile();
  const [voiceSampleFiles, setVoiceSampleFiles] = useState<File[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isCreatingVoice, setIsCreatingVoice] = useState<boolean>(false);

  const [error, setError] = useState<CreateVoicePrintErrors | null>(null);

  const onFilesSelect = useCallback(
    (files: File[]) => {
      console.log("Selected file: ", files);
      setVoiceSampleFiles([...voiceSampleFiles, ...files]);
      setError(null);
    },
    [voiceSampleFiles]
  );
  const { selectedFiles, handleFileInput } = useFileUploader({
    onFilesSelect,
  });

  useEffect(() => {
    const nodes = app.user.voicesByUserId?.nodes?.filter(
      (voice) => voice?.id === voiceId
    );
    console.log("nodes1", JSON.stringify(nodes));
    if (voiceId && voicePrintDemoUrl && (nodes || []).length > 0) {
      props.voicedTrainedCallBack(voiceId, voicePrintDemoUrl);
    }
  }, [app.user.voicesByUserId?.nodes, voiceId, props, voicePrintDemoUrl]);

  const renderCreateButton = () => {
    if (isCreatingVoice) {
      return (
        <div className="flex justify-center">
          <Spinner />
        </div>
      );
    } else {
      const isDisabled =
        props.voiceName === "" || voiceSampleFiles.length === 0;
      const buttonStyle = isDisabled
        ? GrayCancelButtonStyle
        : SuccessButtonStyle;

      const handleClick = async () => {
        if (props.voiceName === "") {
          setError(CreateVoicePrintErrors.NO_VOICE_NAME);
          props.onError(CreateVoicePrintErrors.NO_VOICE_NAME);
          return;
        }
        if (voiceSampleFiles.length === 0) {
          setError(CreateVoicePrintErrors.NO_VOICE_SAMPLES);
          props.onError(CreateVoicePrintErrors.NO_VOICE_SAMPLES);
          return;
        }
        props.onTrainingVoice();

        setIsCreatingVoice(true);
        const uploadPromises = voiceSampleFiles.map((file) => {
          return uploadFile({
            file,
            uploadBucket: UPLOAD_BUCKET,
            fileFolder: props.voiceId,
            userId: app.user.id,
            extend: false,
            fileName: file.name,
          });
        });
        await Promise.all(uploadPromises);
        if (!app.user.id) {
          throw new Error("User id is not defined");
        }
        await cloneVoice({
          userId: app.user.id,
          voiceName: props.voiceName,
          numberOfVoiceSubmissions: voiceSampleFiles.length,
          userRecordingURL: "TODO",
          userName: app.user.name,
          previousVoiceId: props.previousVoiceId,
          voiceId: props.voiceId,
        });
        app.refreshData();
        toast(
          "Voice trained successfully! checkout it out by clicking on the play button"
        );
        setIsCreatingVoice(false);
      };

      return (
        <button
          className={`${buttonStyle} mt-10`}
          onClick={handleClick}
          // disabled={isDisabled}
        >
          Train Voice
        </button>
      );
    }
  };

  return (
    <div className="grid  gap-5 justify-center">
      <div className="text-xl ">Upload Voice Samples</div>
      <div
        onClick={() => fileInputRef.current?.click()}
        className="grid justify-center hover:bg-gray-100 rounded-xl hover:cursor-pointer"
      >
        <div className="flex justify-center">
          <FolderIcon
            className={`h-10 w-10 ${
              error === CreateVoicePrintErrors.NO_VOICE_SAMPLES
                ? "text-red-500"
                : ""
            } } `}
          />
        </div>

        <div>
          <input
            type="file"
            multiple
            onChange={handleFileInput}
            ref={fileInputRef}
            className="hidden"
          />
          {voiceSampleFiles && (
            <div>
              <div className="grid gap-2">
                {voiceSampleFiles.map((file) => {
                  let value = URL.createObjectURL(file);
                  return (
                    <div key={file.name} className="flex">
                      <div className="pt-3 px-2 w-40">
                        {trimAndAddEllipsis(file.name)}
                      </div>
                      <audio src={value} controls></audio>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {renderCreateButton()}
    </div>
  );
};

export default TrainVoiceWithFiles;
