import { Link, useLocation, useParams } from "react-router-dom";

import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../UserApp";
import { Tool } from "../../../generated/graphql";

import ToolUserHistory from "./ToolUserHistory";
import ToolResponses from "./ToolSubmissionResponses";
import ToolSideMenu from "./ToolSideMenu";
import useMakeBackendRequest from "../../../hooks/useMakeBackendRequest";
import {
  deleteOnboardingStatus,
  getOnboardingStatus,
} from "../../onboarding/getOnboardingStatus";
import { OnboardingStatus } from "../../onboarding/Onboarding";
import { ONBOARDING_CREATE_TEXT_SUCCESS_TRIGGER } from "../../../constants/Constants";
import { useAuthMutation } from "../../../hooks/useAuthApollo";
import { getQuery } from "../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../graphql/whitelistedQueries";
import { AttentionInterestDesireAction_TOOL_NAME } from "../../../constants/Constants";
import { CONVERSATION_TOOL_NAME } from "../../../constants/Constants";

const ToolDetail = () => {
  const context = useContext(AppContext);
  const { post } = useMakeBackendRequest();
  const onboardingStatus: OnboardingStatus | null = getOnboardingStatus();

  // let generativeAiTool;
  const [generativeAiTool, setGenerativeAiTool] = useState<Tool>();
  //get generativeToolId from url

  // let { tool } = location.state as propState;
  // let { generativeToolId } = useParams();

  const updateOnboardingQuery = getQuery(WhitelistQueries.UpdateUserOnboarding);
  const [updateOnboardingMutation] = useAuthMutation({
    mutation: updateOnboardingQuery,
  });

  let { generativeToolId } = useParams();

  useEffect(() => {
    if (!context?.generativeAiTools) {
      return;
    }
    const generativeAiTool = context?.generativeAiTools?.find((tool) => {
      return tool.id === generativeToolId;
    });
    setGenerativeAiTool(generativeAiTool);
  }, [context?.generativeAiTools, generativeToolId]);

  const [createdContent, setCreatedContent] = useState<any[]>();
  const [productName, setProductName] = useState<string>();
  const [productDescription, setProductDescription] = useState<string>();
  const [generatingContent, setGeneratingContent] = useState<boolean>(false);

  const [formError, setFormError] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [moreLikeResponse, setMoreLikeResponse] = useState<JSON>();
  const [likedResponses, setLikedResponses] = useState<string[]>([]);

  const isProductTitleRequired =
    generativeAiTool?.titleInputLabel &&
    generativeAiTool?.titleInputLabel !== "";

  type TutorialTextType = {
    title: string;
    taskDescription: string;
    task: string;
  };
  type TutorialTextMap = Record<string, TutorialTextType>;

  const tutorialTexts: TutorialTextMap = {
    [AttentionInterestDesireAction_TOOL_NAME]: {
      title: "Acme Inc",
      task: "Buy our products to improve your life",
      taskDescription: `Type a product name and description. For example, "Acme Inc" and
        "Buy our products to improve your life". Then click "Create"`,
    },
    [CONVERSATION_TOOL_NAME]: {
      title: "",
      task: `A conversation between an expert marketer and
        salesperson about how to get more leads`,
      taskDescription: `Type a conversation topic and who participates in it. For
        example, "A conversation between an expert marketer and
        salesperson about how to get more leads". Then click "Create"`,
    },
  };

  const createContent: () => Promise<void> = async (
    moreLike: JSON | undefined = undefined
  ) => {
    setGeneratingContent(true);
    const params = [productDescription];
    if (productName && productName !== "") {
      params.push(productName);
    }
    const generateParams = {
      productDescription: productDescription,
      productName: productName,
    };
    const moreLikeResponseContent = moreLike || moreLikeResponse;
    const createContentResponse = await post(
      `${process.env.REACT_APP_ML_ENGINE}/generate`,
      {
        toolId: generativeToolId,
        tags: selectedTags,
        // tags: [],
        userId: context?.user?.id,
        moreLikeResponse: JSON.stringify(moreLikeResponseContent),
        //validate me
        params,
        generateParams,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responses: Object[] = createContentResponse.data;
    const JSONresponses = responses.map((response) => {
      return JSON.parse(JSON.stringify(response));
    });

    console.log(`JSONresponses: ${JSONresponses}`);
    setCreatedContent(JSONresponses);
    if (
      onboardingStatus?.successTrigger ===
      ONBOARDING_CREATE_TEXT_SUCCESS_TRIGGER
    ) {
      deleteOnboardingStatus();
      updateOnboardingMutation({
        variables: {
          id: context.user.id,
          onboarded: true,
        },
      });
    }
    setGeneratingContent(false);
  };

  if (!generativeAiTool) {
    return <div>Tool not found</div>;
  }

  const isOnboarding =
    onboardingStatus?.hightLightTool === generativeAiTool?.name;

  //defaultTags

  const renderLoadingContent = () => {
    return (
      <div className="flex justify-center">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const requestGenerateContent = () => {
    if (productDescription && (!isProductTitleRequired || productName)) {
      setFormError("");
      createContent();
    } else {
      setFormError("Required fields can not be empty");
    }
  };
  return (
    <div className="grid grid-cols-12">
      <div className=" col-span-3 hidden md:block">
        <div className=" mb-4 py-2 px-5">
          <Link to={"../"}>
            <div className="w-44 text-sm flex">
              <div className="pt-2">
                <FontAwesomeIcon
                  className="mx-2 mr-5 cursor-pointer"
                  icon={faArrowLeftLong}
                />
              </div>
              <div className="pt-2">Back Main Menu</div>
            </div>
          </Link>
        </div>
        <ToolSideMenu
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          moreLike={moreLikeResponse}
        ></ToolSideMenu>
      </div>

      <div className="grid col-span-12 lg:col-span-6 auto-rows-max ">
        <div className="text-3xl mb-3">{generativeAiTool?.name}</div>
        <div className="w-fit text-lg	 mb-5">{generativeAiTool?.summary}</div>
        <div>
          <div>
            {isProductTitleRequired && (
              <div>
                <div>
                  <div className="w-fit text-lg	 mb-5">
                    {generativeAiTool?.titleInputLabel}
                  </div>
                </div>
                <div>
                  <textarea
                    onChange={(e) => setProductName(e.target.value)}
                    value={productName}
                    className={`
                    ${
                      isOnboarding && onboardingStatus?.hightLightTool
                        ? "border-pulse-gradient"
                        : ""
                    }

                    ${
                      formError && !productName ? "border-red-600" : ""
                    }  h-10 w-full border-2 p-2	`}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="w-fit text-lg	 mb-2 mt-4">
              {generativeAiTool?.contentInputLabel}
            </div>
          </div>
          {/* colorful bouncing text */}
          <div>
            {isOnboarding && onboardingStatus?.hightLightTool && (
              <div
                // on double click
                onDoubleClick={() => {
                  setProductDescription(
                    tutorialTexts[onboardingStatus.hightLightTool].task
                  );
                }}
                className="text-xl text-blue-800 font-bold"
              >
                {tutorialTexts[onboardingStatus.hightLightTool].taskDescription}
              </div>
            )}
          </div>
          <div className="">
            <textarea
              onKeyDown={(e) => {
                // on enter but not on shift enter

                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  requestGenerateContent();
                }
              }}
              onChange={(e) => setProductDescription(e.target.value)}
              value={productDescription}
              className={`
              ${
                isOnboarding && onboardingStatus?.hightLightTool
                  ? "border-pulse-gradient"
                  : ""
              }
              
              ${
                formError && !productDescription ? "border-red-600" : ""
              }  h-48 w-full border-2 p-2`}
            />
          </div>
          {generatingContent ? (
            renderLoadingContent()
          ) : (
            <div
              className={` ${
                isOnboarding &&
                (productDescription?.length || 0) > 40 &&
                !createdContent
                  ? "bg-pulse-gradient font-bold"
                  : "text-white"
              } hover:cursor-pointer bg-blue-400 flex p-2 rounded-lg  text-lg justify-center`}
              onClick={() => {
                requestGenerateContent();
              }}
            >
              <div>Create</div>
            </div>
          )}
        </div>

        <ToolResponses
          highLightCustomize={isOnboarding}
          likedResponses={likedResponses}
          setLikedResponses={setLikedResponses}
          createContent={createContent}
          hide={generatingContent}
          createdContent={createdContent}
          productName={productName}
          setMoreLikeResponse={setMoreLikeResponse}
          toolType={generativeAiTool?.toolTypeByToolTypeId?.type!}
        />
      </div>
      <div className="grid col-span-12 lg:col-span-3 auto-rows-max pl-10 overflow-auto h-screen">
        <ToolUserHistory
          userId={context?.user?.id!}
          toolId={generativeToolId!}
          submissions={createdContent?.map((content) => {
            return {
              response: JSON.stringify(content),
              liked: false,
            };
          })}
        ></ToolUserHistory>
      </div>
    </div>
  );
};

export default ToolDetail;
