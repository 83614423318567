import { PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
const SiteBanner = () => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);

  const renderBannerContent = () => {
    return (
      <div className="flex flex-col">
        {/* disable full screen */}
        <video className="h-96" controls>
          <source
            src="https://storage.googleapis.com/public-cloveai/demos/Clove%20Video%20Creation%20Demo.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    );
  };

  return (
    <div>
      <div
        onClick={() => {
          if (!isBannerOpen) setIsBannerOpen(!isBannerOpen);
        }}
        className=" flex w-full justify-center bg-sky-100 cursor-pointer"
      >
        <div className="flex justify-center ml-auto">
          {isBannerOpen
            ? renderBannerContent()
            : "        1 min tutorial on how to create videos with custom voices!"}
        </div>
        {/* float right */}

        <div
          onClick={() => {
            setIsBannerOpen(false);
          }}
          className="ml-auto text-blue-600 mr-5 "
        >
          <XMarkIcon className="w-7 h-7" />
        </div>
      </div>
    </div>
  );
};

export default SiteBanner;
