import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../UserApp";
import { Voice } from "../../generated/graphql";
import TrainVoiceWithFiles from "./TrainVoiceWithFiles";
import AudioPlayer from "../../utils/AudioPlayer";
import useListBucketFiles, { BucketFile } from "../../hooks/useListBucketFiles";
import { XCircleIcon } from "@heroicons/react/24/solid";
import useInfoPopUps from "../../hooks/useInfoPopUps";
import useDeleteBucketFile from "../../hooks/useDeleteBucketFile";
import { toast } from "react-toastify";
import { useAuthMutation } from "../../hooks/useAuthApollo";
import { getQuery } from "../../graphql/fetchQuery";
import { WhitelistQueries } from "../../graphql/whitelistedQueries";

const ViewVoice = () => {
  const { id } = useParams<{ id: string }>();

  const app = useContext(AppContext);

  const { listBucketFiles } = useListBucketFiles();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Voice>();
  const [bucketFiles, setBucketFiles] = useState<BucketFile[]>([]);
  const { displayConfirmation } = useInfoPopUps();
  const { deleteBucketFile } = useDeleteBucketFile();
  const [isPublic, setIsPublic] = useState<boolean>(false);

  // const [isPublic, setIsPublic] = useState<boolean>(false);

  const setPublicMutationQuery = getQuery(
    WhitelistQueries.SetPublicVoiceMutation
  );

  const [setPublicMutation] = useAuthMutation({
    mutation: setPublicMutationQuery,
  });

  const [demoURL, setDemoURL] = useState<string>();

  const [audioHighListCss, setAudioHighListCss] = useState<string>("");

  //always that we send functions to children, we need to wrap them with useCallback
  //otherwise, the children will be re-rendered every time the parent is re-rendered

  const trainError = useCallback((error: string) => {
    console.log("Error: ", error);
  }, []);

  const voicedTrainedCallBack = useCallback(
    (voiceId: string, demoURL: string) => {
      setDemoURL(demoURL);
      setAudioHighListCss("border-blue-300 rounded-xl border-b-2");
    },
    []
  );

  const loadSampleFiles = async () => {
    if (data?.id) {
      const files = await listBucketFiles(data?.id);
      setBucketFiles(files);
    }
  };

  useEffect(() => {
    app.user.voicesByUserId.nodes.forEach((voice) => {
      if (voice?.id === id && voice) {
        setData(voice);
        setIsPublic(voice.isPublic);
        if (voice.demoUrl) {
          setDemoURL(voice.demoUrl);
        }
      }
    });

    loadSampleFiles();
    setLoading(false);
  }, [app.user.voicesByUserId.nodes, data?.name, id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Error</div>;
  }

  const deleteConfirmation = async (rawFileName: string) => {
    displayConfirmation({
      message: "Are you sure you want to delete this voice sample?",
      handleConfirm: async () => {
        setBucketFiles((bucketFiles) =>
          bucketFiles.filter((file) => file.name !== rawFileName)
        );
        await deleteBucketFile({
          fileName: rawFileName,
        });
        loadSampleFiles();
        toast("Voice sample deleted");
      },
    });
  };

  return (
    <div className="grid gap-16">
      <div
        className="
      flex justify-center
      text-3xl"
      >
        {data.name}
      </div>
      <div className="flex justify-center">
        <div className={audioHighListCss}>
          <AudioPlayer src={demoURL || data.demoUrl!}></AudioPlayer>
        </div>
      </div>
      <div>
        {/* set public checkbox */}
        <div>
          <div className="flex justify-center ">
            <label
              htmlFor="isPublic"
              className="mr-2 block text-sm text-gray-900"
            >
              Public
            </label>
            <input
              id="isPublic"
              name="isPublic"
              type="checkbox"
              checked={isPublic}
              onChange={(e) => {
                // setIsPublic(e.target.checked);
                setIsPublic(e.target.checked);
                setPublicMutation({
                  variables: {
                    id: data.id,
                    isPublic: e.target.checked,
                  },
                });
              }}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div className="flex justify-center text-sm my-2">Disclaimer</div>
        </div>
      </div>
      <div>
        <div className="text-2xl">Files</div>
        <div className="grid grid-cols-2 gap-4">
          {bucketFiles.map((file) => (
            <div className="flex gap-2">
              <div
                key={file.name}
                className="
                flex justify-center items-center w-72"
              >
                {file.name}
              </div>
              <audio src={file.url} controls></audio>

              <XCircleIcon
                onClick={() => deleteConfirmation(file.rawName)}
                className="h-5 w-5 text-gray-400 cursor-pointer mt-4"
              ></XCircleIcon>
            </div>
          ))}
        </div>
      </div>

      <div>
        <TrainVoiceWithFiles
          voiceId={data.id}
          onTrainingVoice={() => {}}
          previousVoiceId={data.id}
          voiceName={data.name}
          onError={trainError}
          voicedTrainedCallBack={voicedTrainedCallBack}
        ></TrainVoiceWithFiles>
      </div>
    </div>
  );
};

export default ViewVoice;
