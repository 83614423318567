import { OnboardingStatusLocalStorageKey } from "../../constants/Constants";
import { OnboardingStatus } from "./Onboarding";

export const WORKSHOP_ONBOARDING_STATUS = "WORKSHOP";

export const getOnboardingStatus = () => {
  const onboardingStatus = localStorage.getItem(
    OnboardingStatusLocalStorageKey
  );
  return onboardingStatus
    ? (JSON.parse(onboardingStatus) as OnboardingStatus)
    : null;
};

export const setOnboardingStatus = (onboardingStatus: OnboardingStatus) => {
  localStorage.setItem(
    OnboardingStatusLocalStorageKey,
    JSON.stringify(onboardingStatus)
  );
};

export const deleteOnboardingStatus = () => {
  localStorage.removeItem(OnboardingStatusLocalStorageKey);
};

export const setNextOnboardingStep = (onboardingStatus: OnboardingStatus) => {};
