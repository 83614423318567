import { StarterKitItemPreview } from "./StarterKitItem";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../UserApp";
import "firebase/compat/auth";
import { ToolTypes } from "../../constants/Constants";
import { Tool } from "../../generated/graphql";
import { OnboardingStatus } from "../onboarding/Onboarding";
import { getOnboardingStatus } from "../onboarding/getOnboardingStatus";
const StarterKit = () => {
  const app = useContext(AppContext);
  const generativeAiTools = new Set(app.generativeAiTools);

  const tutorialRef = useRef<HTMLDivElement>(null);

  const onboarding: OnboardingStatus | null = getOnboardingStatus();

  const scrollToTutorialTool = () => {
    tutorialRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTutorialTool();
  }, [tutorialRef]);

  const getGenerativeAiToolsElements = (tooltype: ToolTypes) => {
    return Array.from(generativeAiTools)
      .filter((tool) => {
        return tool.toolTypeByToolTypeId?.type === tooltype;
      })
      .map((generativeAiTool: Tool) => {
        const isTutorialTool =
          onboarding?.hightLightTool === generativeAiTool.name
            ? "bg-pulse-gradient "
            : "";
        return (
          <div ref={isTutorialTool ? tutorialRef : null}>
            <StarterKitItemPreview
              tool={generativeAiTool}
              postListId={generativeAiTool.id}
              image={""}
              title={generativeAiTool.name}
              summary={generativeAiTool.summary}
              id={""}
            ></StarterKitItemPreview>
          </div>
        );
      });
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="text-2xl flex justify-center">
        Let's create some engaging content that resonates with your audience.
      </div>
      <div className=" border-t-2 ">
        <div className="text-2xl my-3 flex justify-center">Audio Creation</div>
        <div className="grid grid-cols-1 md:grid-cols-3">
          {
            //window.location.hostname === "localhost" &&
            getGenerativeAiToolsElements(ToolTypes.AUDIO_GENERATIVE)
          }
        </div>
      </div>

      <div className=" border-t-2 ">
        <div className="text-2xl my-3 flex justify-center">Text Creation</div>
        <div className="grid grid-cols-1 md:grid-cols-3">
          {getGenerativeAiToolsElements(ToolTypes.TEXT_GENERATIVE)}
        </div>
      </div>
    </div>
  );
};
export { StarterKit };
