import { Link, useNavigate } from "react-router-dom";
import { Tool } from "../../generated/graphql";
import { getOnboardingStatus } from "../onboarding/getOnboardingStatus";
import { OnboardingStatus } from "../onboarding/Onboarding";
import { useEffect, useRef } from "react";

const StarterKitItemPreview = (props: {
  tool: Tool;
  id: string;
  image: string;
  title: string;
  summary: string;
  postListId: string;
}) => {
  const navigate = useNavigate();
  const onboarding: OnboardingStatus | null = getOnboardingStatus();
  const animateCard =
    onboarding?.hightLightTool === props.title ? "bg-pulse-gradient " : "";

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/starter-kit/build/${props.postListId}/${props.id}`, {
            state: { tool: props.tool },
          });
        }}
        style={{ animationDuration: "1s", animationIterationCount: "infinite" }}
        className={`${animateCard} cursor-pointer h-40 relative rounded-lg p-3 m-2 shadow-md shadow-blue-200 bg-light-purple`}
      >
        <div className="px-3 py-3 ">
          <div className="text-2xl font-extrabold  tracking-tight">
            {props.title}
          </div>
          <p className="mb-2 leading-normal text-sm">{props.summary}</p>
          <div className="absolute inset-x-0 bottom-0 "></div>
        </div>
      </div>
    </div>
  );
};

export { StarterKitItemPreview };
