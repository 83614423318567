import { useCallback } from "react";
import {
  Query,
  TextToSpeechSubmission,
} from "../../../../../generated/graphql";
import useTextToSpeech from "../../../../../hooks/useTextToSpeech";
import { getQuery } from "../../../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../../../graphql/whitelistedQueries";
import { useAuthMutation } from "../../../../../hooks/useAuthApollo";
import useFindVoiceId from "./UseFindVoiceIdResult";
import { RegenerateSentenceAudioProps } from "./RegenerateSentenceAudio";

const useWorkshopTextToSpeech = (
  textToSpeeches: Query | null,
  //map
  contentKeyVoiceIdMap: Map<string, string>
) => {
  const { textToSpeech, processing } = useTextToSpeech();

  const DeleteTextToSpeechSubmissionByIdMutation = getQuery(
    WhitelistQueries.DeleteTextToSpeechSubmissionById
  );
  const [deleteTextToSpeechSubmissionById] = useAuthMutation({
    mutation: DeleteTextToSpeechSubmissionByIdMutation,
  });

  const findVoiceIdByObject = useFindVoiceId({
    textToSpeeches: textToSpeeches,
    contentKeyVoiceId: contentKeyVoiceIdMap,
  });

  const generateTextToSpeech = useCallback(
    async (props: RegenerateSentenceAudioProps) => {
      const previousSubmission = props.textToSpeechIdxHistory.get(props.idx);
      let voiceId = previousSubmission?.voiceId;

      if (!voiceId) {
        //we need to try to find one first
        const possibleVoiceId: string = findVoiceIdByObject(
          props.contentKey,
          props.submissionResponse
        );
        if (!possibleVoiceId) {
          throw new Error("reponse workshop could not find voice id");
        }
        voiceId = possibleVoiceId;
      }

      console.log(
        `creating speech ${
          props.content
        } with order previousSubmission?.order || props.idx, ${
          previousSubmission?.order || props.idx
        } `
      );

      try {
        const textToSpeechResponse = await textToSpeech({
          text: props.content,
          userToolSubmissionResponseId: props.userToolSubmissionResponseId,
          uploadPath: props.userToolSubmissionResponseId,
          voiceId: voiceId,
          order: previousSubmission?.order || props.idx,
        });

        props.setTextToSpeechIdxHistory((prev: any) => {
          //remove props.content from map
          const newMap = new Map(prev);
          newMap.delete(props.idx);
          return newMap;
        });

        if (textToSpeechResponse.audioFileURL && previousSubmission?.id) {
          await deleteTextToSpeechSubmissionById({
            variables: {
              id: previousSubmission?.id,
            },
          });
        }

        props.refetchTextToSpeeches();
      } catch (error) {
        console.log(error);
      }
    },
    [deleteTextToSpeechSubmissionById, textToSpeech]
  );

  return { generateTextToSpeech, processing };
};

export default useWorkshopTextToSpeech;
