import { useState } from "react";
import { signOut } from "../auth";

import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideMenu = () => {
  const [showDirectoryMenu, setShowDirectoryMenu] = useState(false);
  const pathName = window.location.pathname;

  // if (pathName.includes("build/")) return <></>;

  return <></>;

  return (
    <aside className="w-20 hidden md:block" aria-label="Sidebar">
      <div className=" py-4 px-3 flex flex-col h-screen pb-24  rounded ">
        <div className="flex-grow">
          <div className="grid gap-2">
            {/* <a href="/">
              <FontAwesomeIcon className="cursor-pointer" icon={faHome} />
              <span className="ml-3">Home</span>
            </a>

            <a href="/">
              <FontAwesomeIcon className="cursor-pointer" icon={faUserGroup} />
              <span className="ml-3">Events</span>
            </a>

            <span
              className="cursor-pointer"
              onClick={() => {
                setShowDirectoryMenu(!showDirectoryMenu);
              }}
            >
              <FontAwesomeIcon className="cursor-pointer" icon={faBook} />
              <span className="ml-3">Directory</span>
            </span>

            <div
              id="dropdown-example"
              className={`grid ${
                showDirectoryMenu ? "" : "hidden"
              } py-2 space-y-2`}
            >
              <a href="/"> Doctors </a>
              <a href="/"> Patients </a>
              <a href="/"> Health Insurance </a>
            </div>

             */}
          </div>
        </div>

        <div
          className="bottom-0"
          onClick={() => {
            signOut();
          }}
        >
          <a href="/">
            <FontAwesomeIcon className="cursor-pointer" icon={faSignOut} />
            <span className="ml-3">Sign Out</span>
          </a>
        </div>
      </div>
    </aside>
  );
};

export default SideMenu;
