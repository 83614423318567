import { useContext, useState } from "react";
import { AppContext } from "../../../UserApp";
import { Query } from "../../../generated/graphql";
import { getQuery } from "../../../graphql/fetchQuery";

import { WhitelistQueries } from "../../../graphql/whitelistedQueries";
import { cleanToolResponse } from "./toolResponseCleanup";
import { useAuthMutation, useAuthQuery } from "../../../hooks/useAuthApollo";

type ToolSideMenuProps = {
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  moreLike?: JSON;
};
const ToolSideMenu = (props: ToolSideMenuProps) => {
  const context = useContext(AppContext);

  const defaultTags = ["serious", "funny", "informative", "persuasive"];

  const [creatingNewTag, setCreatingNewTag] = useState<boolean>(false);
  const [newTagName, setNewTagName] = useState<string>();
  const [availableTags, setAvailableTags] =
    useState<Array<string>>(defaultTags);

  const GET_ALL_TAGS_FOR_USER = getQuery(WhitelistQueries.AllToolTags);
  const { data: allTagsForUser } = useAuthQuery<Query>(
    {
      query: GET_ALL_TAGS_FOR_USER,
    },
    {
      variables: {
        userId: context?.user?.id,
      },
    }
  );

  const userTags =
    allTagsForUser?.allToolTags?.nodes.map((tag) => tag?.name!) || [];

  const [createGenerativeAiToolTag] = useAuthMutation({
    mutation: getQuery(WhitelistQueries.CreateToolTag),
  });

  const renderGenerativeTag = (tag: string) => {
    const indexOf = props.selectedTags.indexOf(tag);
    const isSelected = indexOf > -1;

    return (
      <div
        className={`  ${
          !isSelected ? "bg-light-purple-2x" : " bg-pink-400"
        } cursor-pointer rounded-full text-xs  p-1 text-center `}
        onClick={() => {
          if (isSelected) {
            props.setSelectedTags(
              props.selectedTags.filter((item) => item !== tag)
            );
          } else {
            props.setSelectedTags((oldArray) => [...oldArray, tag]);
          }
        }}
      >
        {tag}
      </div>
    );
  };

  const renderMoreLike = () => {
    const cleanResponse = cleanToolResponse(props.moreLike);
    const keys = Object.keys(cleanResponse);

    const renderedKeys = keys.map((key, index) => {
      return (
        <div key={index} className="mb-2">
          <span className=" font-bold mr-2">{key}:</span>
          <span>{JSON.stringify(cleanResponse[key])}</span>
        </div>
      );
    });
    return (
      <div className="border-b-2">
        <div className="m-2 p-3">
          <span className="capitalize font-extrabold mr-1 flex justify-center my-5">
            Creating More like this:
          </span>
          <span className="text-sm">{renderedKeys}</span>
        </div>
      </div>
    );
  };

  return (
    <div className=" py-3 w-72	">
      <div className="font-medium ">Add Tags</div>

      <div className="grid grid-cols-3 gap-2">
        {availableTags.concat(userTags).map((tag) => {
          return renderGenerativeTag(tag);
        })}

        {/* {JSON.stringify(allTagsForUser?.data.allGenerativeAiToolTags?.nodes.map(tag => tag?.name || "") || [])} */}
      </div>
      <div
        className="flex text-sm justify-center m-4 cursor-pointer text-blue-700"
        onClick={() => {
          setCreatingNewTag(true);
        }}
      >
        {!creatingNewTag && "+ Create new tag"}
      </div>
      {creatingNewTag && (
        <div>
          <div>
            {/* <input type="text" id="first_name"  placeholder="John" required> */}

            <div className="flex justify-center">Tag name:</div>
            <input
              onChange={(e) => setNewTagName(e.target.value)}
              value={newTagName}
              maxLength={30}
              className="
                  my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 pl-2 "
              // className=" border-b-2"
            ></input>
          </div>
          <div className="flex justify-evenly">
            <span
              className="mx-2 cursor-pointer rounded-full text-xs  p-2 text-center bg-blue-400  text-white w-full "
              onClick={() => {
                //TODO: push tag to server
                createGenerativeAiToolTag({
                  variables: {
                    name: newTagName,
                    userId: context?.user?.id,
                  },
                });
                setAvailableTags((oldArray) => [...oldArray, newTagName!]);
                defaultTags.push(newTagName!);

                setNewTagName("");

                setCreatingNewTag(false);
              }}
            >
              Create
            </span>

            <span
              className="mx-2 cursor-pointer rounded-full text-xs  p-2 text-center bg-gray-300 w-full "
              onClick={() => {
                setNewTagName("");
                setCreatingNewTag(false);
              }}
            >
              Cancel
            </span>
          </div>
        </div>
      )}
      {props.moreLike && renderMoreLike()}
    </div>
  );
};

export default ToolSideMenu;
