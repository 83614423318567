import { Maybe, Voice } from "../generated/graphql";
import { AppData } from "../hooks/useLoadAppData";

export const getUserAvailableVoices = (app: AppData) => {
  const addedVoices: (Maybe<Voice> | undefined)[] =
    app.user.userAddedVoicesByUserId?.nodes?.map(
      (added) => added?.voiceByVoiceId
    ) || [];

  //filter added voices to Voice[]
  const userAddedVoices = addedVoices.filter(
    (voice) => voice !== undefined
  ) as Voice[];

  const userAvailableVoices: Maybe<Voice>[] = (
    app.user.voicesByUserId?.nodes || []
  ).concat(userAddedVoices);

  return userAvailableVoices;
};
