import { useEffect, useState } from "react";

export function useMicrophoneEnabled() {
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const handlePermissionChange = () => {
      navigator.permissions
        .query({ name: "microphone" as PermissionName })
        .then((permissionStatus) => {
          setIsMicrophoneEnabled(permissionStatus.state === "granted");
        });
    };

    const getMicrophonePermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setIsMicrophoneEnabled(true);
        stream.getTracks().forEach((track) => track.stop()); // release the stream
      } catch (error) {
        setIsMicrophoneEnabled(false);
      }
    };

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      // Use a simple check for mobile devices
      setIsMicrophoneEnabled(true);
    } else if (navigator.permissions && navigator.permissions.query) {
      // Use the navigator.permissions API if available
      navigator.permissions
        .query({ name: "microphone" as PermissionName })
        .then((permissionStatus) => {
          setIsMicrophoneEnabled(permissionStatus.state === "granted");
          permissionStatus.addEventListener("change", handlePermissionChange);
        });

      return () => {
        navigator.permissions
          .query({ name: "microphone" as PermissionName })
          .then((permissionStatus) => {
            permissionStatus.removeEventListener(
              "change",
              handlePermissionChange
            );
          });
      };
    } else {
      // Fall back to using the navigator.mediaDevices API if the navigator.permissions API is not available
      getMicrophonePermission();
    }
  }, []);

  return isMicrophoneEnabled;
}
