import CreateVoicePrint from "../home/audioProcess/CreateVoicePrint";
import SearchPublicVoices from "../home/audioProcess/search/SearchPublicVoices";
import VoiceList from "../home/audioProcess/VoiceList";
import Backofffice from "../home/backoffice/Backofffice";
import Checkout from "../home/checkout/Checkout";
import Dashboard from "../home/starterKit/Dashboard";
import ResponseWorksop from "../home/starterKit/tools/responses/workshop/ResponseWorkshop";
import CreateVideoFromContext from "../home/video/CreateVideoFromContext";

const getIdPath = (id: string) => {};
const routes = {
  addVoice: {
    path: "/voices/add",
    name: "Add Voice",
    component: CreateVoicePrint,
  },
  listVoices: {
    path: "/voices/list",
    name: "List Voices",
    component: VoiceList,
  },
  ///voices/view/:id
  viewVoice: {
    path: "/voices/view/:id",
    name: "View Voice",
    component: CreateVoicePrint,
  },

  backoffice: {
    path: "/backoffice",
    name: "Backoffice",
    component: Backofffice,
  },
  checkout: {
    path: "/subscription",
    name: "Checkout",
    component: Checkout,
  },
  voiceSearch: {
    path: "/voices/search",
    name: "Search Voices",
    component: SearchPublicVoices,
  },
  responseWorkshop: {
    path: "/content/workshop/:id",
    name: "Search Voices",
    component: ResponseWorksop,
  },
  dasbhoard: {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  createVideoFromContext: {
    path: "/video/context/create",
    name: "Create Video From Context",
    component: CreateVideoFromContext,
  },
};

export default routes;
