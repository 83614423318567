import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { AppContext } from "../../UserApp";
import { Maybe, Subscription, UserSubscription } from "../../generated/graphql";
import {
  PencilIcon,
  XCircleIcon,
  CheckBadgeIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import {
  SuccessButtonStyle,
  GrayCancelButtonStyle,
} from "../../constants/CSSConstants";
import useInfoPopUps from "../../hooks/useInfoPopUps";
import useMakeBackendRequest from "../../hooks/useMakeBackendRequest";

// Are you sure you want to change your subscription from "pro" to "Growing Business"?

function formatNumberWithCommas(num: number): string {
  return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const redirectToCheckout = async (subscriptionId: string) => {
  let url = `${process.env.REACT_APP_BACKEND_HOST}/checkout/session`;
  const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
    const token = await user?.getIdToken();
    url = `${url}?authToken=${token}&subscriptionId=${subscriptionId}`;
    //   url = `${url}?authToken=${token}`;

    window.location.replace(url);
    // navigate(url, { state: { amount: 2000 }, replace: true });
  });
};

const ProductDisplay = () => {
  const appData = useContext(AppContext);
  const { get } = useMakeBackendRequest();
  const subscriptions: Subscription[] | undefined = appData?.subscriptions;
  //sort subscriptions by price
  subscriptions?.sort((a, b) => a.priceInCents - b.priceInCents);
  const [usageData, setUsageData] = useState<string>();

  const usage = get(`${process.env.REACT_APP_ML_ENGINE}/audio/usage`);
  usage.then((data) => {
    console.log("setUsageData", data);
    setUsageData(String(data.data.usage));
  });

  // console.log("usage", usage)

  const { displayConfirmation } = useInfoPopUps();

  if (!subscriptions) return null;
  const userSubscription: Maybe<UserSubscription> =
    appData.user.userSubscriptionsByUserId?.nodes?.[0];
  const currentSubscription: Maybe<Subscription> | undefined =
    userSubscription?.subscriptionBySubscriptionId;
  // if(currentSubscription){
  //   setSubscriptionData(currentSubscription)
  // }
  const updateSubscription = async (
    toSubscription: Subscription,
    currentUserSubscription: Maybe<UserSubscription>
  ) => {
    console.log(
      `currentSubscription?.stripeSubscriptionId ${JSON.stringify(
        currentUserSubscription
      )}`
    );
    if (currentUserSubscription?.stripeSubscriptionId) {
      displayConfirmation({
        message: `Are you sure you want to change your subscription from 
        ${currentSubscription?.name} 
        to "${toSubscription.name}"?`,
        handleConfirm: async () => {
          redirectToCheckout(toSubscription.id);

          return;
        },
      });
    } else {
      redirectToCheckout(toSubscription.id);
    }

    // return unsubscribe;
  };

  return (
    <div className="grid gap-12">
      <div className="flex justify-center text-2xl">Subscription</div>
      <div>
        <div className="grid justify-center text-lg">
          <div className="flex">
            <div className="w-48">Subscribed</div>
            <div>{currentSubscription?.name}</div>
          </div>
          <div className="flex">
            <div className=" w-48">Characters used</div>
            <div>
              {formatNumberWithCommas(Number(usageData || 0))} /{" "}
              {formatNumberWithCommas(
                currentSubscription?.textToSpeechCharactersAllowed || 0
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5">
        {subscriptions?.map((subscription) => {
          const asd: Maybe<Maybe<string>[]> | undefined = subscription.features;
          //filter nulls and maybes return type should be  string>[]
          let features: string[] = asd?.filter(
            (feature) => feature !== null && feature !== undefined
          ) as string[];
          const speechCharsPerMonth = formatNumberWithCommas(
            subscription.textToSpeechCharactersAllowed
          );
          const speechFeature = `Speech characters per month: ${speechCharsPerMonth}`;
          // `

          //  speech characters per month
          // `

          features = [speechFeature, ...features];

          console.log("features", features);

          return (
            <div
              className="p-3 grid gap-2 border-2 rounded-xl"
              key={subscription.id}
            >
              <div className="border-b-2 py-2">
                <div className=" flex justify-center text-2xl">
                  {subscription.name}
                </div>
                <div className=" text-xl">
                  <span className="text-3xl">
                    ${String(subscription.priceInCents / 100)}
                  </span>
                  / mo
                </div>
                <div>{subscription.description}</div>
              </div>

              {}
              <div className="my-2">
                <div>What you get:</div>
                <div className="my-5">
                  {features?.map((feature) => {
                    return (
                      <div className="flex text-sm">
                        <CheckIcon className="w-4 mr-3"></CheckIcon>
                        {feature}
                      </div>
                    );
                  })}
                </div>
              </div>

              <button
                disabled={subscription.id === currentSubscription?.id}
                className={
                  subscription.id === currentSubscription?.id
                    ? GrayCancelButtonStyle
                    : SuccessButtonStyle
                }
                onClick={() => {
                  updateSubscription(subscription, userSubscription);
                }}
                type="submit"
              >
                Checkout
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type MessageProps = {
  message: string;
};
const Message = (props: MessageProps) => (
  <section>
    <p>{props.message}</p>
  </section>
);

export default function Checkout() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? <Message message={message} /> : <ProductDisplay />;
}
