import { useContext } from "react";
import { AppContext } from "./../UserApp";
import { useState } from "react";
import useMakeBackendRequest from "./useMakeBackendRequest";
import {
  FILE_HOST,
  TEXT_TO_SPEECH_ENDPOINT,
  USER_RECORDINGS_UPLOAD_BUCKET,
} from "../constants/Constants";

type TextToSpeechProps = {
  text: string;
  voiceId: string;
  uploadPath: string;
  userToolSubmissionResponseId?: string;
  order: number;
};

type TextToSpeechResponse = {
  fileName: string;
  textToSpeechSubmissionId: string;
  audioFileURL: string;
};

const useTextToSpeech = () => {
  const appData = useContext(AppContext);

  const { post } = useMakeBackendRequest();

  const [audioURLs, setAudioURLs] = useState<any>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const textToSpeech = async (props: TextToSpeechProps) => {
    setProcessing(true);
    const uploadBucket = USER_RECORDINGS_UPLOAD_BUCKET;
    const uploadPath = props.uploadPath;
    const response = await post(TEXT_TO_SPEECH_ENDPOINT, {
      userId: appData?.user?.id,
      text: props.text,
      uploadBucket,
      uploadPath,
      userToolSubmissionResponseId: props.userToolSubmissionResponseId,
      voiceId: props.voiceId,
      order: props.order,
    });
    const audioFileURL = `${FILE_HOST}/${uploadBucket}/${uploadPath}/${response.data.fileName}`;

    setAudioURLs({
      ...audioURLs,
      [props.text]: audioFileURL,
    });

    const result: TextToSpeechResponse = {
      audioFileURL,
      fileName: response.data.fileName,
      textToSpeechSubmissionId: response.data.textToSpeechSubmissionId,
    };
    setProcessing(false);
    return result;
  };
  return { audioURLs, textToSpeech, processing };
};

export default useTextToSpeech;
