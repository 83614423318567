import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";
import LoginBannerURI from "../assets/login/login-banner.jpg";
// import IPageProps from '../interfaces/page.interface';
import { SignInWithSocialMedia } from "./";
import { Providers } from "../config/firebase";

const SignUpPage: React.FunctionComponent = () => {
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const params = new URLSearchParams(document.location.search);
  const redirectURL = params.get("redirect"); // bar

  const navigate = useNavigate();

  const signInWithSocialMedia = (provider: firebase.auth.AuthProvider) => {
    if (error !== "") setError("");

    setAuthenticating(true);

    SignInWithSocialMedia(provider)
      .then((result) => {
        navigate(redirectURL || "/");
      })
      .catch((error) => {
        setAuthenticating(false);
        setError(error.message);
      });
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="flex flex-col gap-40">
          <div className="text-4xl p-8">Clove</div>

          <div className="auth-btn-wrapper grid gap-10 justify-center">
            <div className="text-center text-3xl">Log In</div>

            <div
              className="p-4 rounded-xl cursor-pointer flex hover:underline hover:decoration-yellow-200"
              onClick={() =>
                !authenticating && signInWithSocialMedia(Providers.google)
              }
            >
              <img
                className="h-6 w-6"
                alt="google logo"
                src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
              ></img>
              <div className="text-xl ml-5">Sign Up with Google</div>
            </div>
          </div>

          <div>
            {/* checkbox for terms and conditions */}

            <div className="text-center text-sm">
              By signing up, you agree to our{" "}
              <span className="underline">
                <a href="https://cloveai.com/terms">Terms of Service</a>
              </span>{" "}
              and{" "}
              <span className="underline">
                <a href="https://cloveai.com/privacy">Privacy Policy</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block w-3/12">
        <img
          className="object-cover h-screen"
          alt="Login Banner"
          src={LoginBannerURI}
        ></img>
      </div>
    </div>
  );
};

export default SignUpPage;
