import {
  Query,
  TextToSpeechSubmission,
} from "../../../../../generated/graphql";
import { WhitelistQueries } from "../../../../../graphql/whitelistedQueries";
import { useAuthMutation } from "../../../../../hooks/useAuthApollo";
import { getQuery } from "../../../../../graphql/fetchQuery";

type UseFindVoiceIdProps = {
  textToSpeeches: Query | null;
  contentKeyVoiceId?: Map<string, string>;
};

type UseFindVoiceIdResult = (
  keyToSearch: string,
  objectToSearch: any
) => string;

const useFindVoiceId = (props: UseFindVoiceIdProps): UseFindVoiceIdResult => {
  const findVoiceIdByObject: UseFindVoiceIdResult = (
    keyToSearch,
    objectToSearch
  ) => {
    let voiceIdFound: string = "";
    if (props.contentKeyVoiceId?.has(keyToSearch)) {
      voiceIdFound = props.contentKeyVoiceId.get(keyToSearch)!;
      return voiceIdFound;
    }

    const findVoiceIdRecursive = (obj: any) => {
      const keys = Object.keys(obj);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = obj[key];
        if (typeof value === "string") {
          const speechesLength: number =
            props.textToSpeeches?.allTextToSpeechSubmissions?.nodes.length || 0;
          for (let j = 0; j < speechesLength; j++) {
            const textToSpeech =
              props.textToSpeeches?.allTextToSpeechSubmissions?.nodes[j];
            if (textToSpeech?.text === value && keyToSearch === key) {
              voiceIdFound = textToSpeech.voiceId;
              break;
            }
          }
        } else if (typeof value === "object") {
          findVoiceIdRecursive(value);
        }
      }
    };

    findVoiceIdRecursive(objectToSearch);

    return voiceIdFound;
  };

  return findVoiceIdByObject;
};

export default useFindVoiceId;
