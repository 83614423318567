import { gql } from "@apollo/client";
import axios from "axios";

let queries: { [key: string]: string } = {};

export const loadQueries = async () => {
  const queryText: any = await fetchQuery();
  // console.log(`queryText ${JSON.stringify(queryText)}`);
  queries = queryText;
};
export const getQuery = (queryName: string) => {
  if (!queries[queryName]) {
    throw new Error(`Query ${queryName} not found! ${JSON.stringify(queries)}`);
  }
  // console.log(`queries ${JSON.stringify(queries)}`);
  return gql`
    ${queries[queryName]}
  `;
};
const fetchQuery = async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_GRAPHQL_URL}/definitions`
  );

  return response.data.query;
};

export default fetchQuery;
