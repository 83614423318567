import React, { useCallback, useEffect, useState } from "react";
import "./App.css";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import UserApp from "./UserApp";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUpPage from "./auth/SignUpPage";
import AuthRoute from "./auth/AuthRouter";
import { Loading } from "./common/Loading";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import useGetGraphQLClient from "./graphql/client";
import TermsPopUp from "./auth/TermsPopUp";

function App() {
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(
    firebase.auth().currentUser
  );

  const [authToken, setAuthToken] = useState<string>();
  const [graphQLClient, setGraphQLClient] =
    useState<ApolloClient<NormalizedCacheObject>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getgrahphQLClient } = useGetGraphQLClient();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user?.uid) {
        const token: string = await user.getIdToken();
        setFirebaseUser(user);
        setAuthToken(token);
        const client = getgrahphQLClient(token);
        setGraphQLClient(client);
      }
      setIsLoading(false);
    });
    const checkUser = async () => {
      if (firebaseUser?.uid) {
        const token = await firebaseUser.getIdToken();
        const client = getgrahphQLClient(token);
        setGraphQLClient(client);
        setIsLoading(false);
      }
    };
    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser]);

  if (isLoading) {
    return (
      <div className="App">
        <Loading />
      </div>
    );
  }

  if (!firebaseUser || !graphQLClient || !authToken) {
    return (
      <Router>
        <Routes>
          <Route
            path="*"
            element={
              <AuthRoute waitForChildren>
                <SignUpPage />
              </AuthRoute>
            }
          />
          <Route path="/login" element={<SignUpPage />} />
        </Routes>
      </Router>
    );
  }

  return (
    <ApolloProvider client={graphQLClient}>
      <UserApp authToken={authToken} firebaseUser={firebaseUser} />
    </ApolloProvider>
  );
}

export default App;
