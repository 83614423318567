import { useEffect, useRef, useState } from "react";
import UploadImage from "./UploadImage";
import { Maybe, Query } from "../../../../../generated/graphql";
import { CREATE_VIDEO_ENDPOINT } from "../../../../../constants/Constants";
import useMakeBackendRequest from "../../../../../hooks/useMakeBackendRequest";
import { findTextSubmissionByText } from "./workshopUtils";
import { SuccessButtonStyle } from "../../../../../constants/CSSConstants";
import Spinner from "../../../../../common/Spinner";
import {
  SpeakerWaveIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import { GrayCancelButtonStyle } from "../../../../../constants/CSSConstants";
import { deleteOnboardingStatus } from "../../../../onboarding/getOnboardingStatus";
import { useAuthMutation } from "../../../../../hooks/useAuthApollo";
import { getQuery } from "../../../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../../../graphql/whitelistedQueries";

interface Conversation {
  [key: string]: string;
}

interface ConversationProps {
  id: string;
  conversation: Conversation[];
}

type WorkshopVideoCreatorProps = {
  submissionResponse: any;
  conversationURL?: Maybe<string> | undefined;
  textToSpeeches: Query;
  userToolSubmissionResponseId: string;
  refectTextToSpeeches: () => Promise<any>;
  userToolSubmissionResponseVideoUrl?: Maybe<string> | undefined;
  highLightAction: boolean;
  userId: string;
};
const WorkshopVideoCreator = (videoCreatorprops: WorkshopVideoCreatorProps) => {
  const { post } = useMakeBackendRequest();
  const [showVideoCreator, setShowVideoCreator] = useState(false);

  const createVideoButtonRef = useRef<HTMLDivElement>(null);

  const scrollToVideoActions = () => {
    createVideoButtonRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateOnboardingQuery = getQuery(WhitelistQueries.UpdateUserOnboarding);
  const [updateOnboardingMutation] = useAuthMutation({
    mutation: updateOnboardingQuery,
  });

  const [videoURL, setVideoURL] = useState<Maybe<string> | undefined>(
    videoCreatorprops.userToolSubmissionResponseVideoUrl
  );
  const [isCreatingVideo, setIsCreatingVideo] = useState<boolean>(false);
  const [imagesForParticipant, setImagesForParticipant] = useState<
    Map<string, string[]>
  >(new Map());

  useEffect(() => {
    console.log(
      `showVideoCreator ${showVideoCreator} ${videoCreatorprops.highLightAction}`
    );
    if (showVideoCreator && videoCreatorprops.highLightAction) {
      scrollToVideoActions();
    }
  }, [
    showVideoCreator,
    videoCreatorprops.highLightAction,
    createVideoButtonRef,
  ]);

  const getParticipants = (props: ConversationProps) => {
    if (props.conversation) {
      const keys = props.conversation.map((item) => {
        return Object.keys(item);
      });
      console.log(`getParticipants keys ${JSON.stringify(keys, null, 2)}}`);
      const participants = keys.flat();
      console.log(
        `getParticipants participants ${JSON.stringify(participants, null, 2)}}`
      );
      // make participants unique
      const uniqueParticipants = new Set(participants);

      //setImagesForParticipant

      uniqueParticipants.forEach((participant) => {
        if (!imagesForParticipant.get(participant)) {
          setImagesForParticipant((prev) => {
            return new Map(prev.set(participant, []));
          });
        }
      });

      return Array.from(uniqueParticipants);
    }

    return [];
  };

  const renderParticipantImages = (participant: string) => {
    return (
      <div>
        <div>
          <div className="grid justify-center text-center">
            <div>Choose Image(s) for: </div>
            <div className="text-xl  my-2">{participant}</div>
            <UploadImage
              onUploadComplete={(images) => {
                setImagesForParticipant((prev) => {
                  const newImages = prev.get(participant) || [];
                  return new Map(
                    prev.set(participant, [...newImages, ...images])
                  );
                });
              }}
            ></UploadImage>
          </div>
        </div>
      </div>
    );
  };

  type MovieImage = {
    duration: string;
    imageURLs: string[];
  };
  type MovieConfig = {
    movie: {
      conversationURL: Maybe<string> | undefined;
      images: MovieImage[];
    };
  };
  const createMovieConfig = () => {
    console.log(
      "props.submissionResponse.conversation",
      JSON.stringify(videoCreatorprops.submissionResponse.conversation, null, 2)
    );

    const movieConfig: MovieConfig = {
      movie: {
        conversationURL: videoCreatorprops.conversationURL,
        images: [],
      },
    };

    let currentTimeMS = 0;

    if (
      !videoCreatorprops.submissionResponse.conversation ||
      videoCreatorprops.submissionResponse.conversation.length === 0
    ) {
      console.log(
        `props.submissionResponse.conversation is empty ${JSON.stringify(
          videoCreatorprops.submissionResponse.conversation,
          null,
          2
        )}`
      );
    }

    (videoCreatorprops.submissionResponse.conversation || []).forEach(
      (item: any) => {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          if (key !== "id") {
            const text = item[key];
            const textToSpeechSubmission = findTextSubmissionByText(
              videoCreatorprops.textToSpeeches,
              text
            );
            if (textToSpeechSubmission) {
              const durationMS = textToSpeechSubmission.durationMiliseconds;
              //currentTimeMS to string format 00:00:00:000
              const currentTime = new Date(currentTimeMS)
                .toISOString()
                .substr(11, 12);
              currentTimeMS += durationMS;
              const endTime = new Date(currentTimeMS)
                .toISOString()
                .substr(11, 12);
              const imageURL = imagesForParticipant.get(key) || [];
              // get fileName from imageURL
              // const fileName = imageURL.split("/").pop()!;
              movieConfig.movie.images.push({
                duration: `${currentTime}-${endTime}`,
                imageURLs: imageURL,
                // fileName,
              });
            }
          }
        });
      }
    );
    return movieConfig;
  };

  return (
    <div>
      <div
        onClick={() =>
          setShowVideoCreator((prev) => {
            return !prev;
          })
        }
        className={`flex bg-slate-200 cursor-pointer mb-5 ${
          videoCreatorprops.highLightAction ? "bg-pulse-gradient" : ""
        }`}
      >
        {showVideoCreator ? (
          <ChevronDoubleDownIcon className="w-7 h-7 mx-10 my-2"></ChevronDoubleDownIcon>
        ) : (
          <ChevronDoubleUpIcon className="w-7 h-7 mx-10 my-2"></ChevronDoubleUpIcon>
        )}
        <VideoCameraIcon className="w-7 h-7 mx-10 my-2"></VideoCameraIcon>

        <div className="py-3"> Video Creation</div>
      </div>

      {/*  */}
      <div className={`${showVideoCreator ? "" : "hidden"}`}>
        <div className="grid md:grid-cols-2">
          {getParticipants(videoCreatorprops.submissionResponse).map(
            (participant) => {
              return (
                <div className="my-10">
                  {renderParticipantImages(participant)}
                </div>
              );
            }
          )}
        </div>
        <div>
          {videoURL && (
            <video id="video" playsInline controls src={videoURL}></video>
          )}
        </div>
        <div className="">
          {isCreatingVideo ? (
            <Spinner></Spinner>
          ) : (
            <div
              ref={createVideoButtonRef}
              className="grid gap-4 justify-center"
            >
              <button
                disabled={!videoCreatorprops.conversationURL}
                onClick={async () => {
                  if (!videoCreatorprops.conversationURL) {
                    return;
                  }
                  setIsCreatingVideo(true);

                  await videoCreatorprops.refectTextToSpeeches();

                  //imagesForParticipant if imagesForParticipant is empty for any participant, then don't create video
                  let isImagesForParticipantEmpty = false;

                  imagesForParticipant.forEach((value, key) => {
                    if (value.length === 0) {
                      isImagesForParticipantEmpty = true;
                    }
                  });
                  if (isImagesForParticipantEmpty) {
                    alert("Please upload images for all participants");
                    setIsCreatingVideo(false);

                    return;
                  }

                  const videoConfig = createMovieConfig();

                  const createVideoResponse = await post(
                    CREATE_VIDEO_ENDPOINT,
                    {
                      videoConfig: videoConfig,
                      userToolSubmissionResponseId:
                        videoCreatorprops.userToolSubmissionResponseId,
                    }
                  );

                  if (createVideoResponse.data.videoURL) {
                    setVideoURL(createVideoResponse.data.videoURL);
                  }

                  deleteOnboardingStatus();

                  updateOnboardingMutation({
                    variables: {
                      id: videoCreatorprops.userId,
                      onboarded: true,
                    },
                  });

                  setIsCreatingVideo(false);

                  console.log(
                    `movieconfig: ${JSON.stringify(videoConfig, null, 2)}`
                  );
                }}
                className={`${
                  !videoCreatorprops.conversationURL
                    ? GrayCancelButtonStyle
                    : SuccessButtonStyle
                }`}
              >
                Create Video
              </button>
              <div>
                {!videoCreatorprops.conversationURL && (
                  <div className="text-gray-500">
                    Please create audio before creating video
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkshopVideoCreator;
