import { useState } from "react";
import { Query } from "../../../generated/graphql";
import { getQuery } from "../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../graphql/whitelistedQueries";
import { useAuthQuery } from "../../../hooks/useAuthApollo";
import { cleanToolResponse } from "./toolResponseCleanup";

type Submission = {
  response: any;
  liked: boolean | undefined;
};
export type ToolUserHistoryProps = {
  userId: string;
  toolId: string;
  submissions?: Submission[];
};

const ToolUserHistory = (props: ToolUserHistoryProps) => {
  const allUserToolSubmissionsQuery = getQuery(
    WhitelistQueries.AllUserToolSubmissions
  );
  const { loading, error, data } = useAuthQuery<Query>(
    {
      query: allUserToolSubmissionsQuery,
    },
    {
      variables: {
        userId: props.userId,
        toolId: props.toolId,
      },
    }
  );

  function recursiveStringify(obj: any): string {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map(recursiveStringify).join(", ");
      } else {
        return Object.entries(obj)
          .map(([key, value]) => `${key}: ${recursiveStringify(value)}`)
          .join(", ");
      }
    } else {
      return String(obj);
    }
  }

  const [onlyShowLiked, setOnlyShowLiked] = useState(false);

  const submissions:
    | (
        | {
            response: any;
            liked: boolean | undefined;
          }
        | undefined
      )[]
    | undefined = data?.allUserToolSubmissions?.edges
    .map((edge) =>
      edge.node?.userToolSubmissionResponsesByUserToolSubmissionId.nodes.map(
        (node) => {
          return {
            response: node?.response,
            liked: node?.liked,
          };
        }
      )
    )
    .flat()
    .concat(props.submissions || []);

  return (
    <div className="w-80 hidden lg:block">
      <div className="text-lg flex justify-center"> History</div>
      <div className="flex justify-between">
        <div
          className={`pl-5 cursor-pointer ${
            onlyShowLiked ? " underline font-extrabold" : ""
          }`}
          onClick={() => {
            setOnlyShowLiked(true);
          }}
        >
          Liked
        </div>
        <div
          className={`pr-10  cursor-pointer ${
            !onlyShowLiked ? " underline font-extrabold" : ""
          }`}
          onClick={() => {
            setOnlyShowLiked(false);
          }}
        >
          All
        </div>
      </div>
      <div className="text-sm">
        {submissions?.map((submission, index) => {
          const JSONSubmission = JSON.parse(submission?.response);
          const cleanResponse = cleanToolResponse(JSONSubmission);
          const keys = Object.keys(cleanResponse);
          if (onlyShowLiked && !submission?.liked) return <div></div>;

          return (
            <div key={index} className="border-b-2 mt-2">
              {keys.map((key, index) => {
                return (
                  <div key={index} className="mb-2">
                    <span className=" font-bold mr-2">{key}:</span>
                    <span>{recursiveStringify(JSONSubmission[key])}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ToolUserHistory;
