export const WhitelistQueries = {
  AllTools: `AllTools`,
  AllUsers: `AllUsers`,
  CreateToolTag: `CreateToolTag`,
  AllToolTags: `AllToolTags`,
  AllUserToolSubmissions: `AllUserToolSubmissions`,
  UpdateUserToolSubmissionResponseById: `UpdateUserToolSubmissionResponseById`,
  CreateUser: `CreateUser`,
  DeleteUserToolSubmissionResponseById: `DeleteUserToolSubmissionResponseById`,
  CreateVoice: `CreateVoice`,
  UpdateVoiceById: `UpdateVoiceById`,
  DeleteVoiceById: `DeleteVoiceById`,
  AllSubscriptions: `AllSubscriptions`,
  SetPublicVoiceMutation: `SetPublicVoiceMutation`,
  AllPublicVoices: `AllPublicVoices`,
  CreateUserAddedVoice: `CreateUserAddedVoice`,
  DeleteUserAddedVoiceById: `DeleteUserAddedVoiceById`,
  UserToolSubmissionResponseById: `UserToolSubmissionResponseById`,
  FindTextToSpeechSubmissionsByUserToolSubmissionResponseId: `FindTextToSpeechSubmissionsByUserToolSubmissionResponseId`,
  DeleteTextToSpeechSubmissionById: `DeleteTextToSpeechSubmissionById`,
  UpdateContentUserToolSubmissionResponseById: `UpdateContentUserToolSubmissionResponseById`,
  TextToSpeechSubmissionById: `TextToSpeechSubmissionById`,
  UpdateUserOnboarding: `UpdateUserOnboarding`,
};
