import React, { useEffect, useState } from "react";
import useMakeBackendRequest from "../../../../../hooks/useMakeBackendRequest";
import { UPLOAD_ENDPOINT } from "../../../../../constants/Constants";
import { addRandomStringToFile } from "../../../../../utils/StringUtils";

type UploadImageProps = {
  uploadButtonMessage?: string;
  onUploadComplete?: (uploadedFileURLs: string[]) => void;
};

type UploadedFile = {
  url: string;
  name: string;
  uploadNotified: boolean;
};
const UploadImage = (props: UploadImageProps) => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [previewImages, setPreviewImages] = useState<
    { url: string; uploading: boolean; uploaded: boolean; fileName: string }[]
  >([]);
  const [uploading, setUploading] = useState(false);
  // const [uploadedFileURLs, setUploadedFileURLs] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const { post } = useMakeBackendRequest();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    // if either of the files not jpg or png alert and return
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (!file.type.includes("image")) {
        alert("Only images are allowed");
        return;
      }
      //only allow jpg and png by name
      if (
        !file.name.includes(".jpg") &&
        !file.name.includes(".png") &&
        !file.name.includes(".jpeg") &&
        !file.name.includes(".webp")
      ) {
        alert("Only jpg, webp and  png are allowed");
        return;
      }
      // max 5 images
      if (event.target.files.length > 5) {
        alert("Only 5 images are allowed");
        return;
      }
    }

    const files = event.target.files;
    setSelectedFiles(files);

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files[i];

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const images = [
          {
            url: reader.result as string,
            uploading: false,
            uploaded: false,
            fileName: file.name,
          },
        ];
        setPreviewImages((prev) => [...prev, ...images]);
      };
    }
  };

  useEffect(() => {
    if (previewImages) {
      //check if there are images  in previewImages that are still not uploaded
      const notUploadedImages = previewImages.filter(
        (image) => !image.uploading && !image.uploaded
      );
      if (notUploadedImages.length > 0) {
        console.log("uploading images");
        uploadImages();
      }
    }
  }, [previewImages]);

  // props.onUploadComplete && props.onUploadComplete(uploadedFileURLs);
  const onUploadComplete = props.onUploadComplete;

  useEffect(() => {
    // get all not notified files from uploadedFiles
    const notNotifiedFiles = uploadedFiles.filter(
      (file) => !file.uploadNotified
    );
    if (notNotifiedFiles.length > 0) {
      // set as notified true
      setUploadedFiles((prev) =>
        prev.map((file) => {
          if (!file.uploadNotified) {
            return { ...file, uploadNotified: true };
          }
          return file;
        })
      );
      onUploadComplete &&
        onUploadComplete(notNotifiedFiles.map((file) => file.url));
    }
  }, [onUploadComplete, uploadedFiles]);

  const uploadImages = async () => {
    if (!selectedFiles) return;

    setUploading(true);

    //setPreviewImages upladign for all images
    setPreviewImages((prev) =>
      prev.map((image) => ({
        url: image.url,
        uploading: true,
        uploaded: false,
        fileName: image.fileName,
      }))
    );
    for (let i = 0; i < selectedFiles.length; i++) {
      const formData = new FormData();
      const file = selectedFiles[i];
      formData.append("files", file);
      const formFileName = file.name.replace(/\s/g, "_");
      const uniqueFileName = addRandomStringToFile(formFileName);
      formData.append("fileName", uniqueFileName);
      // check if not uploaded  already in uploadedFiles
      const alreadyUploaded = uploadedFiles.find(
        (uploadedFile) => uploadedFile.name === file.name
      );
      if (alreadyUploaded) {
        console.log(`already uploaded ${file.name}`);
        continue;
      }

      try {
        const response = await post(UPLOAD_ENDPOINT, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(response.data);
        const fileURL = response.data.data.fileURL;
        console.log(`fileURL = ${fileURL}`);
        // setUploadedFileURLs((prev) => [...prev, fileURL]);
        setUploadedFiles((prev) => [
          ...prev,
          { url: fileURL, name: file.name, uploadNotified: false },
        ]);

        setPreviewImages((prev) =>
          prev.map((image, index) =>
            image.fileName === file.name
              ? {
                  url: image.url,
                  uploading: false,
                  uploaded: true,
                  fileName: image.fileName,
                }
              : image
          )
        );
      } catch (error) {
        console.error(error);
        setPreviewImages((prev) =>
          prev.map((image, index) =>
            image.fileName === file.name
              ? {
                  url: image.url,
                  uploading: false,
                  uploaded: true,
                  fileName: image.fileName,
                }
              : image
          )
        );
      }
    }

    setUploading(false);
  };
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <div>
      <div>{/* {JSON.stringify(previewImages)} */}</div>
      <form onSubmit={handleFormSubmit}>
        <div className="grid gap-3">
          <div>
            <input
              type="file"
              // only allow png, jpgs
              accept="image/png, image/jpeg, image/jpg, image/webp"
              multiple
              onChange={handleFileSelect}
            />
          </div>
          <div className="">
            {previewImages.map((image, index) => (
              <div key={index} className="relative inline-block w-40">
                <img src={image.url} alt={`Preview ${index}`} />
                {image.uploading &&
                  !uploadedFiles.find(
                    (uploadedFile) => uploadedFile.name === image.fileName
                  ) && (
                    <div className="absolute right-0 bottom-0 bg-gray-700 text-white font-bold text-xs p-1">
                      Uploading...
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadImage;
