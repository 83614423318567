import { AppContext } from "./../UserApp";
import { useContext } from "react";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useEffect, useState } from "react";

const useMakeBackendRequest = () => {
  const appData = useContext(AppContext);
  const [firebaseToken, setFirebaseToken] = useState<string>();
  const [firebaseUser, setFirebaseUser] = useState<firebase.User>();

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (user: firebase.User | null) => {
        console.log(
          "firebaseUser.uid useMakeBackendRequest",
          JSON.stringify(user?.uid)
        );
        if (user?.uid) {
          setFirebaseUser(user);
        }
      });

    return unsubscribe;
  }, []);

  const post = async (url: string, data: any, headers: any = {}) => {
    const firebaseToken =
      (await firebaseUser?.getIdToken()) || appData.authToken;

    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${firebaseToken}`,
        ...headers,
      },
    });
    return response;
  };

  const get = async (url: string) => {
    const firebaseToken =
      (await firebaseUser?.getIdToken()) || appData.authToken;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${firebaseToken}`,
      },
    });
    return response;
  };

  return { post, get };
};
export default useMakeBackendRequest;
