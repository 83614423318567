import { useContext } from "react";
import { AppContext } from "../../UserApp";
import { PencilIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import useInfoPopUps from "../../hooks/useInfoPopUps";
import { WhitelistQueries } from "../../graphql/whitelistedQueries";
import { getQuery } from "../../graphql/fetchQuery";
import { useAuthMutation } from "../../hooks/useAuthApollo";
import { Maybe, UserAddedVoice, Voice } from "../../generated/graphql";

const VoiceList = () => {
  const app = useContext(AppContext);
  const navigate = useNavigate();
  const { displayConfirmation } = useInfoPopUps();
  const whitelistedDeleteQuery = WhitelistQueries.DeleteVoiceById;
  const deleteVoiceByIdQuery = getQuery(whitelistedDeleteQuery);
  const [deleteVoiceByIdMutation] = useAuthMutation({
    mutation: deleteVoiceByIdQuery,
  });

  const DeleteUserAddedVoiceByIdQuery = getQuery(
    WhitelistQueries.DeleteUserAddedVoiceById
  );
  const [deleteUserAddedVoiceByIdMutation] = useAuthMutation({
    mutation: DeleteUserAddedVoiceByIdQuery,
  });

  const renderVoice = (voice: Voice, voiceAddedId: string | null) => {
    const removeVoice = () => {
      displayConfirmation({
        message: `Are you sure you want to delete this voice?`,
        handleConfirm: async () => {
          if (!voiceAddedId) {
            await deleteVoiceByIdMutation({
              variables: {
                id: voice?.id,
              },
              context: {
                headers: {
                  operationName: String(whitelistedDeleteQuery),
                },
              },
            });
          } else {
            await deleteUserAddedVoiceByIdMutation({
              variables: {
                id: voiceAddedId,
              },
            });
          }

          app.refreshData();
        },
      });
    };
    return (
      <>
        <div>
          {
            //empty div
          }
        </div>
        <div className="mt-3 vertical-center">{voice?.name}</div>
        <div className="">
          <audio src={voice?.demoUrl!} controls />
        </div>
        <div
          className="flex justify-center"
          onClick={() => {
            navigate(`/voices/view/${voice?.id}`);
          }}
        >
          <PencilIcon className="h-5 w-5 text-gray-400 cursor-pointer"></PencilIcon>
        </div>
        <div>
          <XCircleIcon
            onClick={() => {
              removeVoice();
            }}
            className="h-5 w-5 text-gray-400 cursor-pointer"
          ></XCircleIcon>
        </div>
      </>
    );
  };

  // remove null values from array
  const addedVoices: UserAddedVoice[] =
    app.user.userAddedVoicesByUserId?.nodes?.filter(
      (voice) => voice !== null
    ) as UserAddedVoice[];

  return (
    <div className="mx-auto">
      <div className="flex justify-center mb-10">Voice List</div>
      <div className="grid grid-cols-5 gap-y-4">
        {app.user.voicesByUserId.nodes.map((voice) => {
          return renderVoice(voice!, null);
        })}
      </div>

      <div className="flex justify-center my-10">Added voices</div>
      <div className="grid grid-cols-5 gap-y-4">
        {addedVoices?.map((addedVoice) => {
          return renderVoice(addedVoice?.voiceByVoiceId!, addedVoice.id);
        })}
      </div>
    </div>
  );
};

export default VoiceList;
