const cleanToolResponse = (toolResponse: any) => {
  const toolResponseCopy = JSON.parse(JSON.stringify(toolResponse));
  const keys = Object.keys(toolResponseCopy);

  delete toolResponseCopy["id"];

  keys.forEach((key) => {
    if (String(toolResponseCopy[key]).includes("http")) {
      delete toolResponseCopy[key];
    }
  });
  return toolResponseCopy;
};

const cleanResponseKey = (key: string) => {
  if (
    typeof key === "string" &&
    key.toLowerCase() !== "conversation" &&
    isNaN(Number(key))
  ) {
    return `${key}:`;
  }
  return "";
};
export { cleanToolResponse, cleanResponseKey };
