import { cleanResponseKey, cleanToolResponse } from "../toolResponseCleanup";
import ToolResponseRow from "./ToolResponseRow";
const renderResponseKey = (key: string) => {
  return (
    <span className="capitalize font-extrabold mr-1">
      {cleanResponseKey(key)}
    </span>
  );
};

type ToolResponseprops = {
  toolResponse: any;
  rowCss?: string;
  editRowOnClick?: boolean;
  setTextToCopy?: (text: string) => void;
  renderRowActions?: (
    key: string,
    rowContent: string,
    idx: number
  ) => JSX.Element;
  onEditBlur?: (
    idx: number,
    key: string,
    value: string,
    initialContent: string
  ) => void;
  //editAllOnClick coming soon
};

const ToolSubmissionResponse = (props: ToolResponseprops) => {
  const { toolResponse } = props;

  const cleanResponse = cleanToolResponse(toolResponse);
  const keys: string[] = Object.keys(cleanResponse);

  let tmpresponseTextToCopy = ``;
  const renderNestedObject = (obj: any, idx: number) => {
    return Object.keys(obj).map((nestedKey, nestedIdx) => {
      const compositeIdx = idx + nestedIdx;
      return (
        //create random string

        <div className="w-full">
          {renderResponseKey(nestedKey)}
          {typeof obj[nestedKey] === "object"
            ? renderNestedObject(obj[nestedKey], compositeIdx)
            : renderResponseContent(
                nestedKey,
                String(obj[nestedKey]),
                compositeIdx
              )}
        </div>
      );
    });
  };

  const renderResponseContent = (key: string, content: string, idx: number) => {
    if (key === undefined) {
      throw new Error("key is undefined");
    }

    return (
      <div className="my-50 w-full">
        <ToolResponseRow
          contentKey={key}
          idx={idx}
          onEditBlur={props.onEditBlur}
          editRowOnClick={props.editRowOnClick}
          content={content}
          renderRowActions={props.renderRowActions}
        ></ToolResponseRow>
      </div>
    );
  };

  const result = keys.map((key, idx) => {
    tmpresponseTextToCopy += `${key}: ${toolResponse[key]}\n`;
    const isObject = typeof toolResponse[key] === "object";
    return (
      <div>
        <div className={`m-2 p-3 ${props.rowCss || ""}`}>
          {renderResponseKey(key)}
          {isObject
            ? renderNestedObject(toolResponse[key], idx)
            : renderResponseContent(key, toolResponse[key], idx)}
        </div>
      </div>
    );
  });
  if (props.setTextToCopy) {
    props.setTextToCopy(tmpresponseTextToCopy);
  }
  return <div className="">{result}</div>;
};

export default ToolSubmissionResponse;
