export const CENTER_PAGE_MARGINS = "ml-72 mr-56 grid gap-4";

export enum ToolTypes {
  TEXT_GENERATIVE = "TEXT_GENERATIVE",
  AUDIO_GENERATIVE = "AUDIO_GENERATIVE",
}

export const DefaultVoices = {
  Scott: {
    name: "Scott",
    voiceId: "pNInz6obpgDQGcFmaJgB",
    demoURL:
      "https://storage.googleapis.com/public-cloveai/scott-voice-demo.mp3",
  },
  Emma: {
    name: "Emma",
    voiceId: "EXAVITQu4vr4xnSDxMaL",
    demoURL:
      "https://storage.googleapis.com/public-cloveai/emma-voice-demo.mp3",
  },
  Hunter: {
    name: "Hunter",
    voiceId: "yoZ06aMxZJJ28mfd3POQ",
    demoURL:
      "https://storage.googleapis.com/public-cloveai/hunter-voice-demo.mp3",
  },
};

export const OnboardingStatusLocalStorageKey = "onboardingStatus";

export const CONVERSATION_TOOL_NAME = "Conversation generator";

export const AttentionInterestDesireAction_TOOL_NAME =
  "Attention, Interest, Desire, Action";

export const SEARCH_PUBLIC_VOICE = "SearchPublicVoice";

export const ADD_PUBLIC_VOICE = "AddPublicVoice";

export const ONBOARDING_CREATE_VIDEO_SUCCESS_TRIGGER = "OnboardingVideoCreated";
export const ONBOARDING_CREATE_AUDIO__SUCCESS_TRIGGER =
  "OnboardingAudioCreated";

export const ONBOARDING_CREATE_TEXT_SUCCESS_TRIGGER = "OnboardingTextCreated";

export const LIST_FILES_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/files/list`;
export const DELETE_FILES_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/files/delete`;
export const DELETE_ALL_EXTERNAL_VOICES_ENDPOINT = `${process.env.REACT_APP_BACKEND_HOST}/backoffice/freeVoices`;

export const UPDATE_CONVERSATION_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/audio/conversation/update`;
export const UPLOAD_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/audio/upload`;
export const CREATE_VIDEO_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/video/create`;

export const CONTINUE_RESPONSE_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/response/continue`;

export const CREATE_VIDEO_SUMMARY_FROM_CONTEXT_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/video/context/create`;

export const FILE_HOST = `https://storage.googleapis.com`;

export const USER_RECORDINGS_UPLOAD_BUCKET = "clove-ai-user-recordings";

export const TEXT_TO_SPEECH_ENDPOINT = `${process.env.REACT_APP_ML_ENGINE}/audio/textToSpeech`;

export const SUBITLE_BASE64_ENDPOINT = (userId: string, videoName: string) => {
  return `${process.env.REACT_APP_ML_ENGINE}/video/subtitles/${userId}/${videoName}`;
};
