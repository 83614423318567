import { useState, useRef } from "react";

interface AudioPlayerProps {
  src: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  //   const audioRef = useRef<HTMLAudioElement>(null);
  //   const [isPlaying, setIsPlaying] = useState(false);

  //   const togglePlay = () => {
  //     if (audioRef.current) {
  //       if (isPlaying) {
  //         audioRef.current.pause();
  //       } else {
  //         audioRef.current.play();
  //       }
  //       setIsPlaying(!isPlaying);
  //     }
  //   };

  //   return(      <audio ref={audioRef} src={src} controls />
  //   )
  return (
    <div className="w-full bg-gray-100 p-4 rounded-lg shadow-md">
      <audio
        //ref={audioRef}
        src={src}
        controls
      />
      {/* <button className="h-12 w-12 p-2" onClick={togglePlay}>
        {isPlaying ? (
            <div>
                stop
            </div>
        ) : (
            <div>
                play
            </div>
        )}
      </button> */}
    </div>
  );
};

export default AudioPlayer;
