const Footer = () => {
  return (
    <div className=" mt-10">
      <div className=" p-4  rounded-lg  md:flex md:items-center md:justify-between md:p-6 ">
        <span className="text-sm text-gray-50 sm:text-center dark:text-gray-400 pl-40">
          ©{`${new Date().getFullYear()} `}
          <a href="/" className="hover:underline">
            Clove™
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">
              Licensing
            </a>
          </li>
          <li>
            <a href="/" className="hover:underline">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
