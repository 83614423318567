import {
  ClipboardDocumentIcon,
  HeartIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../UserApp";
import { ToolTypes } from "../../../constants/Constants";
import { getQuery } from "../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../graphql/whitelistedQueries";
import ToolResponseAudio from "./ToolResponseAudio";
import { cleanResponseKey, cleanToolResponse } from "./toolResponseCleanup";
import { useAuthMutation } from "../../../hooks/useAuthApollo";
import useMakeBackendRequest from "../../../hooks/useMakeBackendRequest";

type ToolResponseActionsProps = {
  setMoreLikeResponse: React.Dispatch<React.SetStateAction<JSON | undefined>>;
  createContent: (moreLike: JSON | undefined) => Promise<void>;
  setLikedResponses: React.Dispatch<React.SetStateAction<string[]>>;
  likedResponses: string[];
  responseId: string;
  setDeletedResponseIds: React.Dispatch<React.SetStateAction<string[]>>;
  toolResponse: any;
  idx: number;
  responseTextToCopy: string[];
};

const ToolResponseActions = (props: ToolResponseActionsProps) => {
  const UPDATE_LIKED_RESPONSE_BY_ID = getQuery(
    WhitelistQueries.UpdateUserToolSubmissionResponseById
  );
  const [updateLikeResponseByIdMutation] = useAuthMutation({
    mutation: UPDATE_LIKED_RESPONSE_BY_ID,
  });

  const DELETE_RESPONSE_BY_IDQuery = getQuery(
    WhitelistQueries.DeleteUserToolSubmissionResponseById
  );
  const [deleteResponseByIdMutation] = useAuthMutation({
    mutation: DELETE_RESPONSE_BY_IDQuery,
  });

  const responseId = props.responseId;
  return (
    <div>
      <div className="flex gap-2 my-2 justify-evenly flex-wrap">
        <div
          className="flex cursor-pointer"
          onClick={() => {
            const liked = !props.likedResponses.includes(responseId);
            if (liked) {
              props.setLikedResponses((oldArray) => [...oldArray, responseId]);
            } else {
              props.setLikedResponses(
                props.likedResponses.filter((id) => id !== responseId)
              );
            }
            updateLikeResponseByIdMutation({
              variables: {
                id: responseId,
                liked,
              },
            });
          }}
        >
          <HeartIcon
            className={` ${
              props.likedResponses.includes(responseId) ? "text-blue-500" : ""
            } h-6 w-6   mr-2`}
          />{" "}
          Save
        </div>
        <div
          className="flex cursor-pointer"
          onClick={() =>
            navigator.clipboard.writeText(props.responseTextToCopy[props.idx])
          }
        >
          <ClipboardDocumentIcon className="h-6 w-6  mr-2" /> Copy
        </div>
        <div
          className="flex cursor-pointer"
          onClick={() => {
            props.setDeletedResponseIds((oldArray) => [
              ...oldArray,
              responseId,
            ]);
            deleteResponseByIdMutation({
              variables: {
                id: responseId,
                deleted: true,
              },
            });
          }}
        >
          <TrashIcon className="h-6 w-6  mr-2" /> Delete
        </div>
        <div
          className="flex cursor-pointer"
          onClick={() => {
            props.setMoreLikeResponse(props.toolResponse);
            props.createContent(props.toolResponse);
          }}
        >
          <MagnifyingGlassIcon className="h-6 w-6  mr-2" /> Show Similar
        </div>
      </div>
    </div>
  );
};
export default ToolResponseActions;
