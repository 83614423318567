export const SuccessButtonStyle = `bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`;
export const SuccessButtonStyleDisabled = `bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed`;

export const CancelTextButtonStyle = `text-gray font-bold py-2 px-4 rounded`;

export const ItemsCenterStyle = `flex justify-center items-center`;

export const CancelButtonStyle = `bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded`;

export const GrayCancelButtonStyle = `bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded`;

export const LightRedCancelButtonStyle = `bg-red-200 hover:bg-red-300 text-white font-bold py-2 px-4 rounded`;
