import { render } from "@testing-library/react";
import { toast } from "react-toastify";

type ConfirmPopUpProps = {
  message?: string;
  handleConfirm: () => void;
};

const useInfoPopUps = () => {
  console.log("useInfoPopUps");

  function handleCancel() {
    toast.dismiss();
    // handle cancellation logic here
  }

  function displayConfirmation(props: ConfirmPopUpProps) {
    toast.info(
      <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg">
        <span className="text-gray-800">
          {props.message || "Are you sure you want to continue?"}
        </span>
        <div className="mt-4 space-x-2">
          <button
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-400"
            onClick={() => {
              props.handleConfirm();
              toast.dismiss();
            }}
          >
            Yes
          </button>
          <button
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-400"
            onClick={handleCancel}
          >
            No
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        hideProgressBar: true,
        className: "confirmation-toast",
        bodyClassName: "confirmation-toast-body",
        // overlayClassName: 'confirmation-overlay'
      }
    );
  }

  return {
    displayConfirmation,
  };
};
export default useInfoPopUps;
