import {
  Query,
  TextToSpeechSubmission,
} from "../../../../../generated/graphql";
import { getQuery } from "../../../../../graphql/fetchQuery";
import { WhitelistQueries } from "../../../../../graphql/whitelistedQueries";
import { useAuthMutation } from "../../../../../hooks/useAuthApollo";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import useTextToSpeech from "../../../../../hooks/useTextToSpeech";
import Spinner from "../../../../../common/Spinner";
import useFindVoiceId from "./UseFindVoiceIdResult";
import useWorkshopTextToSpeech from "./useWorkshopTextToSpeech";
import { AppData } from "../../../../../hooks/useLoadAppData";
import { useContext } from "react";
import { getUserAvailableVoices } from "../../../../../utils/responseAudioUtils";
import { AppContext } from "../../../../../UserApp";

export type RegenerateSentenceAudioProps = {
  textToSpeechIdxHistory: Map<number, TextToSpeechSubmission>;
  setTextToSpeechIdxHistory: (prev: any) => any;
  // refetchSubmissionResponse: () => void;

  refetchTextToSpeeches: () => Promise<any>;
  content: string;
  idx: number;
  userToolSubmissionResponseId: string;
  contentKey: string;
  submissionResponse: any;
  textToSpeeches: Query;
  //map
  contentKeyVoiceId: Map<string, string>;
  setContentKeyVoiceId: (prev: any) => any;
  highlightSelecteVoice: boolean;

  // contentKeyVoiceId, setContentKeyVoiceId
};
const RegenerateSentenceAudio = (props: RegenerateSentenceAudioProps) => {
  const appData: AppData = useContext(AppContext);

  const userAvailableVoices = getUserAvailableVoices(appData);

  const { generateTextToSpeech, processing } = useWorkshopTextToSpeech(
    props.textToSpeeches,
    props.contentKeyVoiceId
  );

  const findVoiceIdByObject = useFindVoiceId({
    textToSpeeches: props.textToSpeeches,
    contentKeyVoiceId: props.contentKeyVoiceId,
  });
  console.log("props.key", props.contentKey);
  const voiceId = findVoiceIdByObject(
    props.contentKey,
    props.submissionResponse
  );
  // no voice id for {String(userAvailableVoices)}
  if (!voiceId) {
    return (
      <div className="flex h-20 w-20">
        <select
          className={`${
            props.highlightSelecteVoice ? "bg-pulse-gradient" : ""
          }`}
          onChange={(e) => {
            if (!e.target.value) {
              return;
            }
            props.setContentKeyVoiceId((prev: any) => {
              const newMap = new Map(prev);
              newMap.set(props.contentKey, e.target.value);
              return newMap;
            });
          }}
        >
          <option value="" selected>
            Voice for {props.contentKey}
          </option>
          {userAvailableVoices.map((voice) => {
            return <option value={voice?.id}>{voice?.name}</option>;
          })}
        </select>
      </div>
    );
  }

  if (processing)
    return (
      <div>
        <Spinner></Spinner>
      </div>
    );
  return (
    <div>
      <div>
        <div className="flex justify-center">
          <ArrowPathIcon
            onClick={async () => {
              await generateTextToSpeech(props);
            }}
            className="flex justify-center  h-7 w-7"
          />
        </div>
        <div>
          <div>Generate audio</div>
          <div>{props.content.length} Characters </div>
        </div>
      </div>
    </div>
  );
};

export default RegenerateSentenceAudio;
