import { useState } from "react";
import useMakeBackendRequest from "./useMakeBackendRequest";
import { UPLOAD_ENDPOINT } from "../constants/Constants";
import { addRandomStringToFile } from "../utils/StringUtils";

type UploadUserRecordingProps = {
  mediaBlobUrl: string;
  userId: string;
  uploadBucket: string;
  uploadPath: string;
  extend?: boolean;
  fileName: string;
};

type UploadFileProps = {
  file: any;
  uploadBucket: string;
  fileFolder: string;
  userId: string;
  extend?: boolean;
  fileName: string;
};

const useUploadAudioFile = () => {
  const [userRecordingURL, setUserRecordingURL] = useState<string>();
  const [filesUploadedCount, setFilesUploadedCount] = useState<number>(0);
  const { post } = useMakeBackendRequest();

  const cleanUserRecordingURL = () => {
    setUserRecordingURL(undefined);
  };

  const FILE_HOSTS = `https://storage.googleapis.com/clove-ai-user-recordings`;

  const uploadFile = async (props: UploadFileProps) => {
    const formData: FormData = new FormData();

    formData.append("files", props.file);
    formData.append("bucket", props.uploadBucket);
    formData.append("uploadPath", `${props.userId}/${props.fileFolder}`);

    const formFileName = addRandomStringToFile(props.fileName); //|| `${userId}--${Date.now()}.wav`;

    formData.append("fileName", formFileName);

    formData.append("userId", props.userId || "");
    if (props.extend) {
      formData.append("extend", "15");
    }

    const uploadedRecording = await post(UPLOAD_ENDPOINT, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });
    console.log(
      `uploadedRecording ${JSON.stringify(uploadedRecording.data.data)}`
    );

    return uploadedRecording;
  };

  const uploadUserRecording = async (props: UploadUserRecordingProps) => {
    // await loadAudioFile(mediaBlobUrl)
    var xhr = new XMLHttpRequest();
    xhr.open("GET", props.mediaBlobUrl, true);
    xhr.responseType = "blob";
    xhr.onload = async function (e) {
      if (this.status === 200) {
        var myBlob = this.response;

        const size = myBlob.size;
        //at least 30kb
        if (size < 30000) {
          console.log(`size of blob ${size} bytes is too small retry`);

          return;
        }
        console.log(`size of blob ${size} bytes`);

        const uploadedRecording = await uploadFile({
          file: myBlob,
          uploadBucket: props.uploadBucket,
          fileFolder: props.uploadPath,
          userId: props.userId,
          extend: props.extend,
          fileName: props.fileName,
        });

        setUserRecordingURL(
          `${FILE_HOSTS}/${props.uploadPath}/${uploadedRecording.data.data}`
        );
        setFilesUploadedCount(filesUploadedCount + 1);
      }
    };
    xhr.send();
  };

  return {
    uploadUserRecording,
    userRecordingURL,
    cleanUserRecordingURL,
    filesUploadedCount,
    uploadFile,
  };
};

export default useUploadAudioFile;
