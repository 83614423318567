import React, { useEffect, useState, useCallback } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthRoute from "./auth/AuthRouter";
import Profile from "./user/Profile";
import ScrollToTop from "./utils/ScrollToTop";

import { Loading } from "./common/Loading";
import { StarterKit } from "./home/starterKit/StarterKit";
import { AppData, useLoadAppData } from "./hooks/useLoadAppData";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import CreateVoicePrint from "./home/audioProcess/CreateVoicePrint";
import VoiceList from "./home/audioProcess/VoiceList";
import ViewVoice from "./home/audioProcess/ViewVoice";
import AudioRecorderComponent from "./home/audioProcess/AudioRecorderComponent";
import routes from "./constants/routes";
import { User } from "./generated/graphql";
import ToolDetail from "./home/starterKit/tools/ToolDetail";

import Backofffice from "./home/backoffice/Backofffice";
import Checkout from "./home/checkout/Checkout";
import SearchPublicVoices from "./home/audioProcess/search/SearchPublicVoices";
import TermsPopUp from "./auth/TermsPopUp";
import ResponseWorksop from "./home/starterKit/tools/responses/workshop/ResponseWorkshop";
import UploadImage from "./home/starterKit/tools/responses/workshop/UploadImage";
import userEvent from "@testing-library/user-event";
import Onboarding from "./home/onboarding/Onboarding";
import { deleteOnboardingStatus } from "./home/onboarding/getOnboardingStatus";
import Dashboard from "./home/starterKit/Dashboard";
import CreateVideoFromContext from "./home/video/CreateVideoFromContext";

// const ToolDetail = lazy(() => import("./home/starterKit/tools/ToolDetail"));

type UserAppProps = {
  firebaseUser: firebase.User;
  authToken: string;
};

export const AppContext = React.createContext<AppData>({
  user: {} as User,
  intialLoadDone: false,
  authToken: "",
  refreshData: () => {
    throw new Error("Function not implemented.");
  },
});

function UserApp(props: UserAppProps) {
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const [onboardingSet, setOnboardingSet] = useState(false);

  const { appData } = useLoadAppData(props.firebaseUser, props.authToken);

  const areTermsAccepted: boolean =
    localStorage.getItem("termsAccepted") === "true";

  const [showTerms, setShowTerms] = useState(!areTermsAccepted);

  const acceptTerms = useCallback(() => {
    setShowTerms(false);
    localStorage.setItem("termsAccepted", "true");
  }, []);

  useEffect(() => {
    if (appData?.intialLoadDone) {
      setDataLoaded(true);
    }
  }, [appData?.intialLoadDone]);

  //tailwind pop up
  if (showTerms) {
    return <TermsPopUp acceptTerms={acceptTerms}></TermsPopUp>;
  }

  if (!dataLoaded || !appData?.user.id) {
    return (
      <div>
        <div></div>
        <Loading />
      </div>
    );
  }

  if (!appData?.user.onboarded && !onboardingSet) {
    return (
      <Onboarding
        setOnboardingSet={setOnboardingSet}
        userId={appData?.user.id}
      ></Onboarding>
    );
  }
  return (
    <Router>
      <AppContext.Provider value={appData}>
        {/* <Suspense fallback={<Loading />}> */}
        <ScrollToTop />

        <Routes>
          <Route
            path="/"
            element={
              <AuthRoute waitForChildren>
                <StarterKit />
              </AuthRoute>
            }
          />
          {/* <Route path="/login" element={<SignUpPage />} /> */}
          <Route
            path="/starter-kit/build/:generativeToolId/"
            element={
              <AuthRoute>
                <ToolDetail />
              </AuthRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <AuthRoute>
                <Profile />
              </AuthRoute>
            }
          />

          <Route
            path="/voices/add/record"
            element={
              <AuthRoute>
                <AudioRecorderComponent />
              </AuthRoute>
            }
          />

          <Route
            path={routes.addVoice.path}
            element={
              <AuthRoute>
                <CreateVoicePrint />
              </AuthRoute>
            }
          />
          <Route
            path={routes.listVoices.path}
            element={
              <AuthRoute>
                <VoiceList />
              </AuthRoute>
            }
          />

          <Route
            path={routes.viewVoice.path}
            element={
              <AuthRoute>
                <ViewVoice />
              </AuthRoute>
            }
          />

          <Route
            path={routes.backoffice.path}
            element={
              <AuthRoute>
                <Backofffice />
              </AuthRoute>
            }
          />

          <Route
            path={routes.checkout.path}
            element={
              <AuthRoute>
                <Checkout />
              </AuthRoute>
            }
          />

          <Route
            path={routes.voiceSearch.path}
            element={
              <AuthRoute>
                <SearchPublicVoices />
              </AuthRoute>
            }
          />

          <Route
            path={routes.responseWorkshop.path}
            element={
              <AuthRoute>
                <ResponseWorksop />
              </AuthRoute>
            }
          />
          <Route
            path={routes.dasbhoard.path}
            element={
              <AuthRoute>
                <Dashboard />
              </AuthRoute>
            }
          />

          <Route
            path={routes.createVideoFromContext.path}
            element={
              <AuthRoute>
                <CreateVideoFromContext />
              </AuthRoute>
            }
          />
        </Routes>
        {/* </Suspense> */}
      </AppContext.Provider>
    </Router>
  );
}

export default UserApp;
