import React, { useCallback, useEffect, useState } from "react";

import { useMicrophoneEnabled } from "../../hooks/useMicrophoneEnabled";

import allowMicSrc from "../../assets/allow-mic.png";
import { SuccessButtonStyle } from "../../constants/CSSConstants";
function MicrophoneCheck() {
  const isMicrophoneEnabled = useMicrophoneEnabled();
  const [showEnableButton, setShowEnableButton] = useState(false);

  useEffect(() => {
    setShowEnableButton(!isMicrophoneEnabled);
  }, [isMicrophoneEnabled]);

  const handleEnableMicrophone = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isMicrophoneEnabled) {
      // Do something when the microphone is enabled
    }
  }, [isMicrophoneEnabled]);

  if (isMicrophoneEnabled === null) {
    // Render a loading state
    return <div>Loading...</div>;
  }

  if (isMicrophoneEnabled === true) {
    // Render something if the microphone is enabled
    return <div>Microphone is enabled!</div>;
  }

  // Render a button to enable the microphone
  return (
    <div className="grid justify-center gap mt-20 ">
      <div onClick={handleEnableMicrophone}>
        <button className={SuccessButtonStyle}>Enable Microphone</button>
        <img alt="mic" src={allowMicSrc}></img>
      </div>
    </div>
  );
}

export default MicrophoneCheck;
