import { useEffect, useState } from "react";
import { getCurrentUser } from "../auth";
import { CENTER_PAGE_MARGINS } from "../constants/Constants";

const Profile = () => {
  const [user, setUser] = useState<firebase.default.User>();

  useEffect(() => {
    const firebaseUser = getCurrentUser();
    if (firebaseUser) {
      setUser(firebaseUser);
    }
  }, []);

  if (!user) {
    console.log(`no user for profile`);
    return <div></div>;
  }
  return (
    <div className={CENTER_PAGE_MARGINS}>
      <div>{user.displayName}</div>
      <div>{user.email}</div>
    </div>
  );
};

export default Profile;
