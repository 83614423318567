import { PlayCircleIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../UserApp";
import Spinner from "../../../common/Spinner";
import routes from "../../../constants/routes";
import useCreateConversation from "../../../hooks/useCreateConversation";
import { Maybe, Voice } from "../../../generated/graphql";
import { AppData } from "../../../hooks/useLoadAppData";
import { getUserAvailableVoices } from "../../../utils/responseAudioUtils";

type ToolResponseAudioProps = {
  text: any;
  userToolSubmissionResponseId: string;
  charactersRemaining: number;
  textToSpeechCallBack: () => void;
};

function getTotalCharacterCount(json: any): number {
  let total = 0;
  for (const obj of json) {
    for (const key in obj) {
      total += obj[key].length;
    }
  }
  return total;
}

const ToolResponseAudio = (props: ToolResponseAudioProps) => {
  const app: AppData = useContext(AppContext);

  const navigate = useNavigate();
  const { conversationURL, createDemoConversation } = useCreateConversation();

  const [voiceIdForText, setVoiceIdForText] = useState<Map<string, string>>();

  const [isProcessingTextToSpeech, setIsProcessingTextToSpeech] = useState<
    Map<string, boolean>
  >(new Map());

  const namesMap = new Map<string, string>();

  const [notEnoughSpakersSelected, setNotEnoughSpeakersSelected] =
    useState<boolean>(false);

  type Conversation = Array<{ [key: string]: string }>;

  useEffect(() => {
    voiceIdForText?.forEach((voiceId, text) => {
      //addVoices
      if (voiceId === "addVoices") {
        navigate(routes.addVoice.path);
      }
      //searchVoices
      if (voiceId === "searchVoices") {
        navigate(routes.voiceSearch.path);
      }
    });
  }, [navigate, voiceIdForText]);

  const translateGuestNames = (
    conversation: Conversation,
    speakerNames: string[]
  ): Conversation => {
    // if (JSON.stringify(conversation).includes("speaker1")) {
    //   return conversation;
    // }
    const speakerMap: { [key: string]: string } = {};
    let speakerIndex = 1;

    for (let i = 0; i < conversation.length; i++) {
      const keys = Object.keys(conversation[i]);
      for (let j = 0; j < keys.length; j++) {
        const guestName = keys[j];
        let speakerName = speakerMap[guestName];
        if (!speakerName) {
          speakerName = "speaker" + speakerIndex;
          speakerMap[guestName] = speakerName;
          speakerIndex++;
          namesMap.set(speakerName, guestName);
        }
        conversation[i][speakerName] = conversation[i][guestName];

        delete conversation[i][guestName];
      }
    }

    return conversation;
  };

  function countSpeakers(jsonObj: any): number {
    const conversation = jsonObj.conversation;
    if (!conversation) return 1;
    const speakers = new Set<string>();

    for (let i = 0; i < conversation.length; i++) {
      const speaker = Object.keys(conversation[i])[0];
      speakers.add(speaker);
    }

    return speakers.size;
  }

  const numSpeakers = countSpeakers(props.text);

  const speakers: any = [];
  for (let i = 0; i < numSpeakers; i++) {
    const speakers = [];
    speakers.push(`speaker${i + 1}`);
  }

  let cleanConversation: Conversation | null = null;
  if (props.text.conversation) {
    let conversationCopy: any = JSON.parse(
      JSON.stringify(props.text.conversation)
    );
    //if conversation is not an array create an array of one and add it
    if (!Array.isArray(conversationCopy)) {
      const conversationArray = [];
      conversationArray.push(conversationCopy);
      conversationCopy = conversationArray;
    }

    cleanConversation = translateGuestNames(conversationCopy, speakers);
  }

  const text = props.text;

  const cleanText =
    cleanConversation !== null ? cleanConversation : text.speaker;

  const enoughSpeechQuota =
    props.charactersRemaining >= getTotalCharacterCount(cleanText);

  const userAvailableVoices = getUserAvailableVoices(app);

  const renderSpeakerSelection = () => {
    const selects = [];
    console.log(`namesMap ${JSON.stringify(namesMap)}`);
    for (let i = 0; i < numSpeakers; i++) {
      const speakerName = `speaker${i + 1}`;

      const speakerSelect = (
        <select
          onChange={(e) => {
            const value = e.target.value;
            if (value === "addVoices") {
              navigate(routes.addVoice.path);
              return;
            }
            if (value === "searchVoices") {
              navigate(routes.voiceSearch.path);
              return;
            }
            setVoiceIdForText((prev) => {
              const newMap = new Map(prev);
              newMap.set(speakerName, value);
              return newMap;
            });
          }}
          name=""
          id=""
          className={`md:w-auto 
          ${
            notEnoughSpakersSelected &&
            voiceIdForText?.get(speakerName) === undefined
              ? "border-2 rounded-xl border-red-500"
              : ""
          }`}
        >
          <option value="" disabled selected>
            Choose {namesMap.get(speakerName)} Speaker
          </option>

          {userAvailableVoices?.map((voice) => {
            return <option value={voice?.id!}>{voice?.name}</option>;
          })}
          <option value="searchVoices">Search more voices</option>
          <option value="addVoices">Create Custom voices!</option>
        </select>
      );

      selects.push(speakerSelect);
    }

    return selects;
  };

  interface Voices {
    [key: string]: {
      voiceId: string;
    };
  }

  const voices: Voices = {};

  voiceIdForText?.forEach((value, key) => {
    console.log(`key ${key} value ${value}`);

    //find voice by id in app.user.voicesByUserId
    //if found, set voiceId to voice.id
    //if not found, set voiceId to value

    const voice = userAvailableVoices.find((voice) => {
      return voice?.id === value;
    });
    if (!voice) {
      throw new Error("voice not found");
    }

    voices[key] = {
      voiceId: voice?.id,
    };
  });

  return (
    <div className="border-t-2">
      <div className="flex flex-col md:flex-row gap-2 my-2 justify-evenly">
        <div
          onClick={async () => {
            if (Object.keys(voices).length < numSpeakers) {
              setNotEnoughSpeakersSelected(true);
              return;
            }

            // if(voices. > numSpeakers) {
            //   alert("Please select a voice for each speaker");
            // }
            const conversationProps = {
              userToolSubmissionResponseId: props.userToolSubmissionResponseId,
              conversation: cleanConversation || [{ speaker1: text.speaker }],
              voices,
            };

            setIsProcessingTextToSpeech((prev) => {
              const newMap = new Map(prev);
              newMap.set(text, true);
              return newMap;
            });

            if (!enoughSpeechQuota) {
              navigate(routes.checkout.path);
            }

            await createDemoConversation(conversationProps);

            setIsProcessingTextToSpeech((prev) => {
              const newMap = new Map(prev);
              newMap.set(text, false);
              return newMap;
            });
            props.textToSpeechCallBack();
          }}
          className="flex cursor-pointer"
        >
          {isProcessingTextToSpeech.get(text) ? (
            <Spinner></Spinner>
          ) : (
            <div>
              <PlayCircleIcon className="h-6 w-6"></PlayCircleIcon>
              {enoughSpeechQuota ? "Hear it!" : "Upgrade to hear it!"}
            </div>
          )}
        </div>
        <div className="flex flex-wrap gap-2">{renderSpeakerSelection()}</div>
        <div>{conversationURL && <audio src={conversationURL} controls />}</div>
      </div>
      <div className="flex justify-around text-sm">
        <div>{getTotalCharacterCount(cleanText)} characters</div>
        <div>remaining: {props.charactersRemaining}</div>
      </div>
    </div>
  );
};

export default ToolResponseAudio;
